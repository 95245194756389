/**
 * Styled-components theme for Reassure-UI navigation components
 */

import { lighten } from 'polished';
import pallette from './pallette';
const {
    white,
    graphite,
    midGreen,
} = pallette;


const themeConfig = {
    colors: {
        link: {
            text: midGreen,
            hover: {
                text: lighten(0.1, midGreen),
            }
        },
        tabs: {
            text: white,
            background: graphite,
        }
    }
}

export default themeConfig;