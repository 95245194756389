import { Map } from 'immutable';
import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN } from './types';
import { STORE_RESET } from '../types';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    open: false,
};

const initialState = Map(schema);

/**
 *
 * The Mobile Menu Reducer Reducer
 *
 * Controls the visibility/display of the mobile menu
 *
 */
const mobileMenuReducer = (state = initialState, { type }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case MOBILE_MENU_OPEN:
            return state.set('open', true);

        case MOBILE_MENU_CLOSE:
            return state.set('open', false);

        default:
            return state;
    }
};

export { initialState };

export default mobileMenuReducer;
