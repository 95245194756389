import { gql } from '@apollo/client';

export const onUpdateAdviserPortfoliosSubscription = gql`
    subscription OnUpdateAdviserPortfolios {
        onUpdateAdviserPortfolios {
            id
            description
            name
            type
            status
            linkedPolicyCount
            created {
                name
                date
            }
            updated {
                name
                date
            }
        }
    }
`;

export const onUpdateAdviserPortfolioDetailsSubscription = gql`
    subscription OnUpdateAdviserPortfolioDetails {
        onUpdateAdviserPortfolioDetails {
            portfolio {
                id
                description
                name
                type
                status
                linkedPolicyCount
                created {
                    name
                    date
                }
                updated {
                    name
                    date
                }
            }
            funds {
                abiSectorName
                allocation
                name
                reAssureId
                units {
                    name
                    bidPrice
                    reAssureId
                    abiSectorName
                    reAssureFundName
                    reAssureRiskRating
                    returnYTD
                    secId
                }
            }
            policies {
                linked {
                    date
                    name
                }
                details {
                    description
                    policyId
                    policyNumber
                    policyHolderFullName
                    state
                    portalReady
                }
                permissions {
                    switch
                    redirect
                }
            }
        }
    }
`;

export const onUpdateAdviserBackOfficeIntegrationStatus = gql`
    subscription OnUpdateAdviserBackOfficeIntegrationStatus {
        onUpdateAdviserBackOfficeIntegrationStatus {
            id
            name
            description
            buttonText
            status {
                type
                label
                description
            }
            beta
        }
    }
`;
