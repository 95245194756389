/**
 * Styled-components theme for Reassure-UI component design system
 */
import pallette from './pallette';
import page from './page';
import animations from './animations';
import badges from './badges';
import buttons from './buttons';
import data from './data';
import forms from './forms';
import help from './help';
import icons from './icons';
import layout from './layout';
import modals from './modals';
import navigation from './navigation';
import panels from './panels';
import documents from './documents';


let theme = {
    name: 'dark',
    breakpoints: ['320px', '375px', '576px', '768px', '992px', '1400px'],
    colors: {
        
        primaryText: '#ffffff',
        secondaryText: '#4da7aa',
        interaction: pallette.midGreen,

        primary1: '#282a2e',
        primary2: '#000000',
        primary3: '#1d1e20',

        background1: '#282a2e',
        background2: '#000000',
        background3: '#1d1e20',
        
        blacks: [
            'rgba(0,0,0,.0125)',
            'rgba(0,0,0,.025)',
            'rgba(0,0,0,.05)',
            'rgba(0,0,0,.1)',
            'rgba(0,0,0,.2)',
            'rgba(0,0,0,.3)',
            'rgba(0,0,0,.4)',
            'rgba(0,0,0,.5)',
            'rgba(0,0,0,.6)',
            'rgba(0,0,0,.7)',
            'rgba(0,0,0,.8)',
            'rgba(0,0,0,.9)',
        ],
        whites: [
            'rgba(255,255,255,.0125)',
            'rgba(255,255,255,.025)',
            'rgba(255,255,255,.05)',
            'rgba(255,255,255,.1)',
            'rgba(255,255,255,.2)',
            'rgba(255,255,255,.3)',
            'rgba(255,255,255,.4)',
            'rgba(255,255,255,.5)',
            'rgba(255,255,255,.6)',
            'rgba(255,255,255,.7)',
            'rgba(255,255,255,.8)',
            'rgba(255,255,255,.9)',
        ],
    },
    fonts: {
        serif: 'times, serif',
        sansSerif: '"Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif',
    },
    lineHeights: {
        solid: 1,
        title: 1.3,
        copy: 1.4,
        input: 1.5,
    },
    letterSpacings: {
        normal: 'normal',
        tracked: '0.1em',
        tight: '-0.05em',
        mega: '0.25em',
    },
    headings: {
        marginTop: 0.75,
        marginBottom: 0.35
    },
    paragraphs: {
        marginTop: 0,
        marginBottom: 0.6
    },
    borders: [
        0,
        '1px solid',
        '2px solid',
        '4px solid',
        '8px solid',
    ],
    borderRadius: {
        small: '4px',
        medium: '6px',
        large: '8px',
    },
    space: [0, '1px', '2px', '4px', '8px', '16px', '24px', '32px', '48px', '64px', '128px', '256px', '512px'],
    sizes: [0, '1px', '2px', '4px', '8px', '16px', '24px', '32px', '48px', '64px', '128px', '256px', '512px'],
    fontSizes: ['10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '30px', '36px', '42px', '48px', '60px', '72px', '96px'],
    fontWeights: [300, 400, 600, 700],
    width: [16, 32, 64, 128, 256],
    heights: [16, 32, 64, 128, 256],
    maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
    gridColumns: 12,
    gridGutterWidth: '30px',
};

// breakpoint aliases
theme.breakpoints.xxs = theme.breakpoints[0];
theme.breakpoints.xs = theme.breakpoints[1];
theme.breakpoints.sm = theme.breakpoints[2];
theme.breakpoints.md = theme.breakpoints[3];
theme.breakpoints.lg = theme.breakpoints[4];
theme.breakpoints.xl = theme.breakpoints[5];

// bootstrap styled grid breakpoint
theme['$grid-breakpoints'] = {
    xxs: theme.breakpoints[0],
    xs: theme.breakpoints[1],
    sm: theme.breakpoints[2],
    md: theme.breakpoints[3],
    lg: theme.breakpoints[4],
    xl: theme.breakpoints[4]
}

// font-weight aliases
theme.fontWeights.light = theme.fontWeights[0];
theme.fontWeights.regular = theme.fontWeights[1];
theme.fontWeights.medium = theme.fontWeights[2];
theme.fontWeights.bold = theme.fontWeights[3];
theme.formLabelFontWeight = theme.fontWeights.bold;


/**
 * theme text styles:
 * 
 * introCopy => lead
 * copyPlayback => playback
 * bodyCopy => body
 * inlineHelp => small
 * 
 * new font styles:
 * 
 * primary value
 * secondary value
 * 
 */


theme.colors = {
    ...theme.colors,
    ...pallette,
    ...page.colors,
    ...animations.colors,
    ...badges.colors,
    ...buttons.colors,
    ...data.colors,
    ...forms.colors,
    ...help.colors,
    ...icons.colors,
    ...layout.colors,
    ...modals.colors,
    ...navigation.colors,
    ...panels.colors,
    ...documents.colors,
};

export default theme;
