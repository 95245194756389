/**
 * Styled-components theme for Reassure-UI form components
 */

import { 
    rgba,
    lighten,
} from 'polished';
import pallette from './pallette';
const {
    transparent,
    white,
    black,
    paleBlue,
    graphite,
    charcoal,
    midGreen,
    midRed
} = pallette;

const themeConfig = {
    colors: {
        formField: {
            focusHighlight: paleBlue,
        },
        cardRadioGroup: {
            text: white,
            background: transparent,
            border: lighten(0.05, graphite),
            radio: white,
            hover: {
                text: white,
                background: charcoal,
                border: lighten(0.05, graphite),
                radio: white,
            },
            checked: {
                text: white,
                background: graphite,
                border: lighten(0.05, graphite),
                radio: midGreen,
            }
        },
        dropdown: {
            text: white,
            background: charcoal,
            border: lighten(0.05, graphite),
            caret: midGreen,
            focus: {
                background: charcoal,
                border: charcoal,
                error: {
                    background: charcoal,
                    border: midRed,
                }
            },
            error: {
                background: charcoal,
                border: midRed,
            }
        },
        dropdownMenu: {
            text: white,
            background: transparent,
            border: midGreen,
            caret: midGreen,
            menu: {
                background: white,
            },
            menuItem: {
                text: black,
                hover: {
                    text: white,
                    background: midGreen,
                },
            }
        },
        input: {
            text: {
                text: white,
                background: charcoal,
                border: lighten(0.05, graphite),
                focus: {
                    text: white,
                    background: charcoal,
                    border: lighten(0.05, graphite),
                    error: {
                        text: white,
                        background: rgba(midRed, 0.2),
                        border: midRed,
                    }
                },
                error: {
                    text: white,
                    background: rgba(midRed, 0.2),
                    border: midRed,
                }
            },
            checkbox: {
                background: charcoal,
                border: lighten(0.05, graphite),
                checked: {
                    background: transparent,
                    border: midGreen,
                    tickIcon: midGreen,
                }
            }
        },
        label: {
            text: white,
        },
        select: {
            text: white,
            background: charcoal,
            border: lighten(0.05, graphite),
            caret: midGreen,
            focus: {
                background: charcoal,
                border: lighten(0.05, graphite),
                error: {
                    background: rgba(midRed, 0.2),
                    border: midRed,
                }
            },
            error: {
                background: rgba(midRed, 0.2),
                border: midRed,
            }
        },
        toggleSwitch: {
            checked: {
                text: black,
                background: midGreen,
            },
            unchecked: {
                text: white,
                background: transparent,
            },
            switch: white,
            border: midGreen,
        },
        slider: {
            marks: white,
            track: white,
            dots: {
                border: white,
                background: graphite,
            },
            handle: {
                background: midGreen,
                bars: white,
            }
        }

    }

}

export default themeConfig;