import { Map } from 'immutable';
import {
    CUSTOMER_MESSAGES_LOADING,
    CUSTOMER_MESSAGES_REQUESTED,
    CUSTOMER_MESSAGES_RESOLVED,
    CUSTOMER_MESSAGES_ERROR,
    CUSTOMER_MESSAGE_READ,
    UNREAD_THREAD_COUNT_UPDATE,
    SEND_CUSTOMER_MESSAGE_SENDING,
    SEND_CUSTOMER_MESSAGE_SENT,
    SEND_CUSTOMER_MESSAGE_ERROR,
    SEND_CUSTOMER_MESSAGE_RESET,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of our messages
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    requested: false,
};

const initialState = Map(schema);

/**
 *
 * The Customer Messages Reducer
 *
 * Responsible for managing the state and data for customer messages
 *
 */
const customerMessagesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEND_CUSTOMER_MESSAGE_RESET:
            return state.set('outbox', STORE_STATUS_INIT);

        case SEND_CUSTOMER_MESSAGE_SENDING:
            return state.set('outbox', 'SENDING');

        case SEND_CUSTOMER_MESSAGE_SENT:
            return state.set('outbox', 'SENT');

        case SEND_CUSTOMER_MESSAGE_ERROR:
            return state.set('outbox', STORE_STATUS_ERROR);

        case CUSTOMER_MESSAGES_LOADING:
            return state.set('loading', true).set('requested', true);

        case CUSTOMER_MESSAGES_REQUESTED:
            return state.set('requested', true);

        case CUSTOMER_MESSAGES_RESOLVED:
            state = state.set('status', STORE_STATUS_OK);
            state = state.set('unreadCount', payload.unreadCount);
            state = state.set('threads', payload.threads);
            state = state.set('loading', false);
            state = state.set('requested', false);

            return state;

        case CUSTOMER_MESSAGES_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);
            state = state.set('requested', false);
            state = state.set('unreadCount', null);
            state = state.set('threads', []);

            return state;

        case UNREAD_THREAD_COUNT_UPDATE:
            return state.set('unreadCount', payload);

        case CUSTOMER_MESSAGE_READ:
            // Firstly let's update the message itself

            let thread;

            if (state.get('threads')[payload.threadId]) {
                thread = state.get('threads')[payload.threadId];
            }

            // Check if we have messages to udpate
            if (thread.messages.length) {
                let messages = [];

                // Update the message read property for the matching message
                thread.messages.forEach((message, index) => {
                    const newMessage = Object.assign({}, message);

                    if (newMessage.id === payload.messageId) {
                        newMessage.read = true;
                    }

                    messages.push(newMessage);
                });

                // Update the state
                state = state.set(
                    'threads',
                    Object.assign({}, state.get('threads'), {
                        [payload.threadId]: Object.assign({}, state.get('threads')[payload.threadId], {
                            messages,
                        }),
                    })
                );

                let count = 0;

                // Get the unread count
                Object.keys(state.get('threads')).forEach(threadId => {
                    const thread = state.get('threads')[threadId];

                    const unreadMessages = thread.messages.filter(message => {
                        return !message.read && message.from === 'ReAssure';
                    });

                    count = count + unreadMessages.length;
                });

                // Let's recalculate the unread count and update the state for unreadCount
                state = state.set('unreadCount', count);

                // Finally update the state
                return state;
            }

            return state;

        case STORE_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default customerMessagesReducer;
