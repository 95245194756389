import moment from 'moment';

const updateAnswers = (state, answers) => {

    const newQuestions = {};
    answers.forEach(answer => {
        const questionId = `question${answer.id}`;
        const question = state.get('questions')[questionId];
        if(state.get('questions')[questionId]){
            newQuestions[questionId] = Object.assign({}, question, { answer: question.fieldType === 'DATE' ? moment(answer.value).format("DD/MM/YYYY") : answer.value });
        }
    });

    return newQuestions;

};

export default updateAnswers;
