/**
 * Styled-components theme for Reassure-UI component design system
 */
import pallette from './pallette';
import page from './page';
import animations from './animations';
import badges from './badges';
import buttons from './buttons';
import data from './data';
import forms from './forms';
import help from './help';
import icons from './icons';
import layout from './layout';
import modals from './modals';
import navigation from './navigation';
import panels from './panels';
import documents from './documents';


let theme = {
    name: 'light',
    breakpoints: ['320px', '375px', '576px', '768px', '992px', '1400px'],
    colors: {

        primaryText: pallette.darkBlue,
        secondaryText: pallette.darkBlue,
        interaction: pallette.black,

        primary1: 'hsl(220, 43%, 97%)',
        primary2: 'hsl(223, 20%, 94%)',
        primary3: '#ffffff',
        
        background1: 'hsl(220, 43%, 97%)',
        background2: 'hsl(223, 20%, 94%)',
        background3: '#ffffff',

    },
};

theme.colors = {
    ...theme.colors,
    ...pallette,
    ...page.colors,
    ...animations.colors,
    ...badges.colors,
    ...buttons.colors,
    ...data.colors,
    ...forms.colors,
    ...help.colors,
    ...icons.colors,
    ...layout.colors,
    ...modals.colors,
    ...navigation.colors,
    ...panels.colors,
    ...documents.colors,
};

export default theme;
