import { Map } from 'immutable';
import {
    INVITE_REQUESTED,
    INVITE_RESOLVED,
    INVITE_ERROR,
    INVITE_INVALID,
    INVITE_EXPIRED,
    INVITE_IP_LOCKED,
    INVITE_CONFIRMED,
    INVITE_NOT_ACTIVE,
    INVITE_UPDATE_EMAIL,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of our invitation reducer
 *
 */
const initialState = Map({
    status: STORE_STATUS_INIT,
    loading: false,
    inviteCode: null,
    contextNumber: null,
    role: null,
    invite: {},
    confirmed: false,
    alreadyRegistered: false,
});

/**
 *
 * The Invitation Reducer
 *
 * A reducer which controls the state of invitations
 *
 */
const invitationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            if (payload && payload.hasOwnProperty('fromInvite') && payload.fromInvite === true) return state;

            return initialState;

        case INVITE_REQUESTED:
            state = state.set('loading', true);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);

            if (typeof state.get('activeFrom') === 'string') {
                state = state.set('activeFrom', null);
            }

            return state;

        case INVITE_RESOLVED:
            state = state.set('status', STORE_STATUS_OK);
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', payload.invite);
            state = state.set('role', payload.invite.role);
            state = state.set('alreadyRegistered', payload.invite.alreadyRegistered);

            return state;

        case INVITE_EXPIRED:
            state = state.set('status', 'EXPIRED');
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', payload.invite);

            return state;

        case INVITE_INVALID:
            state = state.set('status', 'INVALID');
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', payload.invite);

            return state;

        case INVITE_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', {});

            return state;

        case INVITE_IP_LOCKED:
            state = state.set('status', 'LOCKED');
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', payload.invite);

            return state;

        case INVITE_NOT_ACTIVE:
            state = state.set('status', 'NOT_ACTIVE');
            state = state.set('loading', false);
            state = state.set('inviteCode', payload.inviteCode);
            state = state.set('contextNumber', payload.contextNumber);
            state = state.set('invite', payload.invite);
            state = state.set('activeFrom', payload.invite.activeFrom);

            return state;

        case INVITE_CONFIRMED:
            state = state.set('confirmed', payload.confirmed);

            return state;

        case INVITE_UPDATE_EMAIL:
            return state.setIn(['invite', 'email'], payload);

        default:
            return state;
    }
};

export { initialState };

export default invitationReducer;
