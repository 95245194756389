import { graphQLQuery } from '../../graphql/client';
import { adviserBackOfficeIntegrationsQuery } from '../../graphql/queries/advisers';
import {
    ADVISER_BACK_OFFICE_INTEGRATIONS_ERROR,
    ADVISER_BACK_OFFICE_INTEGRATIONS_REQUESTED,
    ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED,
} from './types';

export const getAdviserBackOfficeIntegrationsAction = async dispatch => {
    dispatch({
        type: ADVISER_BACK_OFFICE_INTEGRATIONS_REQUESTED,
    });

    try {
        const payload = await graphQLQuery(adviserBackOfficeIntegrationsQuery);
        return dispatch({
            type: ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: ADVISER_BACK_OFFICE_INTEGRATIONS_ERROR,
        });
    }
};
