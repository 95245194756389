import { useRef, useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useDebounce = (func, wait, options) => useRef(_.debounce(func, wait, options)).current;

export const useThrottle = (func, wait, options) => useRef(_.throttle(func, wait, options)).current;

export const useRedirect = route => {
    const { pathname } = useLocation();
    return !pathname.startsWith(route);
};
