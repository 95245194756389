import { Map } from 'immutable';
import { isEqual } from 'lodash';
import { STORE_STATUS_INIT, STORE_STATUS_LOADING } from '../statuses';
import { APP_UPDATE, APP_UPDATE_REQUESTED } from './types';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    version: process.env.REACT_APP_RCP_VERSION,
    maintenanceWindow: {
        from: null,
        to: null,
    },
    config: null,
};

const initialState = Map(schema);

/**
 *
 * The App Reducer
 *
 * Controls the current state of the app
 *
 */
const appReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case APP_UPDATE_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);

        case APP_UPDATE:
            const { status, maintenanceWindow, version, config } = payload;

            let updatedState = state.set('status', status);

            if (maintenanceWindow) {
                updatedState = updatedState.set('maintenanceWindow', maintenanceWindow);
            }

            if (version) {
                updatedState = updatedState.set('version', version);
            }

            if (config) {
                updatedState = updatedState.set('config', config);
            }

            return isEqual(updatedState, state) ? state : updatedState;

        default:
            return state;
    }
};

export { initialState };

export default appReducer;
