import { Map } from 'immutable';
import {
    AGENCY_GROUP_USERS_REQUESTED,
    AGENCY_GROUP_USERS_RESOLVED,
    AGENCY_GROUP_USERS_ERROR,
    ADD_AGENCY_GROUP_USER_RESOLVED,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    users: null,
};

const initialState = Map(schema);

const agencyGroupUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case AGENCY_GROUP_USERS_REQUESTED:
            return state.set('loading', true);

        case AGENCY_GROUP_USERS_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('loading', false).set('users', action.payload);

        case AGENCY_GROUP_USERS_ERROR:
            return state.set('status', 'ERROR').set('loading', false).set('users', null);

        case ADD_AGENCY_GROUP_USER_RESOLVED:
            if (!action.payload.errorCode) {
                return state.set('users', action.payload.users);
            } else {
                return state;
            }

        default:
            return state;
    }
};

export { initialState };

export default agencyGroupUsersReducer;
