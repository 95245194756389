import { Map } from 'immutable';
import { POLICY_FUND_FACTSHEET_SET, POLICY_FUND_FACTSHEET_UNSET } from './types';
import { STORE_RESET } from '../types';

/**
 *
 * Set the initial state of valuation types
 *
 */
const schema = {};

export const initialState = Map(schema);

/**
 *
 * The Policy Fund Factsheet Reducer
 *
 * Responsible for managing the state of fund factsheets to be displayed
 *
 */
const policyFundFactsheetReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case POLICY_FUND_FACTSHEET_SET:
            return Map(payload);

        case STORE_RESET:
        case POLICY_FUND_FACTSHEET_UNSET:
            return initialState;

        default:
            return state;
    }
};

export default policyFundFactsheetReducer;
