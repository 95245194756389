const featuresService = {};

const isFeatureFlagEnabled = (state, key) => {
    if (!state) return false;
    if (!state.app.get('config')) return false;
    return !!state.app.get('config')[key];
};

export const FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED = 'FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED';
export const FEATURE_ADVISOR_ENABLED = 'FEATURE_ADVISOR_ENABLED';
export const FEATURE_ADVISER_MANAGE_POLICIES_ENABLED = 'FEATURE_ADVISER_MANAGE_POLICIES_ENABLED';
export const FEATURE_ADVISER_PORTFOLIOS_ENABLED = 'FEATURE_ADVISER_PORTFOLIOS_ENABLED';
export const FEATURE_AGENCY_FULL_IDENTITY_ENABLED = 'FEATURE_AGENCY_FULL_IDENTITY_ENABLED';
export const FEATURE_CHANGE_ADDRESS_ENABLED = 'FEATURE_CHANGE_ADDRESS_ENABLED';
export const FEATURE_CHECK_ELIGIBILITY_ENABLED = 'FEATURE_CHECK_ELIGIBILITY_ENABLED';
export const FEATURE_CONFIG_UPDATE_ENABLED = 'FEATURE_CONFIG_UPDATE_ENABLED';
export const FEATURE_DOCUMENTS_ENABLED = 'FEATURE_DOCUMENTS_ENABLED';
export const FEATURE_POLICY_BENEFITS_ENABLED = 'FEATURE_POLICY_BENEFITS_ENABLED';
export const FEATURE_POLICY_VALUATION_ENABLED = 'FEATURE_POLICY_VALUATION_ENABLED';
export const FEATURE_POLICY_VALUATION_CWP_ENABLED = 'FEATURE_POLICY_VALUATION_CWP_ENABLED';
export const FEATURE_POLICY_BONUS_HISTORY_ENABLED = 'FEATURE_POLICY_BONUS_HISTORY_ENABLED';
export const FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED = 'FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED';
export const FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED = 'FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED';
export const FEATURE_POLICY_INCOME_HISTORY_ENABLED = 'FEATURE_POLICY_INCOME_HISTORY_ENABLED';
export const FEATURE_POLICY_PAYMENT_HISTORY_ENABLED = 'FEATURE_POLICY_PAYMENT_HISTORY_ENABLED';
export const FEATURE_POLICY_PROJECTIONS_ENABLED = 'FEATURE_POLICY_PROJECTIONS_ENABLED';
export const FEATURE_POLICY_ADMINISTRATOR_ENABLED = 'FEATURE_POLICY_ADMINISTRATOR_ENABLED';
export const FEATURE_POLICY_THIRD_PARTIES_ENABLED = 'FEATURE_POLICY_THIRD_PARTIES_ENABLED';
export const FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED = 'FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED';
export const FEATURE_POLICY_GUARANTEE_ENABLED = 'FEATURE_POLICY_GUARANTEE_ENABLED';
export const FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED = 'FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED';
export const FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED = 'FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED';
export const FEATURE_POLICY_FUND_SWITCH_ENABLED = 'FEATURE_POLICY_FUND_SWITCH_ENABLED';
export const FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED = 'FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED';
export const FEATURE_SCHEMES_ENABLED = 'FEATURE_SCHEMES_ENABLED';
export const FEATURE_MFA_AT_LOGIN_ENABLED = 'FEATURE_MFA_AT_LOGIN_ENABLED';
export const FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED = 'FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED';
export const FEATURE_SELF_SERVICE_ENABLED = 'FEATURE_SELF_SERVICE_ENABLED';
export const FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED = 'FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED';

featuresService.advisersEnabled = state => isFeatureFlagEnabled(state, FEATURE_ADVISOR_ENABLED);
featuresService.configUpdateEnabled = state => isFeatureFlagEnabled(state, FEATURE_CONFIG_UPDATE_ENABLED);
featuresService.documentsEnabled = state => isFeatureFlagEnabled(state, FEATURE_DOCUMENTS_ENABLED);
featuresService.checkEligibilityEnabled = state => isFeatureFlagEnabled(state, FEATURE_CHECK_ELIGIBILITY_ENABLED);
featuresService.changeAddressEnabled = state => isFeatureFlagEnabled(state, FEATURE_CHANGE_ADDRESS_ENABLED);
featuresService.singlePremiumPaymentEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED);
featuresService.policyValuationEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_VALUATION_ENABLED);
featuresService.policyValuationCWPEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_VALUATION_CWP_ENABLED);
featuresService.policyBenefitsEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_BENEFITS_ENABLED);
featuresService.policyBonusHistoryEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_BONUS_HISTORY_ENABLED);
featuresService.policyPremiumBreakdownEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED);
featuresService.policyTransactionHistoryEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED);
featuresService.policyIncomeHistoryEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_INCOME_HISTORY_ENABLED);
featuresService.policyPaymentHistoryEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_PAYMENT_HISTORY_ENABLED);
featuresService.policyProjectionsEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_PROJECTIONS_ENABLED);
featuresService.policyAdministratorEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_ADMINISTRATOR_ENABLED);
featuresService.policyThirdPartiesEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_THIRD_PARTIES_ENABLED);
featuresService.policyValuationDifferenceEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED);
featuresService.policyGuaranteeEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_GUARANTEE_ENABLED);
featuresService.policyReturnOfPremiumEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED);
featuresService.policyRegularBonusCWPEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED);
featuresService.selfServiceEnabled = state => isFeatureFlagEnabled(state, FEATURE_SELF_SERVICE_ENABLED);
featuresService.adviserManagePoliciesEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_ADVISER_MANAGE_POLICIES_ENABLED);
featuresService.policyFundSwitchEnabled = state => isFeatureFlagEnabled(state, FEATURE_POLICY_FUND_SWITCH_ENABLED);
featuresService.adviserPortfoliosEnabled = state => isFeatureFlagEnabled(state, FEATURE_ADVISER_PORTFOLIOS_ENABLED);
featuresService.adviserBackOfficeIntegrationEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED);
featuresService.schemeSuperUserNominationEnabled = state =>
    isFeatureFlagEnabled(state, FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED);
featuresService.schemesEnabled = state => isFeatureFlagEnabled(state, FEATURE_SCHEMES_ENABLED);
featuresService.mfaAtLoginEnabled = state => isFeatureFlagEnabled(state, FEATURE_MFA_AT_LOGIN_ENABLED);
featuresService.enableMfaAtLoginEnabled = state => isFeatureFlagEnabled(state, FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED);
featuresService.agencyFullIdentityEnabled = state => isFeatureFlagEnabled(state, FEATURE_AGENCY_FULL_IDENTITY_ENABLED);

export default featuresService;
