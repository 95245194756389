import { Map } from 'immutable';

import {
    SEND_VERIFICATION_CODE_RESOLVED,
    SEND_VERIFICATION_CODE_ERROR ,
    VERIFY_VERIFICATION_CODE_RESOLVED,
    VERIFY_VERIFICATION_CODE_ERROR ,
} from './types';

import { STORE_RESET } from '../types';
/**
*
* Set the initial state of our verification code reducer
*
*/
const initialState = Map({
    sent: false,
    code: null,
    verified: null,
    error: null,
});

/**
*
* The Verification Code Reducer
*
* A reducer which controls the state of verification codes
*
*/
const verificationCodeReducer = (state = initialState, action) => {

    switch (action.type) {

        case STORE_RESET:

            if(action.payload && action.payload.hasOwnProperty('fromInvite') && action.payload.fromInvite === true) return state;

            return initialState;

        case SEND_VERIFICATION_CODE_RESOLVED:

            return state.set('sent', action.payload.sent).set('error', action.payload.error || null);

        case SEND_VERIFICATION_CODE_ERROR:

            return state.set('sent', action.payload.sent).set('error', action.payload.error || null);

        case VERIFY_VERIFICATION_CODE_RESOLVED:

            return state.set('code', action.payload.code).set('verified', action.payload.verified).set('error', action.payload.error || null);

        case VERIFY_VERIFICATION_CODE_ERROR:

            return state.set('code', action.payload.code).set('verified', action.payload.verified).set('error', action.payload.error || null);

        default:

            return state;
    }
};

export { initialState };

export default verificationCodeReducer;