import { Map } from 'immutable';
import { APPEARANCE_SET_MODE } from './types';

/**
 *
 * Set the initial state of the appearance reducer
 *
 */
const initialState = Map({
    mode: null,
});

/**
 * The appearance reducer
 *
 * A reducer which controls the appearance state of the app
 *
 */
const appearanceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case APPEARANCE_SET_MODE:
            return state.set('mode', payload);
        default:
            return state;
    }
};

export { initialState };

export default appearanceReducer;
