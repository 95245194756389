import { gql } from '@apollo/client';

export const adviserClientPoliciesQuery = gql`
    query AdviserClientPolicies {
        adviserClientPolicies {
            customerId
            title
            firstName
            lastName
            dateOfBirth
            added {
                date
                name
            }
            portalActive
            policies {
                policyId
                policyNumber
                description
                originalCompany
                policyType
                policyHolderId
                policyHolderFullName
                state
                startDate
                portalReady
                policyCode
                error
            }
        }
    }
`;

export const adviserClientPoliciesDownloadsQuery = gql`
    query AdviserClientPoliciesDownloads {
        adviserClientPoliciesDownloads {
            id
            expiryDate
            format
            processedDate
            requestedBy
            requestedDate
            status
        }
    }
`;

export const agencyGroupDetailsQuery = gql`
    query AgencyGroupDetails {
        agencyGroupDetails {
            name
            fcaReference
            buildingNumber
            buildingName
            address1
            address2
            address3
            address4
            address5
            postCode
            telephoneNumber
            sharing
        }
    }
`;

export const agencyGroupUsersQuery = gql`
    query AgencyGroupUsers {
        agencyGroupUsers {
            title
            firstName
            lastName
            email
            mobile
            landline
            portalActive
            status
            dateAdded
            lastLogin
            role
            customerId
            address {
                buildingNumber
                buildingName
                address1
                address2
                address3
                address4
                address5
                postCode
            }
        }
    }
`;

export const adviserPortfoliosQuery = gql`
    query AdviserPortfolios {
        adviserPortfolios {
            id
            description
            name
            type
            status
            linkedPolicyCount
            created {
                name
                date
            }
            updated {
                name
                date
            }
        }
    }
`;

export const adviserPortfolioDetailsQuery = gql`
    query AdviserPortfolioDetails($input: IdInput!) {
        adviserPortfolioDetails(input: $input) {
            portfolio {
                id
            }
            funds {
                abiSectorName
                allocation
                name
                reAssureId
                units {
                    name
                    bidPrice
                    reAssureId
                    abiSectorName
                    reAssureFundName
                    reAssureRiskRating
                    returnYTD
                    secId
                    reportUrl
                }
            }
            policies {
                linked {
                    date
                    name
                }
                details {
                    description
                    policyId
                    policyNumber
                    policyHolderFullName
                    state
                    portalReady
                }
                permissions {
                    switch
                    redirect
                }
            }
        }
    }
`;

export const validateAdviserPortfolioFundsQuery = gql`
    query ValidateAdviserPortfolioFunds($input: PortfolioFundsInput!) {
        validateAdviserPortfolioFunds(input: $input) {
            policyId
            policyNumber
            description
            policyHolderFullName
        }
    }
`;

export const adviserPortfolioAvailablePoliciesQuery = gql`
    query AdviserPortfolioAvailablePolicies($input: IdInput!) {
        adviserPortfolioAvailablePolicies(input: $input) {
            details {
                policyId
                policyNumber
                policyHolderFullName
                description
            }
            canLink
            permissions {
                switch
                redirect
            }
        }
    }
`;

export const adviserBackOfficeIntegrationsQuery = gql`
    query AdviserBackOfficeIntegrations {
        adviserBackOfficeIntegrations {
            id
            name
            description
            buttonText
            status {
                type
                label
                description
            }
            beta
        }
    }
`;
