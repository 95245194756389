import { Map } from 'immutable';
import {
    CHALLENGE_IDENTITY_REQUESTED,
    CHALLENGE_IDENTITY_RESOLVED,
    CHALLENGE_IDENTITY_ERROR,
    CHALLENGE_IDENTITY_SUBMITTED,
    CHALLENGE_IDENTITY_FAILED,
    CHALLENGE_IDENTITY_PASSED,
    CHALLENGE_IDENTITY_TIMEDOUT,
    CHALLENGE_IDENTITY_LOCKED,
} from './types';
import { STORE_RESET } from '../types';
import updateQuestions from './updateQuestions';
import updateAnswers from './updateAnswers';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT } from '../statuses';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    submitting: false,
    passed: false,
};

const initialState = Map(schema);

/**
 *
 * The Identity Reducer
 *
 * A reducer to manage identity security questions for user identity verification
 *
 */
const challengeIdentityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case CHALLENGE_IDENTITY_REQUESTED:
            return state.set('loading', true);

        case CHALLENGE_IDENTITY_SUBMITTED:
            state = state.set('submitting', true).set('questions', updateAnswers(state, payload.answers));

            return state;

        case CHALLENGE_IDENTITY_RESOLVED:
            state = state.set('status', payload.status);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('sessionId', payload.sessionId);
            state = state.set('questions', updateQuestions(state, payload.questions));

            return state;

        case CHALLENGE_IDENTITY_PASSED:
            state = state.set('status', payload.status);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('passed', payload.passed);
            state = state.set('questions', updateQuestions(state, payload.questions));

            return state;

        case CHALLENGE_IDENTITY_FAILED:
            state = state.set('status', payload.status);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('passed', payload.passed);
            state = state.set('questions', updateQuestions(state, payload.questions));

            return state;

        case CHALLENGE_IDENTITY_TIMEDOUT:
            state = state.set('status', payload.status);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('passed', false);
            state = state.set('questions', {});

            return state;

        case CHALLENGE_IDENTITY_LOCKED:
            state = state.set('status', payload.status);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('passed', false);
            state = state.set('questions', {});

            return state;

        case CHALLENGE_IDENTITY_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);
            state = state.set('submitting', false);
            state = state.set('passed', false);
            state = state.set('questions', {});

            return state;

        default:
            return state;
    }
};

export { initialState };

export default challengeIdentityReducer;
