import React from 'react';
import { toast } from 'react-toastify';
import ToastNotification from './ToastNotification';

const notify = (level, children, extraProps) => {
    const closeButton = extraProps && extraProps.hasOwnProperty('closeButton');
    return toast(
        <ToastNotification level={level.toLowerCase()} closeButton={closeButton}>
            {children}
        </ToastNotification>,
        extraProps
    );
};

export default notify;
