import { useNotificationSubscription } from '../../graphql/hooks';
import { useGoogleTagManager } from '../../services/analytics/hooks';
import { useOnUpdateAdviserBackOfficeIntegrationStatusSubscription } from '../../store/adviser-back-office-integrations/hooks';
import { useUpdateAdviserPortfolioDetailsSubscription } from '../../store/adviser-portfolio-details/hooks';
import { useUpdateAdviserPortfoliosSubscription } from '../../store/adviser-portfolios/hooks';
import { useAppConfigSubscription } from '../../store/app/hooks';
import { useNewMessagesSubscription } from '../../store/customer-messages/hooks';
import { useProfileSubscription } from '../../store/profile/hooks';

export const Subscriptions = () => {
    useAppConfigSubscription();
    useNewMessagesSubscription();
    useNotificationSubscription();
    useProfileSubscription();
    useUpdateAdviserPortfoliosSubscription();
    useUpdateAdviserPortfolioDetailsSubscription();
    useOnUpdateAdviserBackOfficeIntegrationStatusSubscription();
    return null;
};

export const Analytics = () => {
    useGoogleTagManager();
    return null;
};
