import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import setAccountNotifications from '../../store/account-notifications/setAccountNotifications';
import { useApp } from '../../store/app/hooks';
import setAppearanceMode from '../../store/appearance/setAppearanceMode';
import { STORE_STATUS_INIT } from '../../store/statuses';
import { CookiePro } from '../cookie-pro';
import ToastContainer from '../toast-notifications/styles';
import AppConfig from './AppConfig';
import { Analytics, Subscriptions } from './Utilities';

const App = () => {
    const { status } = useApp();
    const dispatch = useDispatch();
    const setupApp = useCallback(() => {
        dispatch(setAccountNotifications());
        dispatch(setAppearanceMode());
    }, [dispatch]);

    useEffect(() => {
        setupApp();
    }, [setupApp]);

    return (
        <>
            <ToastContainer />

            <CookiePro />

            {status !== STORE_STATUS_INIT && (
                <>
                    <AppConfig />
                    <Subscriptions />
                    <Analytics />
                </>
            )}
        </>
    );
};

export default App;
