import { gql } from '@apollo/client';

export const onNewCustomerMessagesSubscription = gql`
    subscription OnNewCustomerMessages {
        onNewCustomerMessages {
            unreadCount
            threads {
                id
                subject
                excerpt
                dateTime
                policyId
                customerId
                messages {
                    id
                    from
                    dateTime
                    read
                    body
                }
            }
        }
    }
`;
