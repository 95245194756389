import { Map } from 'immutable';
import { ORIGINATING_COMPANIES_REQUESTED, ORIGINATING_COMPANIES_RESOLVED, ORIGINATING_COMPANIES_ERROR } from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    companies: null,
};

const initialState = Map(schema);

const originatingCompaniesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ORIGINATING_COMPANIES_REQUESTED:
            return state.set('loading', true);

        case ORIGINATING_COMPANIES_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('loading', false).set('companies', payload);

        case ORIGINATING_COMPANIES_ERROR:
            return state.set('status', STORE_STATUS_ERROR).set('loading', false).set('companies', null);

        case STORE_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default originatingCompaniesReducer;
