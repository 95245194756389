import { ACTIVATION_STEPS_SET } from './types';
import { STORE_RESET } from '../types'

const initialState = null;

/**
*
* The Activation Steps reducer
*
* Controls the activation steps required by an active user
*
*/
const activationStepsReducer = (state = initialState, { type, payload }) => {

    switch(type) {

        case STORE_RESET:

            return initialState;

        case ACTIVATION_STEPS_SET:

            return payload;

        default:

            return state;
    }
};

export { initialState };

export default activationStepsReducer;