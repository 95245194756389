/**
 * Styled-components theme for Reassure-UI panel components
 */

import pallette from './pallette';
const {
    transparent,
    white,
    lightBlue,
    darkBlue,
    midRed,
} = pallette;


const themeConfig = {
    colors: {
        accordion: {
            background: 'hsl(220, 43%, 97%)', // background1
            border: lightBlue,
        },
        card: {
            text: darkBlue,
            background: white,
            border: white,
            boxShadow: '0 12px 24px rgba(18,38,63,.08)',
            contrast: {
                text: darkBlue,
                background: lightBlue,
                border: lightBlue,
            },
            error: {
                text: white,
                background: midRed,
                border: midRed,
            }
        },
        drawer: {
            text: white,
            background: darkBlue,
            contrast: {
                text: white,
                background: lightBlue,
            }
        },
        errorCard: {
            text: white,
            background: transparent,
            border: midRed,
        }
    }
}

export default themeConfig;