const updateQuestions = (state, questions) => {

    const questionState = state.get('questions');
    const newQuestions = {};

    questions.forEach(question => {

        const questionId = `question${question.id}`;

        if(questionState && questionState[questionId]){
            newQuestions[questionId] = Object.assign({}, questionState[questionId], question);
            newQuestions[questionId].status = 'ANSWERED';
        } else if(questionState) {
            newQuestions[questionId] = Object.assign({}, question);
            newQuestions[questionId].status = 'NEW';
        } else {
            newQuestions[questionId] = Object.assign({}, question);
            newQuestions[questionId].status = 'INIT';
        }
    });

    return newQuestions;

};

export default updateQuestions;
