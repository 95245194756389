import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import httpRequestService from '../services/http/request';
import { APP_UPDATE } from '../store/app/types';
import { store } from '../store';
import { STORE_STATUS_MAINTENANCE } from '../store/statuses';

/**
 * Add authentication headers to the apollo request
 */
export const authMiddleware = setContext(async (_, { headers }) => {
    try {
        const authorization = await httpRequestService.getAuthorizeHeader();

        return {
            headers: { ...headers, ...authorization },
        };
    } catch (error) {
        throw error;
    }
});

export const maintenanceAfterware = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 503)
        store.dispatch({
            type: APP_UPDATE,
            payload: {
                status: STORE_STATUS_MAINTENANCE,
            },
        });
});

export const versionMiddleware = new ApolloLink((operation, forward) => {
    const xRcpVersion = httpRequestService.getVersionHeader();
    // add the RCP_VERSION to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, ...xRcpVersion },
    }));

    return forward(operation);
});

export const digitalFingerprintMiddleware = new ApolloLink(async (operation, forward) => {
    const xDigitalFingerprint = await httpRequestService.getDigitalFingerprint();
    operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, ...xDigitalFingerprint },
    }));

    return forward(operation);
});
