import { Map } from 'immutable';
import {
    SCHEME_NOMINATION_SET_SCHEME_NUMBER,
    SCHEME_NOMINATION_SET_TRUSTEE_DETAILS,
    SCHEME_NOMINATION_STATUS_SCHEME_NUMBER,
    SCHEME_NOMINATION_STATUS_TRUSTEE_DETAILS,
    SCHEME_NOMINATION_STATUS_SUPERUSER_DETAILS,
    SCHEME_NOMINATION_SET_SUPERUSER_DETAILS,
    SCHEME_NOMINATION_STATUS_SUMMARY,
    SCHEME_NOMINATION_STATUS_SUCCESS,
    SCHEME_NOMINATION_SET_SUPERUSER_SUCCESS,
} from './types';

export const initialState = Map({
    status: SCHEME_NOMINATION_STATUS_SCHEME_NUMBER,
    validation: {
        reference: null,
        validationId: null,
    },
});

const schemeNominationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SCHEME_NOMINATION_SET_SCHEME_NUMBER:
            return state.set('status', SCHEME_NOMINATION_STATUS_TRUSTEE_DETAILS).set('validation', payload.validation);
        case SCHEME_NOMINATION_SET_TRUSTEE_DETAILS:
            return state.set('status', SCHEME_NOMINATION_STATUS_SUPERUSER_DETAILS).set('nominator', payload);
        case SCHEME_NOMINATION_SET_SUPERUSER_DETAILS:
            return state.set('status', SCHEME_NOMINATION_STATUS_SUMMARY).set('nominee', payload);
        case SCHEME_NOMINATION_SET_SUPERUSER_SUCCESS:
            return state.set('status', SCHEME_NOMINATION_STATUS_SUCCESS);
        default:
            return state;
    }
};

export default schemeNominationReducer;
