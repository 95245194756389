/**
 * Styled-components theme for page body
 */

const themeConfig = {
    colors: {
        body: {
            background: 'hsl(220, 43%, 97%)',
            alternateBackground: 'hsl(223, 20%, 94%)',
        }
    }
}

export default themeConfig;