export const CUSTOMER_MESSAGES_REQUESTED = 'CUSTOMER_MESSAGES_REQUESTED';
export const CUSTOMER_MESSAGES_LOADING = 'CUSTOMER_MESSAGES_LOADING';
export const CUSTOMER_MESSAGES_RESOLVED = 'CUSTOMER_MESSAGES_RESOLVED';
export const CUSTOMER_MESSAGES_ERROR = 'CUSTOMER_MESSAGES_ERROR';
export const CUSTOMER_MESSAGE_READ = 'CUSTOMER_MESSAGE_READ';
export const UNREAD_THREAD_COUNT_UPDATE = 'UNREAD_THREAD_COUNT_UPDATE';
export const SEND_CUSTOMER_MESSAGE_SENDING = 'SEND_CUSTOMER_MESSAGE_SENDING';
export const SEND_CUSTOMER_MESSAGE_SENT = 'SEND_CUSTOMER_MESSAGE_SENT';
export const SEND_CUSTOMER_MESSAGE_ERROR = 'SEND_CUSTOMER_MESSAGE_ERROR';
export const SEND_CUSTOMER_MESSAGE_RESET = 'SEND_CUSTOMER_MESSAGE_RESET';
export const SEND_CUSTOMER_MESSAGE_SET_CONTEXT = 'SEND_CUSTOMER_MESSAGE_SET_CONTEXT';
