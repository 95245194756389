import { Map } from 'immutable';
import {
    ADVISER_PORTFOLIOS_REQUESTED,
    ADVISER_PORTFOLIOS_RESOLVED,
    ADVISER_PORTFOLIOS_ERROR,
    ADVISER_PORTFOLIOS_UPDATE_PORTFOLIO,
} from './types';

import { STORE_RESET } from '../types';
import { STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';
import { updateAdviserPortfolio } from './helpers';

const schema = {
    status: STORE_STATUS_INIT,
    portfolios: null,
};

export const initialState = Map(schema);

const adviserPortfoliosReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case ADVISER_PORTFOLIOS_REQUESTED:
            return state.set('status', 'LOADING');
        case ADVISER_PORTFOLIOS_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('portfolios', payload);
        case ADVISER_PORTFOLIOS_ERROR:
            return state.set('status', payload).set('portfolios', null);
        case ADVISER_PORTFOLIOS_UPDATE_PORTFOLIO:
            return updateAdviserPortfolio(payload, state);
        default:
            return state;
    }
};

export default adviserPortfoliosReducer;
