import { Map } from 'immutable';
import { FUND_SELECTOR_UPDATE } from './types';
import { STORE_RESET } from '../types';

const schema = {
    matchesCount: null,
};

const initialState = Map(schema);

const fundSelectorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FUND_SELECTOR_UPDATE:
            const currentState = state.toJS();
            return Map({ ...currentState, ...payload });

        case STORE_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default fundSelectorReducer;
