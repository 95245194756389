import { Map } from 'immutable';
import {
    POLICIES_REQUESTED,
    POLICIES_RESOLVED,
    POLICIES_ERROR,
    POLICY_REQUESTED,
    POLICY_RESOLVED,
    POLICY_ERROR,
    REMOVE_POLICY_CARD,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
};

const initialState = Map(schema);

const updatePolicy = (state, policy) => {
    const policyState = state.get(policy.policyId);

    return policyState ? Object.assign({}, policyState, policy) : policy;
};

/**
 *
 * The Policies Reducer
 *
 * Responsible for managing the state and data of customer policies
 *
 */
const policiesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case POLICIES_REQUESTED:
            return state.set('loading', true);

        case POLICIES_RESOLVED:
            Object.keys(payload.policies).forEach(policyId => {
                let policy = payload.policies[policyId];
                state = state.set(policyId, updatePolicy(state, policy));
            });

            state = state.set('loading', false);
            state = state.set('status', STORE_STATUS_OK);

            return state;

        case POLICIES_ERROR:
            return state.set('loading', false).set('status', STORE_STATUS_ERROR);

        case STORE_RESET:
            return initialState;

        case POLICY_REQUESTED:
            return state.set(payload.policyId, updatePolicy(state, { policyId: payload.policyId, loading: true }));

        case POLICY_RESOLVED:
            let policy = Object.assign({}, payload.policy, { status: STORE_STATUS_OK, loading: false });

            state = state.set(payload.policy.policyId, updatePolicy(state, policy));

            return state;

        case POLICY_ERROR:
            state = state.set(
                payload.policyId,
                updatePolicy(state, { status: STORE_STATUS_ERROR, policyId: payload.policyId, loading: false })
            );

            return state;

        case REMOVE_POLICY_CARD:
            const { policyId, card } = payload;
            const policyState = state.get(policyId);
            if (!policyState || !policyState.cards) return state;
            return state.setIn(
                [payload.policyId, 'cards'],
                policyState.cards.filter(existingCard => existingCard !== card)
            );

        default:
            return state;
    }
};

export { initialState };

export default policiesReducer;
