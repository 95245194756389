export const CHALLENGE_ACCOUNT_REQUESTED = 'CHALLENGE_ACCOUNT_REQUESTED';
export const CHALLENGE_ACCOUNT_RESOLVED = 'CHALLENGE_ACCOUNT_RESOLVED';
export const CHALLENGE_ACCOUNT_ERROR = 'CHALLENGE_ACCOUNT_ERROR';
export const VERIFY_CHALLENGE_ACCOUNT_REQUESTED = 'VERIFY_CHALLENGE_ACCOUNT_REQUESTED';
export const VERIFY_CHALLENGE_ACCOUNT_RESOLVED = 'VERIFY_CHALLENGE_ACCOUNT_RESOLVED';
export const VERIFY_CHALLENGE_ACCOUNT_ERROR = 'VERIFY_CHALLENGE_ACCOUNT_ERROR';
export const CHALLENGE_ACCOUNT_POLICYID_RESOLVED = 'CHALLENGE_ACCOUNT_POLICYID_RESOLVED';
export const CHALLENGE_ACCOUNT_IDENTITY_PASSED = 'CHALLENGE_ACCOUNT_IDENTITY_PASSED';
export const CREATE_CHALLENGE_ACCOUNT_SECURITY_REQUESTED = 'CREATE_CHALLENGE_ACCOUNT_SECURITY_REQUESTED';
export const CREATE_CHALLENGE_ACCOUNT_SECURITY_RESOLVED = 'CREATE_CHALLENGE_ACCOUNT_SECURITY_RESOLVED';
export const CREATE_CHALLENGE_ACCOUNT_SECURITY_ERROR = 'CREATE_CHALLENGE_ACCOUNT_SECURITY_ERROR';