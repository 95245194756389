export const updateAdviserPortfolio = (payload, state) => {
    const portfolios = state.get('portfolios').map(portfolio => {
        return portfolio.id === payload.id ? Object.assign({}, portfolio, payload) : portfolio;
    });

    return state.set('portfolios', portfolios);
};

export const filterPortfolios = (portfolios, filters) => {
    if (!filters || !Object.keys(filters).length) return portfolios;

    let result = portfolios;

    const { searchterm } = filters;

    if (searchterm) {
        result = portfolios.filter(({ name }) => name.toLowerCase().includes(searchterm.toLowerCase()));
    }

    return result;
};
