/**
 * Styled-components theme for Reassure-UI modal components
 */

import { lighten } from 'polished';
import pallette from './pallette';
const {
    white,
    darkBlue,
    black,
} = pallette;


const themeConfig = {
    colors: {
        modals: {
            text: darkBlue,
            background: white,
            separators: 'rgba(0,0,0,0.2)',
            overlay: 'rgba(0,5,15,0.8)',
            link: {
                text: black,
                hover: {
                    text: lighten(0.1, black),
                }
            }
        },
    }
}

export default themeConfig;