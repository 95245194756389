import {
    PROFILE_RESOLVED,
} from './types';

const onProfileDetails = (profile) => {
    return {
        type: PROFILE_RESOLVED,
        payload: {
            profile
        }
    }
}

export default onProfileDetails;
