import { Map } from 'immutable';
import { 
    ACCOUNT_NOTIFICATIONS_SET
} from './types';

/**
*
* Set the initial state of our app
*
*/
const schema = {
    visualPreferences: false,
    adviserClientPoliciesDownloads: false,
}

const initialState = Map(schema);

/**
*
* The Account Notifications Reducer
*
* Controls the current state of account notification in the app
* Account notifications are triggered on login of an activated user
*
*/
const accountNotificationsReducer = (state = initialState, action) => {

    switch (action.type) {

        case ACCOUNT_NOTIFICATIONS_SET:

            if (!action.payload || !Object.keys(action.payload).length) return state;

            for (const key of Object.keys(action.payload)) {
                if (state.get(key) === undefined) continue;
                state = state.set(key, action.payload[key]);
            }

            return state;
        
        default:

            return state;
    }
};

export { initialState };

export default accountNotificationsReducer;