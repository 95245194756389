import { graphQLQuery } from '../../graphql/client';
import { validateAdviserPortfolioFundsQuery } from '../../graphql/queries/advisers';
import { ADVISER_PORTFOLIO } from './profile-types';

export const getNewFund = async ({ funds, reAssureId, id, type }) => {
    let invalidPolicies = [];
    if (type === ADVISER_PORTFOLIO) {
        invalidPolicies = await graphQLQuery(validateAdviserPortfolioFundsQuery, {
            portfolioId: id,
            funds: [{ id: reAssureId }],
        });
    }
    const units = funds.filter(fund => fund.reAssureId === reAssureId);
    return {
        allocation: 0,
        abiSectorName: units[0]?.abiSectorName,
        confirmMultiUnitFundOnSelection: units?.length > 1,
        confirmCashFundOnSelection: units?.filter(unit => unit.cashLike === true).length > 0,
        name: units[0]?.reAssureFundName || units[0]?.name,
        reAssureId,
        invalidPolicies,
        units,
    };
};

export const createFundProfile = funds => {
    const fundProfile = {};
    if (!funds || !funds.length) return fundProfile;
    for (const fund of funds) {
        const { reAssureId, units, allocation } = fund;
        if (!reAssureId) continue;
        fundProfile[reAssureId] = {
            ...fund,
            allocation: allocation || 0,
            confirmMultiUnitFundOnSelection: units.length > 1,
            confirmCashFundOnSelection: units.filter(unit => unit.cashLike === true).length > 0,
        };
    }

    return fundProfile;
};

export const getUpdateAdviserPortfolioFundsInput = ({ id: portfolioId, funds }) => {
    return {
        portfolioId,
        funds: funds.map(({ reAssureId, allocation }) => ({
            reAssureId,
            value: allocation,
        })),
    };
};
