// Transform threads from array to object with id keys
const transformTheads = (threads) => {

    const newThreads = {};

    threads.forEach(thread => {
        newThreads[thread.id] = thread;
    });

    return newThreads;

};

export default transformTheads;
