import { LOCATION_CHANGE } from 'connected-react-router';
import { List } from 'immutable';

const initialState = List([]);
const limit = 10;

/**
 * Holds the state for router history
 *
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
const routerHistoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOCATION_CHANGE:
            state = state.push(payload);
            if (state.size > limit) {
                state = state.shift();
            }
            return state;
        default:
            return state;
    }
};

export { initialState };

export default routerHistoryReducer;
