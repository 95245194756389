/**
 * Styled-components theme for page body
 */

import pallette from './pallette';
const {
    graphite,
    black,
} = pallette;

const themeConfig = {
    colors: {
        body: {
            background: graphite,
            alternateBackground: black,
        }
    }
}

export default themeConfig;