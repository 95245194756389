import { useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onProfileDetailsSubscription } from '../../graphql/subscriptions/profile';
import getContent from '../../services/content/getContent';
import { STORE_STATUS_INIT } from '../statuses';
import { useUser } from '../user/hooks';
import getProfileDetails from './getProfileDetails';
import onProfileDetails from './onProfileDetails';
import { selectProfile } from './selectors';

export const useProfile = () => {
    const dispatch = useDispatch();
    const { status, loading, profile } = useSelector(selectProfile);
    const getProfile = useCallback(async () => {
        await dispatch(getProfileDetails);
    }, [dispatch]);

    const customerName =
        profile && profile.title && profile.firstName && profile.lastName
            ? `${profile.title}. ${profile.firstName} ${profile.lastName}`
            : getContent(['your-account', 'fallback']);

    useEffect(() => {
        if (status === STORE_STATUS_INIT && !loading) {
            getProfile();
        }
    }, [getProfile, status, loading]);

    return { status, loading, profile, customerName };
};

export const useProfileSubscription = () => {
    const { currentLogin } = useUser();
    const dispatch = useDispatch();
    useSubscription(onProfileDetailsSubscription, {
        skip: !currentLogin,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            if (!data || !data.onProfileDetails) return;
            dispatch(onProfileDetails(data.onProfileDetails));
        },
    });
};
