import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import { POLICY_INCOME_HISTORY_REQUESTED, POLICY_INCOME_HISTORY_RESOLVED, POLICY_INCOME_HISTORY_ERROR } from './types';

/**
 *
 * Set the initial state of policy projection reducer
 *
 */
const schema = {};

const initialState = Map(schema);

/**
 * Builds the model for each policy state
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePolicy = (state, { type, payload }) => {
    let update;

    switch (type) {
        case POLICY_INCOME_HISTORY_REQUESTED:
            update = Object.assign(payload, { loading: true });
            break;
        case POLICY_INCOME_HISTORY_RESOLVED:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_OK });
            break;
        case POLICY_INCOME_HISTORY_ERROR:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_ERROR });
            break;
        default:
            update = payload;
    }

    const policyIncomeHistory = state.get(payload.policyId);

    return policyIncomeHistory ? Object.assign({}, policyIncomeHistory, update) : update;
};

/**
 *
 * The Policy Income History Reducer
 *
 * Responsible for managing the state and data of customer policy income history
 *
 */
const policyIncomeHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case POLICY_INCOME_HISTORY_REQUESTED:
            return state.set(action.payload.policyId, updatePolicy(state, action));

        case POLICY_INCOME_HISTORY_RESOLVED:
            return state.set(action.payload.policyId, updatePolicy(state, action));

        case POLICY_INCOME_HISTORY_ERROR:
            return state.set(action.payload.policyId, updatePolicy(state, action));

        default:
            return state;
    }
};

export { initialState };

export default policyIncomeHistoryReducer;
