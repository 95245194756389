export const LOG_IN = 'Log In';
export const ROLE_LOG_IN = 'Role Log In';
export const GROUP_LOG_IN = 'Group Log In';
export const LOG_OUT = 'Log Out';
export const ACCOUNT_MANAGEMENT = 'Account Management';
export const SECURE_MESSAGES = 'Secure Messages';
export const INVITATION = 'Invitation';
export const MULTI_FACTOR = 'Multi Factor';
export const SECURITY_QUESTIONS = 'Security Questions';
export const POLICY_TYPE = 'Policy Type';
export const ADVISER_CLIENTS = 'Adviser Clients';
export const ADVISER_USERS = 'Adviser Users';
export const POLICY_PROJECTION = 'Policy Projection';
export const UI_TOGGLE = 'UI Toggle';