/**
 * Styled-components theme for Reassure-UI badge components
 */

import pallette from './pallette';
const {
    darkBlue,
    transparent,
} = pallette;


const themeConfig = {
    colors: {
        badge: {
            text: darkBlue,
            background: transparent,
            border: darkBlue,
        }
    }
}

export default themeConfig;