export const AGENCY_GROUP_USERS_REQUESTED = 'AGENCY_GROUP_USERS_REQUESTED';
export const AGENCY_GROUP_USERS_RESOLVED = 'AGENCY_GROUP_USERS_RESOLVED';
export const AGENCY_GROUP_USERS_ERROR = 'AGENCY_GROUP_USERS_ERROR';
export const ADD_AGENCY_GROUP_USER_REQUESTED = 'ADD_AGENCY_GROUP_USER_REQUESTED';
export const ADD_AGENCY_GROUP_USER_RESOLVED = 'ADD_AGENCY_GROUP_USER_RESOLVED';
export const ADD_AGENCY_GROUP_USER_ERROR = 'ADD_AGENCY_GROUP_USER_ERROR';
export const ADD_AGENCY_GROUP_USER_RESET = 'ADD_AGENCY_GROUP_USER_RESET';
export const REMOVE_AGENCY_GROUP_USER_REQUESTED = 'REMOVE_AGENCY_GROUP_USER_REQUESTED';
export const REMOVE_AGENCY_GROUP_USER_RESOLVED = 'REMOVE_AGENCY_GROUP_USER_RESOLVED';
export const REMOVE_AGENCY_GROUP_USER_ERROR = 'REMOVE_AGENCY_GROUP_USER_ERROR';
export const REMOVE_AGENCY_GROUP_USER_ERROR_CODE = 'REMOVE_AGENCY_GROUP_USER_ERROR_CODE';
export const REMOVE_AGENCY_GROUP_USER_RESET = 'REMOVE_AGENCY_GROUP_USER_RESET';
