import {
    CUSTOMER_MESSAGES_LOADING,
    CUSTOMER_MESSAGES_RESOLVED,
    CUSTOMER_MESSAGES_ERROR,
    CUSTOMER_MESSAGE_READ,
    SEND_CUSTOMER_MESSAGE_SENDING,
    SEND_CUSTOMER_MESSAGE_SENT,
    SEND_CUSTOMER_MESSAGE_ERROR,
} from './types';
import transformThreads from './transformThreads';
import { graphQLMutation, graphQLQuery } from '../../graphql/client';
import { getCustomerMessagesQuery } from '../../graphql/queries/secure-messages';
import { getUnreadMessageFromThread } from './helpers';
import analyticsService from '../../services/analytics/analytics';
import { SECURE_MESSAGES } from '../../services/analytics/categories';
import { markCustomerMessageReadMutation, sendCustomerMessageMutation } from '../../graphql/mutations/secure-messages';
import notify from '../../components/toast-notifications/notify';
import getContent from '../../services/content/getContent';

/**
 * getCustomerMessages - returns the message threads for the customer
 * @return {Object} [Object containing the uneadCount and the Thread array]
 */
export const getCustomerMessagesAction = async dispatch => {
    dispatch({ type: CUSTOMER_MESSAGES_LOADING });

    try {
        const { threads, unreadCount } = await graphQLQuery(getCustomerMessagesQuery);
        return dispatch({
            type: CUSTOMER_MESSAGES_RESOLVED,
            payload: {
                unreadCount,
                threads: transformThreads(threads),
            },
        });
    } catch (error) {
        return dispatch({
            type: CUSTOMER_MESSAGES_ERROR,
        });
    }
};

/**
 * onNewCustomerMessagesAction - updates the message threads for the customer
 * @return {Object} [Object containing the uneadCount and the Thread array]
 */
export const onNewCustomerMessagesAction = ({ unreadCount, threads }) => {
    return {
        type: CUSTOMER_MESSAGES_RESOLVED,
        payload: {
            unreadCount,
            threads: transformThreads(threads),
        },
    };
};

/**
 * markCustomerMessageRead - marks a specific messageId as read
 * @param threadId - the theadId the message belongs to (in order to find it more quickly)
 * @return {Boolean} [true - the message has been read]
 */
export const markCustomerMessageReadAction = async threadId => async (dispatch, getState) => {
    const state = getState().customerMessages;
    const messageId = getUnreadMessageFromThread(state.get('threads')[threadId]);

    try {
        if (!messageId) return;

        analyticsService.logEvent({
            event: SECURE_MESSAGES,
            action: 'User opened unread message',
        });

        const read = await graphQLMutation(markCustomerMessageReadMutation, { id: messageId });

        if (!read) return messageId;

        return dispatch({
            type: CUSTOMER_MESSAGE_READ,
            payload: {
                threadId,
                messageId,
            },
        });
    } catch (error) {
        throw error;
    }
};

/**
 * sendCustomerMessageAction - send a customer message to ReAssure
 * @param {Object} - customerMessage - a customer message with the following properties (subject, body, policyId, messageLevel, productType, typeId, categoryId, categoryDescription, customerIdReference)
 * @return {Boolean} [true - the message has been sent]
 */
export const sendCustomerMessageAction = async message => async dispatch => {
    dispatch({
        type: SEND_CUSTOMER_MESSAGE_SENDING,
    });

    try {
        await graphQLMutation(sendCustomerMessageMutation, message);
        notify('info', getContent(['customer-messages', 'send-customer-message', 'message-sent-notification']));
        return dispatch({
            type: SEND_CUSTOMER_MESSAGE_SENT,
        });
    } catch (error) {
        return dispatch({
            type: SEND_CUSTOMER_MESSAGE_ERROR,
        });
    }
};
