import { Map } from 'immutable';
import { CUSTOMER_DOCUMENTS_REQUESTED, CUSTOMER_DOCUMENTS_RESOLVED, CUSTOMER_DOCUMENTS_ERROR } from './types';
import { STORE_RESET } from '../types';
import { transformDocuments } from './helpers';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of our documents
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    documents: null,
};

const initialState = Map(schema);

/**
 *
 * The Customer Documents Reducer
 *
 * Responsible for managing the state and data for customer documents
 *
 */
const customerDocumentsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case CUSTOMER_DOCUMENTS_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);

        case CUSTOMER_DOCUMENTS_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('documents', transformDocuments(payload));

        case CUSTOMER_DOCUMENTS_ERROR:
            return state.set('status', STORE_STATUS_ERROR).set('documents', null);

        default:
            return state;
    }
};

export { initialState };

export default customerDocumentsReducer;
