import { Map } from 'immutable';
import {
    ADVISER_CLIENT_POLICIES_REQUESTED,
    ADVISER_CLIENT_POLICIES_RESOLVED,
    ADVISER_CLIENT_POLICIES_ERROR,
    ADVISER_CLIENT_POLICIES_NEXT_PAGE,
    ADVISER_CLIENT_POLICIES_PREV_PAGE,
    ADVISER_CLIENT_POLICIES_SET_FILTER,
    ADD_ADVISER_CLIENT_POLICIES_REQUESTED,
    ADD_ADVISER_CLIENT_POLICIES_RESOLVED,
    ADD_ADVISER_CLIENT_POLICIES_ERROR,
    RESET_ADD_ADVISER_CLIENT_POLICIES,
    ADVISER_CLIENT_POLICIES_DOWNLOAD_REQUESTED,
    ADVISER_CLIENT_POLICIES_DOWNLOAD_RESOLVED,
    ADVISER_CLIENT_POLICIES_DOWNLOAD_ERROR,
    ADVISER_CLIENT_POLICIES_DOWNLOADS_REQUESTED,
    ADVISER_CLIENT_POLICIES_DOWNLOADS_RESOLVED,
    ADVISER_CLIENT_POLICIES_DOWNLOADS_ERROR,
} from './types';

import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    clients: null,
    addedStatus: STORE_STATUS_OK,
    adding: false,
    requestDownload: {
        status: STORE_STATUS_INIT,
        loading: false,
    },
    downloads: {
        status: STORE_STATUS_INIT,
        loading: false,
        requests: null,
    },
};

export const RESULTS_PER_PAGE = 10;

export const transformClients = clients => {
    if (!clients || !clients.length) return [];

    const seperator = '\n';

    return clients.map(client => {
        const { firstName, lastName, policies } = client;
        let keywordIndex = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
        if (policies.length) {
            for (const { policyNumber } of policies) {
                keywordIndex = `${keywordIndex}${seperator}${policyNumber.toUpperCase()}`;
            }
        }
        return {
            ...client,
            keywordIndex,
        };
    });
};

export const filterClients = (clients, filters) => {
    if (!filters) return clients;
    const filterKeys = Object.keys(filters);
    if (!filterKeys || !filterKeys.length) return clients;
    for (const filterKey of filterKeys) {
        if (filterKey === 'keyword' && filters.keyword.toUpperCase().length) {
            clients = clients.filter(client => client.keywordIndex.indexOf(filters.keyword.toUpperCase()) !== -1);
        }
        if (!clients.length) return [];
    }

    return clients;
};

export const getClientPolicyFragment = (clients, page = 1) => {
    if (!clients || !clients.length) return [];
    const startingIndex = (page - 1) * RESULTS_PER_PAGE;
    const endingIndex = Math.min(startingIndex + RESULTS_PER_PAGE, clients.length);
    return clients.slice(startingIndex, endingIndex);
};

export const initialiseClientPolicyResults = (state, clients) => {
    const transformedClients = transformClients(clients);
    state = state.set('status', STORE_STATUS_OK).set('loading', false).set('clients', {
        all: transformedClients,
        result: transformedClients,
        page: 1,
        resultsPerPage: RESULTS_PER_PAGE,
    });

    return state;
};

export const setClientPolicyResultsFromFilter = (state, filter) => {
    for (const key of Object.keys(filter)) {
        if (filter[key].length) {
            state = state.setIn(['clients', 'filters', key], filter[key]);
        } else {
            state = state.deleteIn(['clients', 'filters', key]);
        }
    }

    const filters = state.getIn(['clients', 'filters']);

    if (filters && Object.keys(filters).length) {
        state = state.setIn(['clients', 'result'], filterClients(state.getIn(['clients', 'all']), filters));
        state = state.setIn(['clients', 'matches'], state.getIn(['clients', 'result']).length);
    } else {
        state = state.setIn(['clients', 'result'], state.getIn(['clients', 'all']));
        state = state.setIn(['clients', 'matches'], undefined);
    }

    return state;
};

export const setClientPolicyFragment = state =>
    state.setIn(
        ['clients', 'fragment'],
        getClientPolicyFragment(state.getIn(['clients', 'result']), state.getIn(['clients', 'page']))
    );

export const setClientPolicyPage = (state, page) => state.setIn(['clients', 'page'], page);

const initialState = Map(schema);

const adviserClientPoliciesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case ADVISER_CLIENT_POLICIES_REQUESTED:
            return state.set('loading', true);

        case ADVISER_CLIENT_POLICIES_RESOLVED:
            state = initialiseClientPolicyResults(state, action.payload);

            return setClientPolicyFragment(state);

        case ADVISER_CLIENT_POLICIES_PREV_PAGE:
            state = setClientPolicyPage(state, state.getIn(['clients', 'page']) - 1);

            return setClientPolicyFragment(state);

        case ADVISER_CLIENT_POLICIES_NEXT_PAGE:
            state = setClientPolicyPage(state, state.getIn(['clients', 'page']) + 1);

            return setClientPolicyFragment(state);

        case ADVISER_CLIENT_POLICIES_SET_FILTER:
            state = setClientPolicyPage(state, 1);

            state = setClientPolicyResultsFromFilter(state, action.payload);

            return setClientPolicyFragment(state);

        case ADVISER_CLIENT_POLICIES_ERROR:
            return state.set('status', STORE_STATUS_ERROR).set('loading', false).set('clients', null);

        case ADD_ADVISER_CLIENT_POLICIES_REQUESTED:
            return state.set('adding', true).set('addedStatus', STORE_STATUS_OK);

        case ADD_ADVISER_CLIENT_POLICIES_RESOLVED:
            if (!action.payload.errorCode) {
                state = initialiseClientPolicyResults(state, action.payload.clients);

                return setClientPolicyFragment(state);
            }

            return state.set('addedStatus', action.payload.errorCode).set('adding', false);

        case ADD_ADVISER_CLIENT_POLICIES_ERROR:
            return state.set('addedStatus', STORE_STATUS_ERROR).set('adding', false).set('clients', null);

        case RESET_ADD_ADVISER_CLIENT_POLICIES:
            return state.set('addedStatus', STORE_STATUS_OK);

        case ADVISER_CLIENT_POLICIES_DOWNLOAD_REQUESTED:
            return state.set('requestDownload', {
                status: STORE_STATUS_INIT,
                loading: true,
            });

        case ADVISER_CLIENT_POLICIES_DOWNLOAD_RESOLVED:
            return state.set('requestDownload', {
                status: STORE_STATUS_OK,
                loading: false,
            });

        case ADVISER_CLIENT_POLICIES_DOWNLOAD_ERROR:
            return state.set('requestDownload', {
                status: STORE_STATUS_ERROR,
                loading: false,
            });

        case ADVISER_CLIENT_POLICIES_DOWNLOADS_REQUESTED:
            return state.setIn(['downloads', 'loading'], true);

        case ADVISER_CLIENT_POLICIES_DOWNLOADS_RESOLVED:
            return state.set('downloads', {
                status: STORE_STATUS_OK,
                loading: false,
                requests: action.payload,
            });

        case ADVISER_CLIENT_POLICIES_DOWNLOADS_ERROR:
            return state.set('downloads', {
                status: STORE_STATUS_ERROR,
                loading: false,
                requests: null,
            });

        default:
            return state;
    }
};

export { initialState };

export default adviserClientPoliciesReducer;
