import { Map } from 'immutable';
import {
    SEND_CUSTOMER_MESSAGE_SENDING,
    SEND_CUSTOMER_MESSAGE_SENT,
    SEND_CUSTOMER_MESSAGE_ERROR,
    SEND_CUSTOMER_MESSAGE_RESET,
    SEND_CUSTOMER_MESSAGE_SET_CONTEXT,
} from '../customer-messages/types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of our messages
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    context: null,
};

const initialState = Map(schema);

/**
 * The Send Customer Messages Reducer
 * Responsible for managing the state and data for sending a customer message
 */
const sendCustomerMessagesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEND_CUSTOMER_MESSAGE_RESET:
            return initialState;

        case SEND_CUSTOMER_MESSAGE_SENDING:
            return state.set('loading', true);

        case SEND_CUSTOMER_MESSAGE_SENT:
            return state.set('status', STORE_STATUS_OK).set('loading', false);

        case SEND_CUSTOMER_MESSAGE_ERROR:
            return state.set('status', STORE_STATUS_ERROR).set('loading', false);

        case SEND_CUSTOMER_MESSAGE_SET_CONTEXT:
            return state.set('context', payload);

        default:
            return state;
    }
};

export { initialState };

export default sendCustomerMessagesReducer;
