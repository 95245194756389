/**
 * Styled-components theme for Reassure-UI layout components
 */

import { 
    darken,
    rgba,
} from 'polished';
import pallette from './pallette';
const {
    white,
    darkBlue,
} = pallette;


const themeConfig = {
    colors: {
        cookieConsent: {
            text: white,
            background: darken(0.09, darkBlue),
            border: darken(0.09, darkBlue),
        },
        footer: {
            text: white,
            background: darkBlue,
            border: darkBlue,
        },
        scrollArea: {
            scrollbar: rgba(darkBlue, 0.15),
            hover: {
                scrollbar: rgba(darkBlue, 0.4),
            }
        },
        table: {
            border: 'hsla(230, 20%, 91%, 1)',
            header: {
                text: white,
                background: '#687288',
            },
            body: {
                text: darkBlue,
                background: white,
                stripedRow: '#f5f6fa',
            },
            footer: {
                text: white,
                background: '#687288',
            }
        },
        labelDivider: {
            border: darkBlue,
            badge: {
                text: white,
                background: darkBlue,
                border: darkBlue,
            }
        },
    }
}

export default themeConfig;