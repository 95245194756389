import styled, { css, keyframes } from 'styled-components';
import { H1, H2, H3, H4, P, Text, breakpointUp } from '@reassure/ui/core';
import { CrossCircleFilledIcon } from '@reassure/ui/icons/standard';
import { CheckedCircleColourIcon } from '@reassure/ui/icons/colour';
import { ToastContainer, cssTransition } from 'react-toastify';
import reactToastifyCss from 'react-toastify/dist/ReactToastify.css';
import WarningClockIcon from '../icons/warning-clock-icon';

const toastifySlideInDown = () => {
    return keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -600px, 0); 
    }
    90% {
        opacity: 0.9;
        transform: translate3d(0, 0, 0); 
    }
    to {
        opacity: 1;
        transform: none; 
    }
    `;
};

const toastifySlideOutRight = () => {
    return keyframes`
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0); 
    }
    to {
        opacity: 0;
        transform: translate3d(500px, 0, 0); 
    }
    `;
};

const toastSlideInTransitions = cssTransition({
    enter: 'Toastify__slideInDown',
    exit: 'Toastify__slideOutRight',
    collapse: false,
});

const StyledToastContainer = styled(ToastContainer)`
    ${css`
        ${reactToastifyCss}
    `}
    top: 1em !important;
    right: 1em !important;
    width: auto !important;

    .Toastify__toast {
        margin-bottom: 1em;
    }

    ${breakpointUp.sm`
        width: 370px !important;
    `}

    .Toastify__slideInDown {
        animation-name: ${toastifySlideInDown()};
        animation-duration: 750ms;
        animation-timing-function: ease-in;
    }

    .Toastify__slideOutRight {
        animation-name: ${toastifySlideOutRight()};
        animation-duration: 750ms;
        animation-timing-function: ease-in;
    }

    .Toastify__toast {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        background: transparent;

        button.Toastify__close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: transparent;
            opacity: 1;
            color: ${({ theme }) => theme.colors.midGrey};
            &:hover {
                color: ${({ theme }) => theme.colors.blacks[8]};
            }

            svg {
                width: ${({ theme }) => theme.space[6]};
                height: ${({ theme }) => theme.space[6]};
            }
        }

        &:hover {
            button.Toastify__close-button {
                color: ${({ theme }) => theme.colors.blacks[8]};
            }
        }
    }
`;

StyledToastContainer.defaultProps = {
    position: 'top-right',
    autoClose: 6500,
    hideProgressBar: true,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: true,
    pauseOnHover: true,
    closeButton: false,
    transition: toastSlideInTransitions,
};

export default StyledToastContainer;

export const SuccessIcon = styled(CheckedCircleColourIcon)`
    color: ${({ theme }) => theme.colors.success};
    width: 30px;
    height: auto;
    margin: 0 5px;
    position: relative;
    top: -10px;
`;

export const ErrorIcon = styled(CrossCircleFilledIcon)`
    color: ${({ theme }) => theme.colors.danger};
    width: 30px;
    margin: 0 5px;
    position: relative;
    top: 0px;
`;

export const MaintenanceIcon = styled(WarningClockIcon)`
    color: ${({ theme }) => theme.colors.warning};
    width: 30px;
    margin: 0 5px;
    position: relative;
    top: 0px;
`;

export const Content = styled.div`
    width: 100%;
    padding-left: ${({ theme }) => theme.space[4]};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    color: #5a5b5e;

    ${H1},
    ${H2},
    ${H3},
    ${H4},
    ${P},
    ${Text},
    p {
        color: ${({ theme }) => theme.colors.primaryText};
        font-weight: ${({ theme }) => theme.fontWeights.regular};
    }

    p:last-child {
        margin-bottom: 0;
    }
`;
export const getBorderColor = (theme, level) => {
    switch (level) {
        case 'maintenance':
            return theme.colors.warning;
        case 'error':
            return theme.colors.danger;
        default:
            return theme.colors.success;
    }
};

export const Notification = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-left: 5px solid ${({ theme, level }) => getBorderColor(theme, level)};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[4]};
    box-shadow: 0 12px 24px rgba(18, 38, 63, 0.15);

    ${({ closeButton }) =>
        closeButton === true &&
        `
        ${Content} {
            padding-right: 45px;
        }
    `}
`;
