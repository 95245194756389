import { ACCOUNT_NOTIFICATIONS_SET } from './types';
import { getAccountNotifications } from '../../services/localStorage';

const setAccountNotifications = () => {
    const payload = getAccountNotifications();
    return {
        type: ACCOUNT_NOTIFICATIONS_SET,
        payload,
    };
};

export default setAccountNotifications;
