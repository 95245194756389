export const getCustomerMessagesActiveThread = (threadId = null, threads = null) => {
    if (!threadId || !threads) return null;

    return threads[threadId] ? threads[threadId] : null;
};

/**
 * Find an unread message in a thread
 * @param  {Object} thread The message thread
 * @return {Mixed} Message ID if one is found or false if not
 */
export const getUnreadMessageFromThread = thread => {
    if (!thread) return false;

    if (!thread.messages.length) return false;

    const unreadMessages = thread.messages.filter(message => {
        return !message.read;
    });

    if (!unreadMessages.length) return false;

    return unreadMessages[0].id;
};
