import { createSelector } from 'reselect';

export const getCustomerDocumentsState = createSelector(
    state => state.customerDocuments,
    customerDocuments => customerDocuments.toJS()
);

/**
 * Create an object map of documents
 */
export const transformDocuments = documents => {
    let documentObject = {};

    documents.forEach(document => {
        documentObject[document.documentId] = document;
    });

    return documentObject;
};
