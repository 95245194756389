export const ADVISER = 'ADVISER';
export const ADVISER_LEAD = 'ADVISER_LEAD';
export const ADVISER_COMMISSION_AGENT = 'ADVISER_COMMISSION_AGENT';
export const RETAIL = 'RETAIL';
export const RETAIL_MIGRATED = 'RETAIL_MIGRATED';
export const OKTA_RETAIL_GROUP = 'RCP-RETAIL';
export const OKTA_RETAIL_MIGRATED_GROUP = 'RCP-RETAIL-MIGRATED';
export const OKTA_ADVISER_GROUP = 'RCP-ADVISER';
export const OKTA_ADVISER_LEAD_GROUP = 'RCP-ADVISER-LEAD';
export const OKTA_ADVISER_COMMISSION_AGENT_GROUP = 'RCP-ADVISER-COMMISSION-AGENT';
export const SCHEME = 'SCHEME';
export const SCHEME_SUPERUSER = 'SCHEME_SUPERUSER';
