import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import notify from '../../components/toast-notifications/notify';
import getContent from '../../services/content/getContent';
import { getSecureRedirect } from '../../services/secureRoute';
import { useApp } from '../app/hooks';
import { selectUser } from './selectors';
import {
    TERMS_AND_CONDITIONS_ACCEPTED,
    TERMS_AND_CONDITIONS_REQUIRED,
    TERMS_AND_CONDITIONS_UPDATE,
} from './termsAndConditionsStatuses';

export const useUser = () => useSelector(selectUser);

export const useSecureRedirect = () => {
    const user = useUser();
    const { pathname } = useLocation();
    const { config } = useApp();
    const redirect = useMemo(() => getSecureRedirect({ ...user, pathname, config }), [user, pathname, config]);

    if (redirect && pathname === '/activate/notification-preferences') {
        setTimeout(() => {
            notify('info', getContent(['customer-policies', 'welcome-notification']));
        }, 4000);
    }

    return redirect;
};

export const useTermsAndConditionsStatus = () => {
    const { category, termsAndConditionsAccepted, acceptedTermsAndConditions } = useUser();
    const { config } = useApp();

    let status;
    const currentTerms = {
        id: config[`${category}_TERMS_DOCUMENT_ID`],
        version: config[`${category}_TERMS_VERSION_ID`],
        date: config[`${category}_TERMS_PUBLISH_DATE`],
    };

    if (!termsAndConditionsAccepted && !acceptedTermsAndConditions) {
        status = TERMS_AND_CONDITIONS_REQUIRED;
        return { termsAndConditionsAccepted, acceptedTermsAndConditions, currentTerms, status };
    }

    if (termsAndConditionsAccepted && !acceptedTermsAndConditions) {
        status = TERMS_AND_CONDITIONS_UPDATE;
        return { termsAndConditionsAccepted, acceptedTermsAndConditions, currentTerms, status };
    }

    const { id, version, date } = acceptedTermsAndConditions;
    const { id: currentId, version: currentVersion, date: publishDate } = currentTerms;

    if (id !== currentId || version !== currentVersion || moment(date).isBefore(publishDate)) {
        status = TERMS_AND_CONDITIONS_UPDATE;
        return { termsAndConditionsAccepted, acceptedTermsAndConditions, currentTerms, status };
    }

    return {
        termsAndConditionsAccepted,
        acceptedTermsAndConditions,
        currentTerms,
        status: TERMS_AND_CONDITIONS_ACCEPTED,
    };
};
