/**
 * Styled-components theme for Reassure-UI panel components
 */

import pallette from './pallette';
const {
    transparent,
    white,
    graphite,
    charcoal,
    midRed,
} = pallette;


const themeConfig = {
    colors: {
        accordion: {
            background: charcoal,
            border: graphite,
        },
        card: {
            text: white,
            background: graphite,
            border: graphite,
            boxShadow: '0 12px 24px rgba(0,0,0,.08)',
            contrast: {
                text: white,
                background: charcoal,
                border: charcoal,
            },
            error: {
                text: white,
                background: midRed,
                border: midRed,
            }
        },
        drawer: {
            text: white,
            background: charcoal,
            contrast: {
                text: white,
                background: graphite,
            }
        },
        errorCard: {
            text: white,
            background: transparent,
            border: midRed,
        }
    }
}

export default themeConfig;