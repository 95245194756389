import { useState, useEffect } from 'react';
import { getFutureMaintenanceWindowMessage } from '../../services/maintenance/maintenance';
import { isMaintenanceWindowDismissed, setMaintenanceWindowDismissed } from '../../services/sessionStorage';
import { useApp } from '../../store/app/hooks';
import notify from '../toast-notifications/notify';

const PlannedMaintenanceNotification = () => {

    const { maintenanceWindow } = useApp()
    const [dismissed, setDismissed] = useState(isMaintenanceWindowDismissed());
    const message = getFutureMaintenanceWindowMessage(maintenanceWindow)
    const dismissNotification = () => {
        setMaintenanceWindowDismissed()
        setDismissed(isMaintenanceWindowDismissed())
    }

    useEffect(() => {
        if (!message || dismissed) return;
        notify("maintenance", message, {
            autoClose: false,
            onClose: dismissNotification,
            closeButton: true,
        })
    }, [message, dismissed]);

    return null;
}

export default PlannedMaintenanceNotification;