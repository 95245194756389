import { Map } from 'immutable';
import { TRANSACTION_UPDATE } from './types';

const schema = {};

const initialState = Map(schema);

/**
 *
 * The Update Profile Details Reducer
 *
 * Responsible for managing the state of editable profile field transactions
 *
 */
const updateProfileDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TRANSACTION_UPDATE:
            const { type, data } = payload;

            let field = state.get(type);
            field = {
                ...field,
                ...data,
            };
            return state.set(type, field);

        default:
            return state;
    }
};

export { initialState };

export default updateProfileDetailsReducer;
