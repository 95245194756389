import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    ADVISER_PORTFOLIO_AVAILABLE_FUNDS_ERROR,
    ADVISER_PORTFOLIO_AVAILABLE_FUNDS_REQUESTED,
    ADVISER_PORTFOLIO_AVAILABLE_FUNDS_RESOLVED,
} from './types';

export const initialState = Map({});

const adviserPortfolioAvailableFundsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case ADVISER_PORTFOLIO_AVAILABLE_FUNDS_REQUESTED:
            return state.set(payload, {
                status: STORE_STATUS_LOADING,
            });
        case ADVISER_PORTFOLIO_AVAILABLE_FUNDS_ERROR:
            return state.set(payload, {
                status: STORE_STATUS_ERROR,
            });
        case ADVISER_PORTFOLIO_AVAILABLE_FUNDS_RESOLVED:
            const { id, funds, abiSectors } = payload;
            return state.set(id, {
                status: STORE_STATUS_OK,
                funds,
                abiSectors,
            });
        default:
            return state;
    }
};

export default adviserPortfolioAvailableFundsReducer;
