import { Map } from 'immutable';
import {
    CUSTOMER_MESSAGE_CATEGORIES_REQUESTED,
    CUSTOMER_MESSAGE_CATEGORIES_RESOLVED,
    CUSTOMER_MESSAGE_CATEGORIES_ERROR,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of our messages
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
};

const initialState = Map(schema);

/**
 *
 * The Customer Message Categories Reducer
 *
 * Responsible for managing the state and data for customer message categories
 *
 */
const customerMessageCategoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CUSTOMER_MESSAGE_CATEGORIES_REQUESTED:
            return state.set('loading', true);

        case CUSTOMER_MESSAGE_CATEGORIES_RESOLVED:
            state = state.set('status', STORE_STATUS_OK);
            state = state.set('categories', payload);
            state = state.set('loading', false);

            return state;

        case CUSTOMER_MESSAGE_CATEGORIES_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);
            state = state.set('categories', []);

            return state;

        case STORE_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default customerMessageCategoriesReducer;
