import { Map } from 'immutable';
import { POLICY_ADMINISTRATORS_REQUESTED, POLICY_ADMINISTRATORS_RESOLVED, POLICY_ADMINISTRATORS_ERROR } from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of policy projection reducer
 *
 */
const schema = {};

const initialState = Map(schema);
/**
 * Builds the model for each policy state
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePolicyAdministrators = (state, { type, payload }) => {
    let update;

    switch (type) {
        case POLICY_ADMINISTRATORS_REQUESTED:
            update = Object.assign(payload, { loading: true });
            break;
        case POLICY_ADMINISTRATORS_RESOLVED:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_OK });
            break;
        case POLICY_ADMINISTRATORS_ERROR:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_ERROR });
            break;
        default:
            update = payload;
    }

    const policyAdministrators = state.get(payload.policyId);

    return policyAdministrators ? Object.assign({}, policyAdministrators, update) : update;
};

/**
 *
 * The Policy Administrators Reducer
 *
 * Responsible for managing the state and data of customer policy administrators
 *
 */
const policyAdministratorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case POLICY_ADMINISTRATORS_REQUESTED:
            return state.set(action.payload.policyId, updatePolicyAdministrators(state, action));

        case POLICY_ADMINISTRATORS_RESOLVED:
            return state.set(action.payload.policyId, updatePolicyAdministrators(state, action));

        case POLICY_ADMINISTRATORS_ERROR:
            return state.set(action.payload.policyId, updatePolicyAdministrators(state, action));

        default:
            return state;
    }
};

export { initialState };

export default policyAdministratorsReducer;
