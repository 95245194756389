import { Map } from 'immutable';
import { PREMIUM_BREAKDOWN_REQUESTED, PREMIUM_BREAKDOWN_RESOLVED, PREMIUM_BREAKDOWN_ERROR } from './types';
import { STORE_RESET } from '../types';

/**
 *
 * Set the initial state of premium-breakdown types
 *
 */
const schema = {};

const initialState = Map(schema);

const updatePremiumBreakdown = (state, { payload }) => {
    const policyPremiumBreakdown = state.get(payload.policyId);

    const newState = policyPremiumBreakdown ? Object.assign({}, policyPremiumBreakdown, payload) : payload;

    return newState;
};

/**
 *
 * The Premium Breakdown Reducer
 *
 * Responsible for managing the state and data of policy premium breakdowns
 *
 */
const premiumBreakdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case PREMIUM_BREAKDOWN_REQUESTED:
            return state.set(action.payload.policyId, updatePremiumBreakdown(state, action));

        case PREMIUM_BREAKDOWN_RESOLVED:
            return state.set(action.payload.policyId, updatePremiumBreakdown(state, action));

        case PREMIUM_BREAKDOWN_ERROR:
            return state.set(action.payload.policyId, updatePremiumBreakdown(state, action));

        default:
            return state;
    }
};

export { initialState };

export default premiumBreakdownReducer;
