import { graphQLQuery } from '../../graphql/client';
import { adviserPortfoliosQuery } from '../../graphql/queries/advisers';
import { ERROR_ADVISER_PORTFOLIOS_NO_ACCESS, ERROR_SERVICE } from '../../services/errorCodes';
import { ADVISER_PORTFOLIOS_ERROR, ADVISER_PORTFOLIOS_REQUESTED, ADVISER_PORTFOLIOS_RESOLVED } from './types';

export const getAdviserPortfoliosAction = async dispatch => {
    dispatch({
        type: ADVISER_PORTFOLIOS_REQUESTED,
    });

    try {
        const payload = await graphQLQuery(adviserPortfoliosQuery);

        return dispatch({
            type: ADVISER_PORTFOLIOS_RESOLVED,
            payload,
        });
    } catch (error) {
        const errorMessage = Array.isArray(error) ? error[0].message : error.message;

        return dispatch({
            type: ADVISER_PORTFOLIOS_ERROR,
            payload: errorMessage === 'Not Authorised!' ? ERROR_ADVISER_PORTFOLIOS_NO_ACCESS : ERROR_SERVICE,
        });
    }
};
