import { Map } from 'immutable';
import { STORE_STATUS_EDIT, STORE_STATUS_ERROR, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    ADVISER_PORTFOLIO_DETAILS_ERROR,
    ADVISER_PORTFOLIO_DETAILS_REQUESTED,
    ADVISER_PORTFOLIO_DETAILS_RESOLVED,
    ADVISER_PORTFOLIO_DETAILS_EDIT,
    ADVISER_PORTFOLIO_DETAILS_CANCEL_EDIT,
    ADVISER_PORTFOLIO_SECTOR_FILTER,
} from './types';

export const initialState = Map({ abiSectorFilter: null });

export const setAdviserPortfolioDetails = (state, portfolioId, { abiSectors, funds, policies }) => {
    return state.set(portfolioId, { abiSectors, status: STORE_STATUS_OK, funds, policies });
};
const updatePortfolioDetails = (state, type, { portfolioId, funds, policies, abiSectors }) => {
    switch (type) {
        case ADVISER_PORTFOLIO_DETAILS_REQUESTED:
            return state.set(portfolioId, { status: STORE_STATUS_LOADING });
        case ADVISER_PORTFOLIO_DETAILS_EDIT:
            return state.setIn([portfolioId, 'status'], STORE_STATUS_EDIT);
        case ADVISER_PORTFOLIO_DETAILS_CANCEL_EDIT:
            return state.setIn([portfolioId, 'status'], STORE_STATUS_OK);
        case ADVISER_PORTFOLIO_DETAILS_ERROR:
            return state.set(portfolioId, { status: STORE_STATUS_ERROR });
        default:
            return setAdviserPortfolioDetails(state, portfolioId, { abiSectors, funds, policies });
    }
};

const adviserPortfolioDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case ADVISER_PORTFOLIO_DETAILS_REQUESTED:
        case ADVISER_PORTFOLIO_DETAILS_RESOLVED:
        case ADVISER_PORTFOLIO_DETAILS_ERROR:
        case ADVISER_PORTFOLIO_DETAILS_EDIT:
        case ADVISER_PORTFOLIO_DETAILS_CANCEL_EDIT:
            return updatePortfolioDetails(state, type, payload);
        case ADVISER_PORTFOLIO_SECTOR_FILTER:
            return state.set('abiSectorFilter', payload || null);
        default:
            return state;
    }
};

export default adviserPortfolioDetailsReducer;
