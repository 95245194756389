import { useCallback, useEffect, useState } from 'react';
import { getBreakpoint } from '../services/browser/browser';
import { useBreakpointChange } from '../services/browser/hooks';

export const useVirtualisedPolicyList = (policies, heightFunction) => {
    const [policiesList, setPoliciesList] = useState(null);
    const updatePoliciesList = useCallback(
        breakpoint => {
            if (policies) {
                setPoliciesList(
                    policies.map(policy => ({
                        ...policy,
                        height: heightFunction(policy, breakpoint),
                    }))
                );
            }
        },
        [policies, heightFunction]
    );

    useBreakpointChange(breakpoint => {
        updatePoliciesList(breakpoint);
    });

    useEffect(() => {
        updatePoliciesList(getBreakpoint());
    }, [policies, updatePoliciesList]);

    return policiesList || [];
};
