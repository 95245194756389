import { Map } from 'immutable';

import {
    ACTIVATE_FACTOR_REQUESTED,
    ACTIVATE_FACTOR_RESOLVED,
    ACTIVATE_FACTOR_ERROR,
    VERIFY_FACTOR_REQUESTED,
    VERIFY_FACTOR_RESOLVED,
    VERIFY_FACTOR_ERROR,
    RESEND_FACTOR_REQUESTED,
    RESEND_FACTOR_RESOLVED,
    RESEND_FACTOR_ERROR,
} from './types';

import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT } from '../statuses';

/**
 *
 * Set the initial state of our factors reducer
 *
 */
const initialState = Map({
    status: STORE_STATUS_INIT,
    loading: false,
    resend: 'INIT',
});

/**
 *
 * The factors Reducer
 *
 * A reducer which controls the state of factors
 *
 */
const factorsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case RESEND_FACTOR_REQUESTED:
            state = state.set('resend', 'REQUESTED');
            return state;

        case RESEND_FACTOR_RESOLVED:
            state = state.set('resend', payload.resend);
            state = state.set('loading', false);

            return state;

        case RESEND_FACTOR_ERROR:
            state = state.set('resend', STORE_STATUS_ERROR);
            state = state.set('loading', false);

            return state;

        case ACTIVATE_FACTOR_REQUESTED:
        case VERIFY_FACTOR_REQUESTED:
            state = state.set('loading', true);
            return state;

        case ACTIVATE_FACTOR_RESOLVED:
        case VERIFY_FACTOR_RESOLVED:
            state = state.set('status', payload.status);
            state = state.set('loading', false);

            return state;

        case ACTIVATE_FACTOR_ERROR:
        case VERIFY_FACTOR_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);

            return state;

        default:
            return state;
    }
};

export { initialState };

export default factorsReducer;
