/**
 * The ReAssure Now theme provided by Reassure UI
 */
import { pallette } from '@reassure/ui/core';
export const THEME_NAME_STANDARD = 'STANDARD';
export const THEME_NAME_LIGHT = 'LIGHT';
export const THEME_NAME_DARK = 'DARK';

const { midGreen, lightBlue, darkBlue, transparent } = pallette;

const theme = {
    name: 'standard',
    colors: {
        interaction: midGreen,
        button: {
            primary: {
                disabled: {
                    text: darkBlue,
                    background: lightBlue,
                    border: lightBlue,
                },
            },
            secondary: {
                disabled: {
                    text: darkBlue,
                    background: transparent,
                    border: lightBlue,
                },
            },
        },
    },
};

export default theme;
