import { Map } from 'immutable';
import { transformHashMapToArray } from '../helpers';
import { STORE_RESET } from '../types';
import { createPortfolioPolicyProfile, transformPortfolioPolicy } from './helpers';
import { PORTFOLIO_POLICY_ACTION_PRESERVE, PORTFOLIO_POLICY_ACTION_UNLINK } from './link-actions';
import {
    PORTFOLIO_POLICY_PROFILE_LINK_POLICY,
    PORTFOLIO_POLICY_PROFILE_CREATE,
    PORTFOLIO_POLICY_PROFILE_DELETE,
    PORTFOLIO_POLICY_PROFILE_UNLINK_POLICY,
    PORTFOLIO_POLICY_PROFILE_LINK_ALL_POLICIES,
    PORTFOLIO_POLICY_PROFILE_UNLINK_ALL_POLICIES,
} from './types';

export const initialState = Map({});

export const linkPolicy = (state, { portfolioId, policy }) => {
    const {
        details: { policyId },
    } = policy;
    const existingPolicy = state.get(portfolioId)[policyId];
    if (!existingPolicy) {
        return state.setIn([portfolioId, policyId], transformPortfolioPolicy(policy));
    }
    return state.setIn([portfolioId, policyId, 'action'], PORTFOLIO_POLICY_ACTION_PRESERVE);
};

export const unlinkPolicy = (state, { portfolioId, policyId }) => {
    const existingPolicy = state.get(portfolioId)[policyId];
    if (!existingPolicy) return;
    const { action } = existingPolicy;
    if (action === PORTFOLIO_POLICY_ACTION_PRESERVE) {
        return state.setIn([portfolioId, policyId, 'action'], PORTFOLIO_POLICY_ACTION_UNLINK);
    }
    return state.deleteIn([portfolioId, policyId]) || initialState;
};

export const linkAllPolicies = (state, { portfolioId, policies }) => {
    for (const policy of policies) {
        if (!policy.canLink) continue;
        state = linkPolicy(state, { portfolioId, policy });
    }
    return state;
};

export const unlinkAllPolicies = (state, { portfolioId }) => {
    const policies = transformHashMapToArray(state.get(portfolioId));
    for (const {
        details: { policyId },
    } of policies) {
        state = unlinkPolicy(state, { portfolioId, policyId });
    }
    return state;
};

const portfolioPolicyProfileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PORTFOLIO_POLICY_PROFILE_CREATE:
            return state.set(payload.portfolioId, createPortfolioPolicyProfile(payload.policies));
        case PORTFOLIO_POLICY_PROFILE_DELETE:
            return state.delete(payload.portfolioId);
        case PORTFOLIO_POLICY_PROFILE_LINK_POLICY:
            return linkPolicy(state, payload);
        case PORTFOLIO_POLICY_PROFILE_UNLINK_POLICY:
            return unlinkPolicy(state, payload);
        case PORTFOLIO_POLICY_PROFILE_LINK_ALL_POLICIES:
            return linkAllPolicies(state, payload);
        case PORTFOLIO_POLICY_PROFILE_UNLINK_ALL_POLICIES:
            return unlinkAllPolicies(state, payload);
        case STORE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default portfolioPolicyProfileReducer;
