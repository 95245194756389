import getContent from './content/getContent';
import { ADVISER } from './role-types';
import { getWindowWidth, getBreakpoint, calculateElementDimensions } from './browser/browser';

export const FUND_SWITCH_STATUS_ALLOWED = 'ALLOWED';
export const FUND_SWITCH_STATUS_NOT_ALLOWED = 'NOT_ALLOWED';
export const FUND_SWITCH_STATUS_NO_AUTHORITY = 'NO_AUTHORITY';
export const FUND_SWITCH_STATUS_PENDING = 'PENDING';
export const FUND_SWITCH_FUND_TYPE_IN = 'IN';
export const FUND_SWITCH_FUND_TYPE_OUT = 'OUT';

export const getFundSwitchStatusContent = (featureEnabled, status, roleCategory) => {
    if (!featureEnabled) return getContent(['fund-switch', 'status', FUND_SWITCH_STATUS_NOT_ALLOWED]);

    switch (status) {
        case FUND_SWITCH_STATUS_NO_AUTHORITY:
            return roleCategory === ADVISER
                ? getContent(['fund-switch', 'status', status, roleCategory])
                : getContent(['fund-switch', 'status', FUND_SWITCH_STATUS_NOT_ALLOWED]);
        default:
            return getContent(['fund-switch', 'status', status]);
    }
};

export const getPolicyFundSwitchDirection = (state, policyId, direction) => {
    if (!state.policyFundSwitch.get(policyId) || !state.policyFundSwitch.get(policyId)[direction]) return null;
    return state.policyFundSwitch.get(policyId)[direction];
};

export const getPolicyFundSwitchType = fund =>
    fund.hasOwnProperty('abiSectorName') ? FUND_SWITCH_FUND_TYPE_IN : FUND_SWITCH_FUND_TYPE_OUT;

export const getPolicyFundCardMeasurementDiv = () => {
    const id = 'policy-fund-card-measurement-div';
    let div = document.getElementById(id);
    if (!div) {
        try {
            div = document.createElement('div');
            div.id = id;
            div.style.fontFamily = '"Open sans"';
            // hide the element off the screen
            div.style.position = 'fixed';
            div.style.left = '-10000px';
            // test id for unit tests
            div.dataset.testid = id;
            // insert the div
            const root = document.getElementById('root');
            document.body.insertBefore(div, root);
        } catch (error) {
            console.error(error);
        }
    }
    return div;
};

export const getPolicyFundTypeOutDimensions = breakpoint => {
    switch (breakpoint) {
        case 'xl':
            return {
                areaWidths: ['993px'],
                offsetHeight: 124,
            };
        case 'lg':
            return {
                areaWidths: ['687px'],
                offsetHeight: 124,
            };
        case 'md':
            return {
                areaWidths: ['519px'],
                offsetHeight: 124,
            };
        default:
            const windowWidth = getWindowWidth();
            const innerAreaBase = windowWidth - 66;
            return {
                areaWidths: [`${innerAreaBase}px`],
                offsetHeight: 179,
            };
    }
};

export const getPolicyFundTypeInDimensions = breakpoint => {
    switch (breakpoint) {
        case 'xl':
            return {
                areaWidths: ['348px', '348px'],
                offsetHeight: 62,
            };
        case 'lg':
            return {
                areaWidths: ['235px', '102px'],
                offsetHeight: 66,
            };
        case 'md':
            return {
                areaWidths: ['519px', '107px'],
                offsetHeight: 102,
            };
        default:
            const windowWidth = getWindowWidth();
            const innerAreaBase = windowWidth - 66;
            return {
                areaWidths: [`${innerAreaBase}px`, `${innerAreaBase / 2}px`],
                offsetHeight: 286,
            };
    }
};

export const getPolicyFundTypeDimensions = (type, breakpoint) => {
    switch (type) {
        case FUND_SWITCH_FUND_TYPE_IN:
            return getPolicyFundTypeInDimensions(breakpoint);
        default:
            return getPolicyFundTypeOutDimensions(breakpoint);
    }
};

export const getPolicyFundCardFontSize = breakpoint => {
    switch (breakpoint) {
        case 'md':
        case 'lg':
        case 'xl':
            return '16px';
        default:
            return '14px';
    }
};

export const calculatePolicyFundSwitchOutCardHeight = (heights, offsetHeight) => {
    return heights[0] + offsetHeight;
};

export const calculatePolicyFundSwitchInCardHeight = (breakpoint, heights, offsetHeight) => {
    switch (breakpoint) {
        case 'lg':
        case 'xl':
            return Math.max(heights[0], heights[1], 148 - offsetHeight) + offsetHeight;
        default:
            return heights[0] + heights[1] + offsetHeight;
    }
};

export const getPolicyFundSwitchCardHeight = fund => {
    const breakpoint = getBreakpoint();
    const type = getPolicyFundSwitchType(fund);
    let dimensions;
    switch (type) {
        case FUND_SWITCH_FUND_TYPE_IN:
            dimensions = getPolicyFundTypeInDimensions(breakpoint);
            break;
        default:
            dimensions = getPolicyFundTypeOutDimensions(breakpoint);
    }

    const { areaWidths, offsetHeight } = dimensions;

    const fontSize = getPolicyFundCardFontSize(breakpoint);

    const heights = [];

    areaWidths.forEach((width, index) => {
        const content = index === 0 ? fund.name : fund.abiSectorName;
        const styles = {
            fontSize,
            width,
            lineHeight: index === 0 || type === FUND_SWITCH_FUND_TYPE_OUT ? '1.3' : '1.4',
            fontWeight: index === 0 || type === FUND_SWITCH_FUND_TYPE_OUT ? '700' : '400',
        };
        const { height } = calculateElementDimensions(content, styles);
        heights.push(height);
    });

    switch (type) {
        case FUND_SWITCH_FUND_TYPE_IN:
            return calculatePolicyFundSwitchInCardHeight(breakpoint, heights, offsetHeight);
        default:
            return calculatePolicyFundSwitchOutCardHeight(heights, offsetHeight);
    }
};

export const policyFundSwitchSelectedFundLimitReached = (state, policyId, direction) => {
    const policyFundSwitch = getPolicyFundSwitchDirection(state, policyId, direction);
    if (!policyFundSwitch || !policyFundSwitch.limitReached) return false;
    return !!policyFundSwitch.limitReached;
};

export const getPolicyFundSwitchRedirectFunds = (state, policyId, direction) => {
    const policyFundSwitch = getPolicyFundSwitchDirection(state, policyId, direction);
    if (!policyFundSwitch || !policyFundSwitch.redirectPremiums) return [];
    return policyFundSwitch.redirectPremiums;
};

export const isFundSwitchOutAllocationValid = selectedFunds => {
    if (!Array.isArray(selectedFunds)) return false;
    if (selectedFunds.length === 0) return false;
    for (const { allocation } of selectedFunds) {
        if (allocation < 0.01 || allocation > 100) {
            return false;
        }
    }
    return true;
};

export const isFundSwitchInAllocationValid = selectedFunds => {
    // note: as we know the allocations can have a maximum of 2 decimal places we will convert
    // all numbers to integers before adding them to avoid JavaScript floating point rounding errors
    // e.g. in JavaScript 0.1 + 0.2 = 0.30000000000000004
    if (!Array.isArray(selectedFunds) || selectedFunds.length === 0) return false;
    const roundingCoefficient = 100;
    let total = 0;
    for (const { allocation } of selectedFunds) {
        if (allocation < 0.01 || allocation > 100) {
            return false;
        }
        total += parseFloat(allocation) * roundingCoefficient;
    }
    return total === 100 * roundingCoefficient;
};

export const getFundSwitchInAllocationRemaining = selectedFunds => {
    // note: as we know the allocations can have a maximum of 2 decimal places we will convert
    // all numbers to integers before adding them to avoid JavaScript floating point rounding errors
    // e.g. in JavaScript 0.1 + 0.2 = 0.30000000000000004
    const roundingCoefficient = 100;
    let total = 0;
    for (const { allocation } of selectedFunds) {
        total += parseFloat(allocation) * roundingCoefficient;
    }
    total = total / 100;
    return parseFloat((100 - total).toFixed(2));
};

export const selectedFundsCanRedirectPremiums = selectedFunds => {
    for (const { units } of selectedFunds) {
        if (units.filter(unit => unit.premiumPaying === true).length > 0) {
            return true;
        }
    }
    return false;
};
