/**
 * Styled-components theme for Reassure-UI data components
 */

import pallette from './pallette';
const {
    brightBlue,
} = pallette;


const themeConfig = {
    colors: {
        valueBar: {
            bar: brightBlue,
        }
    }
}

export default themeConfig;