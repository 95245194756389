import { createSelector } from 'reselect';
import { ERROR_ADVISER_PORTFOLIOS_NO_ACCESS, ERROR_SERVICE } from '../../services/errorCodes';
import { sortPortfolios } from '../../services/portfolios';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING } from '../statuses';
import { filterPortfolios } from './helpers';

const adviserPortfoliosStateSelector = state => state.adviserPortfolios;
const filtersSelector = (_, filters) => filters;
const statusSelector = state => state.get('status');
const portfoliosSelector = state => state.get('portfolios');

export const selectAdviserPortfolios = createSelector(
    [adviserPortfoliosStateSelector, filtersSelector],
    (state, filters) => {
        const status = statusSelector(state);
        const portfolios = filterPortfolios(portfoliosSelector(state), filters);
        return {
            status,
            loading: status === STORE_STATUS_LOADING || status === STORE_STATUS_INIT,
            error: status === ERROR_SERVICE || status === STORE_STATUS_ERROR,
            noAccess: status === ERROR_ADVISER_PORTFOLIOS_NO_ACCESS,
            portfolios: sortPortfolios(portfolios),
        };
    }
);
