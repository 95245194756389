import { Map } from 'immutable';
import {
    CHALLENGE_ACCOUNT_REQUESTED,
    CHALLENGE_ACCOUNT_RESOLVED,
    CHALLENGE_ACCOUNT_ERROR,
    VERIFY_CHALLENGE_ACCOUNT_REQUESTED,
    VERIFY_CHALLENGE_ACCOUNT_RESOLVED,
    VERIFY_CHALLENGE_ACCOUNT_ERROR,
    CHALLENGE_ACCOUNT_POLICYID_RESOLVED,
    CHALLENGE_ACCOUNT_IDENTITY_PASSED,
    CREATE_CHALLENGE_ACCOUNT_SECURITY_REQUESTED,
    CREATE_CHALLENGE_ACCOUNT_SECURITY_RESOLVED,
    CREATE_CHALLENGE_ACCOUNT_SECURITY_ERROR,
} from './types';
import { CHALLENGE_ACCOUNT_ACTION_UNKNOWN } from '../../services/challengeAccount';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    action: null,
    username: null,
    emailPasscodeSent: null,
    emailVerified: null,
    verifiedPasscode: null,
    invalidAttempts: 0,
    policyId: null,
    identityVerified: null,
    activated: false,
};

const initialState = Map(schema);

const onChallengeResolved = (state, { username, action, emailPasscodeSent }) => {
    state = state.set('status', STORE_STATUS_OK);
    state = state.set('emailVerified', null);
    state = state.set('verifiedPasscode', null);
    state = state.set('action', action || CHALLENGE_ACCOUNT_ACTION_UNKNOWN);
    state = state.set('username', username);
    state = !action ? state.set('invalidAttempts', state.get('invalidAttempts') + 1) : state.set('invalidAttempts', 0);
    return state.set('emailPasscodeSent', !!emailPasscodeSent);
};

const onVerifyChallengeResolved = (state, { username, emailVerified, code }) => {
    state = state.set('status', STORE_STATUS_OK);
    state = state.set('emailVerified', !!emailVerified);
    state = state.set('verifiedPasscode', !!emailVerified ? code : null);
    return state.set('username', username);
};

const onChallengeAccountPolicyId = (state, payload) => {
    state = state.set('status', STORE_STATUS_OK);
    return state.set('policyId', payload);
};

const onChallengeAccountIdentityPassed = state => {
    state = state.set('status', STORE_STATUS_OK);
    return state.set('identityVerified', true);
};

const onCreateChallengeAccountSecurity = state => {
    state = state.set('status', STORE_STATUS_OK);
    return state.set('activated', true);
};

/**
 *
 * The Upgrade Reducer
 *
 * Responsible for managing the state when upgrading migrated users
 *
 */
const challengeAccountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHALLENGE_ACCOUNT_REQUESTED:
        case CREATE_CHALLENGE_ACCOUNT_SECURITY_REQUESTED:
        case VERIFY_CHALLENGE_ACCOUNT_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);

        case CHALLENGE_ACCOUNT_RESOLVED:
            return onChallengeResolved(state, payload);

        case CREATE_CHALLENGE_ACCOUNT_SECURITY_ERROR:
        case VERIFY_CHALLENGE_ACCOUNT_ERROR:
        case CHALLENGE_ACCOUNT_ERROR:
            return state.set('status', STORE_STATUS_ERROR);

        case VERIFY_CHALLENGE_ACCOUNT_RESOLVED:
            return onVerifyChallengeResolved(state, payload);

        case CHALLENGE_ACCOUNT_POLICYID_RESOLVED:
            return onChallengeAccountPolicyId(state, payload);

        case CHALLENGE_ACCOUNT_IDENTITY_PASSED:
            return onChallengeAccountIdentityPassed(state, payload);

        case CREATE_CHALLENGE_ACCOUNT_SECURITY_RESOLVED:
            return onCreateChallengeAccountSecurity(state, payload);

        default:
            return state;
    }
};

export { initialState };

export default challengeAccountReducer;
