import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { getBreakpoint } from './browser';

export const useBreakpointChange = callback => {
    const [currentBreakpoint, setCurrentBreakpoint] = useState(null);
    useEffect(() => {
        if (!currentBreakpoint) {
            const initialBreakpoint = getBreakpoint();
            setCurrentBreakpoint(initialBreakpoint);
            callback(initialBreakpoint);
        }

        const handler = throttle(() => {
            const breakpoint = getBreakpoint();

            if (breakpoint !== currentBreakpoint) {
                setCurrentBreakpoint(breakpoint);
                callback(breakpoint);
            }
        }, 250);

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [callback, currentBreakpoint]);
};
