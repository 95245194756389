import moment from 'moment';
import { ADVISER, ADVISER_COMMISSION_AGENT, ADVISER_LEAD, RETAIL, SCHEME } from './role-types';

const VERIFY_EMAIL_ROUTE = '/activate/validate-email';
const CHALLENGE_IDENTITY_ROUTE = '/activate/security-questions';
const TERMS_AND_CONDITIONS_ROUTE = '/activate/terms-and-conditions';
const ACCOUNT_SECURITY_ROUTE = '/activate/account-security';
const NOTIFICATION_PREFERENCES_ROUTE = '/activate/notification-preferences';
const COMMISSION_AGENT_ROUTE = '/adviser-documents';
export const ADVISER_ROUTE = '/clients';
export const RETAIL_ROUTE = '/policies';
export const SCHEME_ROUTE = '/schemes';

export const statusNotActive = ({ status }) => status !== 'ACTIVE';

export const userIsActivated = props => {
    const { emailVerified, notificationPreferencesSet, validated, recoveryQuestion } = props;
    return (
        !userMustAcceptTermsAndConditions(props) &&
        !!emailVerified &&
        !!notificationPreferencesSet &&
        !!validated &&
        !!recoveryQuestion
    );
};

export const notSet = (props, property) => !props[property];

export const userMustAcceptTermsAndConditions = props => {
    const { termsAndConditionsAccepted, acceptedTermsAndConditions, category, config } = props;
    if (!termsAndConditionsAccepted || !acceptedTermsAndConditions) return true;
    const { id, version, date } = acceptedTermsAndConditions;
    if (id !== config[`${category}_TERMS_DOCUMENT_ID`]) return true;
    if (version !== config[`${category}_TERMS_VERSION_ID`]) return true;
    if (moment(date).isBefore(config[`${category}_TERMS_PUBLISH_DATE`], 'minute')) return true;
    return false;
};
/**
 * Test if the route state dictates we redirect so they can verify their email
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectToVerifyEmail = ({ pathname }) => {
    return pathname !== VERIFY_EMAIL_ROUTE;
};

/**
 * Test if the route state dictates we redirect so they can set account security
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectToTermsAndConditions = ({ pathname }) => {
    return pathname !== TERMS_AND_CONDITIONS_ROUTE;
};

/**
 * Test if the route state dictates we redirect so they can set account security
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectToAccountSecurity = ({ pathname }) => {
    return pathname !== ACCOUNT_SECURITY_ROUTE;
};

/**
 * Test if the route state dictates we redirect so they can answer security questions
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectToSecurityQuestions = ({ pathname }) => {
    return pathname !== CHALLENGE_IDENTITY_ROUTE;
};

/**
 * Test if the route state dictates we redirect so they can complete their notification prefs
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectToNotificationPreferences = ({ pathname }) => {
    return pathname !== NOTIFICATION_PREFERENCES_ROUTE;
};

/**
 * Test if the pathname is an unrestricted logged in pathname available to all logged in users
 * @param {Object} props
 * @return {Boolean}
 */
export const pathnameIsLoggedInUserRoute = ({ pathname }) => {
    return pathname.indexOf('/profile') === 0 || pathname.indexOf('/terms') === 0;
};

/**
 * If the user is a commission agent then test if the pathname needs redirecting
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectCommissionAgent = ({ pathname }) => {
    return pathname.indexOf(COMMISSION_AGENT_ROUTE) !== 0;
};

/**
 * Test whether the pathname is ok for general users
 * @param {Object} props
 * @return {Boolean}
 */
export const pathnameIsStandardUserRoute = ({ pathname }) => {
    return (
        pathname.indexOf('/messages') === 0 ||
        pathname.indexOf('/documents') === 0 ||
        pathname.indexOf('/help') === 0 ||
        pathname.indexOf('/tools') === 0
    );
};

/**
 * Test if the pathname is an acceptable adviser pathname
 * @param {Object} props
 * @return {Boolean}
 */
export const pathnameIsAdviserUserRoute = ({ pathname, role }) =>
    pathname.indexOf(ADVISER_ROUTE) === 0 ||
    pathname.indexOf('/manage') === 0 ||
    (role === ADVISER_LEAD && pathname.indexOf('/users') === 0) ||
    pathname.indexOf('/policies/') === 0;

/**
 * If the user is an adviser then test if the pathname needs redirecting
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectAdviser = ({ pathname, role }) =>
    !pathnameIsAdviserUserRoute({ pathname, role }) || pathname === '/policies' || pathname.indexOf('/activate') === 0;

/**
 * If the user is a retail user then test if the pathname needs redirecting
 * @param {Object} props
 * @return {Boolean}
 */
export const redirectRetail = ({ pathname }) =>
    pathname.indexOf('/activate') === 0 || pathname.indexOf(RETAIL_ROUTE) !== 0;

export const redirectScheme = ({ pathname }) =>
    pathname.indexOf('/activate') === 0 || pathname.indexOf(SCHEME_ROUTE) !== 0;

/**
 * Test whether the user has a role
 * @param {String} role
 * @param {Object} props
 */
export const hasRole = (role, props) => props.role && props.role === role;

/**
 * Test whether the user has a category
 * @param {String} category
 * @param {Object} props
 */
export const hasCategory = (category, props) => props.category && props.category === category;

/**
 * Get's a secure pathname if the user requires fourther activation
 * @param {Object} props
 * @return {Mixed} The pathname to redirect to or false
 */
export const getSecureRedirect = props => {
    if (statusNotActive(props)) return false;

    if (userIsActivated(props)) {
        if (pathnameIsLoggedInUserRoute(props)) return false;

        // Conditional checks for Commission Agents
        if (hasRole(ADVISER_COMMISSION_AGENT, props)) {
            if (redirectCommissionAgent(props)) return COMMISSION_AGENT_ROUTE;
            return false;
        }

        // Check if the pathname is a standard user pathname
        if (pathnameIsStandardUserRoute(props)) return false;

        // Conditional checks for Advisers
        if (hasCategory(ADVISER, props)) {
            if (redirectAdviser(props)) return ADVISER_ROUTE;
            return false;
        }

        // Conditional checks for Retail customers
        if (hasCategory(RETAIL, props)) {
            if (redirectRetail(props)) return RETAIL_ROUTE;
            return false;
        }

        // Conditional checks for Scheme users
        if (hasCategory(SCHEME, props)) {
            if (redirectScheme(props)) return SCHEME_ROUTE;
            return false;
        }

        return false;
    }

    // Check if the user has accepted their terms and conditions
    if (userMustAcceptTermsAndConditions(props)) {
        if (redirectToTermsAndConditions(props)) return TERMS_AND_CONDITIONS_ROUTE;
        return false;
    }

    // Check if emailVerified is set and handle pathname appropriately
    if (notSet(props, 'emailVerified')) {
        if (redirectToVerifyEmail(props)) return VERIFY_EMAIL_ROUTE;
        return false;
    }

    // Check if the user has a not set a recovery question
    if (notSet(props, 'recoveryQuestion')) {
        if (redirectToAccountSecurity(props)) return ACCOUNT_SECURITY_ROUTE;
        return false;
    }

    // Check if validated is set and handle pathname appropriately
    if (notSet(props, 'validated')) {
        if (redirectToSecurityQuestions(props)) return CHALLENGE_IDENTITY_ROUTE;
        return false;
    }

    // Check if notificationPreferencesSet is set and handle pathname appropriately
    if (notSet(props, 'notificationPreferencesSet')) {
        if (redirectToNotificationPreferences(props)) return NOTIFICATION_PREFERENCES_ROUTE;
        return false;
    }

    return false;
};
