import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { getAppConfigProperty } from '../../store/app/helpers';
import { useApp } from '../../store/app/hooks';
import { CONFIG_GTM_ACCOUNT } from '../config';

export const useGoogleTagManager = () => {
    const [initialised, setInitialised] = useState(false);
    const { config } = useApp();
    const gtmAccount = getAppConfigProperty(config, CONFIG_GTM_ACCOUNT);
    const [cookieProLoaded, setCookieProLoaded] = useState(false);

    const handleCookieProLoaded = useCallback(() => {
        setCookieProLoaded(true);
    }, []);

    useEffect(() => {
        window.addEventListener('rcp.cookiePro.loaded', handleCookieProLoaded);
        return () => {
            window.removeEventListener('rcp.cookiePro.loaded', handleCookieProLoaded);
        };
    }, [handleCookieProLoaded]);

    useEffect(() => {
        if (initialised || !cookieProLoaded) return;
        if (!initialised && gtmAccount && cookieProLoaded) {
            TagManager.initialize({
                gtmId: gtmAccount,
            });
            console.info(`Google Tag Manager initialised using account ${gtmAccount}`);
            setInitialised(true);
        }
    }, [initialised, gtmAccount, cookieProLoaded]);
};
