import { APPEARANCE_SET_MODE } from './types';
import { setVisualPreferenceMode, getVisualPreferenceMode } from '../../services/localStorage';
import { THEME_NAME_STANDARD } from '../../theme';

/**
 * Sets the appearance mode in the store. If no parameter is passed then either the localStorage value or the default "standard" mode is set.
 *
 * @param {*} mode
 * @returns
 */
const setAppearanceMode = mode => {
    const localVisualPreferenceMode = getVisualPreferenceMode();

    // If we don't get a mode as a parameter then get it from local storage
    if (!mode) {
        mode = localVisualPreferenceMode;
    }

    // If there is still no mode set then set it to default
    if (!mode) {
        mode = THEME_NAME_STANDARD;
    }

    // If the mode does not equal the local storage value then set the local storage
    if (mode !== localVisualPreferenceMode) {
        setVisualPreferenceMode(mode);
    }

    return {
        type: APPEARANCE_SET_MODE,
        payload: mode,
    };
};

export default setAppearanceMode;
