import { gql } from '@apollo/client';

export const profileDetailsQuery = gql`
    query ProfileDetails($input: IdInput) {
        profileDetails(input: $input) {
            title
            firstName
            lastName
            dateOfBirth
            nationalInsuranceNumber
            telephoneNumber {
                value
                status
                editable
            }
            mobileNumber {
                value
                status
                editable
            }
            emailAddress {
                value
                status
                editable
            }
            address {
                value {
                    address
                    postCode
                }
                status
                editable
            }
        }
    }
`;

export const bragCountriesQuery = gql`
    query BRAGCountries {
        bragCountries {
            name
            code
            rating
        }
    }
`;
