import axios from 'axios';
import httpRequestService from './request';

const getServerStatusRequest = async () => {
    const options = {
        url: httpRequestService.endpoints.status,
        method: 'GET',
    };

    try {
        const response = await axios(options);
        return response.data;
    } catch (error) {
        console.error('Could not get the server status', error);
    }
};

export default getServerStatusRequest;
