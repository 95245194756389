import { graphQLQuery } from '../../graphql/client';
import { adviserPortfolioDetailsQuery } from '../../graphql/queries/advisers';
import { setFundAllocationsToSector, transformFundsToUniqueAbiSectors } from '../helpers';
import {
    ADVISER_PORTFOLIO_DETAILS_ERROR,
    ADVISER_PORTFOLIO_DETAILS_REQUESTED,
    ADVISER_PORTFOLIO_DETAILS_RESOLVED,
} from './types';

export const getAdviserPortfolioDetailsAction = async portfolioId => async dispatch => {
    dispatch({
        type: ADVISER_PORTFOLIO_DETAILS_REQUESTED,
        payload: {
            portfolioId,
        },
    });

    try {
        const { funds, policies } = await graphQLQuery(adviserPortfolioDetailsQuery, { id: portfolioId });
        dispatch(getAdviserPortfolioDetailsResolvedAction(portfolioId, funds, policies));
    } catch (error) {
        return dispatch({
            type: ADVISER_PORTFOLIO_DETAILS_ERROR,
            payload: {
                portfolioId,
            },
        });
    }
};

export const getAdviserPortfolioDetailsResolvedAction = (portfolioId, funds, policies) => {
    const uniqueAbiSectors = transformFundsToUniqueAbiSectors(funds);
    const abiSectors = setFundAllocationsToSector(uniqueAbiSectors, funds);
    return {
        type: ADVISER_PORTFOLIO_DETAILS_RESOLVED,
        payload: {
            portfolioId,
            funds,
            policies,
            abiSectors,
        },
    };
};
