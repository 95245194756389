import { Map } from 'immutable';
import { STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    SET_STORE_STATUS_INIT,
    SINGLE_PREMIUM_PAYMENT_ERROR,
    SINGLE_PREMIUM_PAYMENT_REQUESTED,
    SINGLE_PREMIUM_PAYMENT_RESOLVED,
} from './types';

const schema = {
    status: STORE_STATUS_INIT,
    type: null,
    firstName: null,
    lastName: null,
    policyReference: null,
    nationalInsuranceNumber: null,
    amount: null,
    breakdown: null,
    policyId: null,
    knowYourCustomerRequired: null,
    publicAccessToken: null,
    reference: null,
    bankAccount: null,
};

const initialState = Map(schema);

const singlePremiumPaymentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case SET_STORE_STATUS_INIT:
            state = state.set('policyId', null);
            state = state.set('status', STORE_STATUS_INIT);
            return state;

        case SINGLE_PREMIUM_PAYMENT_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);

        case SINGLE_PREMIUM_PAYMENT_RESOLVED:
            for (const key of Object.keys(payload)) {
                state = state.set(key, payload[key]);
            }

            return state.set('status', STORE_STATUS_OK);

        case SINGLE_PREMIUM_PAYMENT_ERROR:
            return state.set('status', payload);

        default:
            return state;
    }
};

export { initialState, schema };

export default singlePremiumPaymentReducer;
