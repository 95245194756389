import { gql } from '@apollo/client';

export const onNotificationSubscription = gql`
    subscription OnNotification {
        onNotification {
            level
            topic
        }
    }
`;
