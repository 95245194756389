import moment from 'moment-timezone';

export const isInActiveMaintenanceWindow = maintenanceWindow => {
    if (!maintenanceWindow.from) return false;
    const now = moment().tz('Europe/London');
    const windowStart = moment(maintenanceWindow.from).tz('Europe/London');
    const windowEnd = maintenanceWindow.to ? moment(maintenanceWindow.to).tz('Europe/London') : false;
    return windowEnd ? windowStart.isBefore(now) && windowEnd.isAfter(now) : windowStart.isBefore(now);
};

export const getAppConfigProperty = (properties, key) => (properties && properties[key]) || null;
