import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    ADVISER_BACK_OFFICE_INTEGRATIONS_ERROR,
    ADVISER_BACK_OFFICE_INTEGRATIONS_REQUESTED,
    ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED,
    ADVISER_BACK_OFFICE_INTEGRATIONS_UPDATE_INTEGRATION_STATUS,
} from './types';

const schema = {
    status: STORE_STATUS_INIT,
    integrations: [],
};

export const initialState = Map(schema);

const adviserBackOfficeIntegrationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case ADVISER_BACK_OFFICE_INTEGRATIONS_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);
        case ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('integrations', payload);
        case ADVISER_BACK_OFFICE_INTEGRATIONS_ERROR:
            return initialState.set('status', STORE_STATUS_ERROR);
        case ADVISER_BACK_OFFICE_INTEGRATIONS_UPDATE_INTEGRATION_STATUS:
            const integrations = state.get('integrations');
            return state.set(
                'integrations',
                integrations.map(integration => {
                    const { id, status } = payload;
                    if (integration.id === id) {
                        return Object.assign({}, integration, { status });
                    }
                    return integration;
                })
            );
        default:
            return state;
    }
};

export default adviserBackOfficeIntegrationsReducer;
