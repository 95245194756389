import { useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateAdviserBackOfficeIntegrationStatus } from '../../graphql/subscriptions/advisers';
import { ADVISER } from '../../services/role-types';
import { useFeatureFlags } from '../app/hooks';
import { STORE_STATUS_INIT } from '../statuses';
import { useUser } from '../user/hooks';
import { getAdviserBackOfficeIntegrationsAction } from './actions';
import { selectAdviserBackOfficeIntegrations } from './selectors';
import { ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED } from './types';

export const useAdviserBackOfficeIntegrations = () => {
    const dispatch = useDispatch();
    const { status, integrations, loading, error } = useSelector(selectAdviserBackOfficeIntegrations);
    const getAdviserBackOfficeIntegrations = useCallback(async () => {
        await dispatch(getAdviserBackOfficeIntegrationsAction);
    }, [dispatch]);

    useEffect(() => {
        if (status === STORE_STATUS_INIT) {
            getAdviserBackOfficeIntegrations();
        }
    }, [getAdviserBackOfficeIntegrations, status]);
    return { status, integrations, loading, error };
};

export const useOnUpdateAdviserBackOfficeIntegrationStatusSubscription = () => {
    const dispatch = useDispatch();
    const { adviserBackOfficeIntegrationEnabled } = useFeatureFlags();
    const { category } = useUser();
    useSubscription(onUpdateAdviserBackOfficeIntegrationStatus, {
        skip: !adviserBackOfficeIntegrationEnabled || category !== ADVISER,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            if (data && data.onUpdateAdviserBackOfficeIntegrationStatus) {
                dispatch({
                    type: ADVISER_BACK_OFFICE_INTEGRATIONS_RESOLVED,
                    payload: data.onUpdateAdviserBackOfficeIntegrationStatus,
                });
            }
        },
    });
};
