import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    ADVISER_PORTFOLIO_AVAILABLE_POLICIES_ERROR,
    ADVISER_PORTFOLIO_AVAILABLE_POLICIES_REQUESTED,
    ADVISER_PORTFOLIO_AVAILABLE_POLICIES_RESOLVED,
} from './types';

export const initialState = Map({});

const adviserPortfolioAvailablePoliciesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case ADVISER_PORTFOLIO_AVAILABLE_POLICIES_REQUESTED:
            return state.set(payload, {
                status: STORE_STATUS_LOADING,
            });
        case ADVISER_PORTFOLIO_AVAILABLE_POLICIES_ERROR:
            return state.set(payload, {
                status: STORE_STATUS_ERROR,
            });
        case ADVISER_PORTFOLIO_AVAILABLE_POLICIES_RESOLVED:
            const { id, policies } = payload;
            return state.set(id, {
                status: STORE_STATUS_OK,
                policies,
            });
        default:
            return state;
    }
};

export default adviserPortfolioAvailablePoliciesReducer;
