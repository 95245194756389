import { calculateElementDimensions } from './browser/browser';

export const sortPortfolios = portfolios => {
    return portfolios
        ? [...portfolios].sort((a, b) => {
              const aDate = a.updated && a.updated.date ? a.updated.date : a.created.date;
              const bDate = b.updated && b.updated.date ? b.updated.date : b.created.date;
              return aDate > bDate ? -1 : 1;
          })
        : [];
};

export const checkPortfolioNameExists = (portfolios, name, id) =>
    portfolios.filter(portfolio => portfolio.name === name && portfolio.id !== id).length > 0;

export const linkedPolicyBreakpointStyles = {
    xl: {
        baseHeight: 56,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    lg: {
        baseHeight: 56,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    md: {
        baseHeight: 165,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    sm: {
        baseHeight: 165,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    xs: {
        baseHeight: 180,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    xxs: {
        baseHeight: 210,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
};

export const availablePolicyBreakpointStyles = {
    xl: {
        baseHeight: 86,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    lg: {
        baseHeight: 86,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    md: {
        baseHeight: 86,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    sm: {
        baseHeight: 186,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    xs: {
        baseHeight: 186,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    xxs: {
        baseHeight: 186,
        styles: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
};

const getPolicyDimensions = (policy, breakpoint, breakpointStyles) => {
    let policyHolderFullName, description, policyNumber;
    if (policy.hasOwnProperty('details')) {
        const { details } = policy;
        policyHolderFullName = details.policyHolderFullName;
        description = details.description;
        policyNumber = details.policyNumber;
    } else {
        policyHolderFullName = policy.policyHolderFullName;
        description = policy.description;
        policyNumber = policy.policyNumber;
    }
    const { styles } = breakpointStyles[breakpoint];
    const { height: nameHeight } = calculateElementDimensions(policyHolderFullName, styles);
    const { height: descriptionHeight } = calculateElementDimensions(description, styles);
    const { height: policyNumberHeight } = calculateElementDimensions(policyNumber, styles);
    let dateHeight = null;
    if (policy.linked) {
        const dimension = calculateElementDimensions(policy.linkedlinkedDate, styles);
        dateHeight = dimension.height;
    }
    return { nameHeight, descriptionHeight, policyNumberHeight, dateHeight };
};

export const addLinkedPolicyPermissionsOffset = (policy, height) => {
    if (policy.hasOwnProperty('permissions') && policy.permissions.switch === false) {
        height = height + 30;
    }
    return height;
};

export const getLinkedPolicyDesktopHeight = (policy, breakpoint) => {
    let maxHeight;
    const { nameHeight, descriptionHeight, policyNumberHeight, dateHeight } = getPolicyDimensions(
        policy,
        breakpoint,
        linkedPolicyBreakpointStyles
    );
    maxHeight = Math.max(nameHeight, descriptionHeight, policyNumberHeight, dateHeight);
    return maxHeight;
};

export const getLinkedPolicyMobileHeight = (policy, breakpoint) => {
    let totalHeight;
    const { nameHeight, descriptionHeight, policyNumberHeight, dateHeight } = getPolicyDimensions(
        policy,
        breakpoint,
        linkedPolicyBreakpointStyles
    );
    totalHeight = nameHeight + descriptionHeight + policyNumberHeight + dateHeight;
    return totalHeight;
};

export const getLinkedPolicyCardHeight = (policy, breakpoint) => {
    let height;
    switch (breakpoint) {
        case 'xl':
            height =
                linkedPolicyBreakpointStyles[breakpoint].baseHeight + getLinkedPolicyDesktopHeight(policy, breakpoint);
            break;
        case 'lg':
            height =
                linkedPolicyBreakpointStyles[breakpoint].baseHeight + getLinkedPolicyDesktopHeight(policy, breakpoint);
            break;
        case 'md':
            height =
                linkedPolicyBreakpointStyles[breakpoint].baseHeight + getLinkedPolicyMobileHeight(policy, breakpoint);
            break;
        case 'xxs':
            height =
                linkedPolicyBreakpointStyles[breakpoint].baseHeight + getLinkedPolicyMobileHeight(policy, breakpoint);
            break;
        default:
            height =
                linkedPolicyBreakpointStyles[breakpoint].baseHeight + getLinkedPolicyMobileHeight(policy, breakpoint);
            break;
    }
    return height;
};

export const getAvailablePolicyDesktopHeight = (policy, breakpoint) => {
    let maxHeight;
    const { nameHeight, descriptionHeight, policyNumberHeight } = getPolicyDimensions(
        policy,
        breakpoint,
        availablePolicyBreakpointStyles
    );
    maxHeight = Math.max(nameHeight, descriptionHeight, policyNumberHeight);
    return maxHeight;
};

export const getAvailablePolicyMobileHeight = (policy, breakpoint) => {
    let totalHeight;
    const { nameHeight, descriptionHeight, policyNumberHeight } = getPolicyDimensions(
        policy,
        breakpoint,
        availablePolicyBreakpointStyles
    );
    totalHeight = nameHeight + descriptionHeight + policyNumberHeight;
    return totalHeight;
};

export const getAvailablePolicyCardHeight = (policy, breakpoint) => {
    let height;
    switch (breakpoint) {
        case 'xl':
            height =
                availablePolicyBreakpointStyles[breakpoint].baseHeight +
                getAvailablePolicyDesktopHeight(policy, breakpoint);
            break;
        case 'lg':
            height =
                availablePolicyBreakpointStyles[breakpoint].baseHeight +
                getAvailablePolicyDesktopHeight(policy, breakpoint);
            break;
        case 'md':
            height =
                availablePolicyBreakpointStyles[breakpoint].baseHeight +
                getAvailablePolicyMobileHeight(policy, breakpoint);
            break;
        case 'xxs':
            height =
                availablePolicyBreakpointStyles[breakpoint].baseHeight +
                getAvailablePolicyMobileHeight(policy, breakpoint);
            break;
        default:
            height =
                availablePolicyBreakpointStyles[breakpoint].baseHeight +
                getAvailablePolicyMobileHeight(policy, breakpoint);
            break;
    }
    return height;
};

export const getInstructionPolicyCardHeight = breakpoint => {
    switch (breakpoint) {
        case 'xxs':
        case 'xs':
        case 'sm':
        case 'md':
            return 160;
        default:
            return 74;
    }
};
