/**
 * Styled-components theme for Reassure-UI document components
 */

import { rgba } from 'polished';
import pallette from './pallette';
const {
    white,
    graphite,
    black,
    midGreen,
} = pallette;

const themeConfig = {
    colors: {
        pdfViewer: {
            background: graphite,
            toolbar: {
                text: white,
                background: rgba(black, 0.9),
                icons: midGreen,
                separators: rgba(white, 0.2),
            }
        }
    }
}

export default themeConfig;