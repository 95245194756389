/**
 * Styled-components theme for Reassure-UI help components
 */

import pallette from './pallette';
const {
    transparent,
    white,
    black,
    midGreen,
} = pallette;


const themeConfig = {
    colors: {
        tooltip: {
            text: black,
            background: 'rgba(255,255,255,0.9)',
            inverted: {
                text: white,
                background: 'rgba(0,0,0,0.9)',
            }
        },
        tooltipToggle: {
            text: white,
            background: transparent,
            border: midGreen,
        }
    }
}

export default themeConfig;