export const SESSION_STORAGE_MAINTENANCE_WINDOW_NOTIFICATION_DISMISSED =
    'SESSION_STORAGE_MAINTENANCE_WINDOW_NOTIFICATION_DISMISSED';
export const SESSION_STORAGE_SESSION_TIMEOUT = 'SESSION_STORAGE_SESSION_TIMEOUT';
export const SESSION_STORAGE_SESSION_TIMEOUT_INACTIVITY = 'INACTIVITY';
export const SESSION_STORAGE_SESSION_TIMEOUT_SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SESSION_STORAGE_MFA_FORCE_LOGOUT = 'SESSION_STORAGE_MFA_FORCE_LOGOUT';

export const setMaintenanceWindowDismissed = () => {
    sessionStorage.setItem(SESSION_STORAGE_MAINTENANCE_WINDOW_NOTIFICATION_DISMISSED, true);
};

export const isMaintenanceWindowDismissed = () => {
    return !!sessionStorage.getItem(SESSION_STORAGE_MAINTENANCE_WINDOW_NOTIFICATION_DISMISSED);
};

export const setSessionTimeout = value => {
    sessionStorage.setItem(SESSION_STORAGE_SESSION_TIMEOUT, value);
};

export const hasInactivitySessionTimeout = () => {
    const sessionTimeout = sessionStorage.getItem(SESSION_STORAGE_SESSION_TIMEOUT);
    if (!sessionTimeout || sessionTimeout !== SESSION_STORAGE_SESSION_TIMEOUT_INACTIVITY) return false;
    return true;
};

export const hasSessionExpiredSessionTimeout = () => {
    const sessionTimeout = sessionStorage.getItem(SESSION_STORAGE_SESSION_TIMEOUT);
    if (!sessionTimeout || sessionTimeout !== SESSION_STORAGE_SESSION_TIMEOUT_SESSION_EXPIRED) return false;
    return true;
};

export const hasInactivityTimeout = () => {
    const sessionTimeout = sessionStorage.getItem(SESSION_STORAGE_SESSION_TIMEOUT);
    if (!sessionTimeout || sessionTimeout !== SESSION_STORAGE_SESSION_TIMEOUT_INACTIVITY) return false;
    return true;
};

export const deleteSessionTimeout = () => {
    return sessionStorage.removeItem(SESSION_STORAGE_SESSION_TIMEOUT);
};

export const setSessionExpiredViaMfaAtLogin = value => {
    sessionStorage.setItem(SESSION_STORAGE_MFA_FORCE_LOGOUT, value);
};

export const hasSessionExpiredViaMfaAtLogin = () => {
    const sessionExpiredMfaLogout = sessionStorage.getItem(SESSION_STORAGE_MFA_FORCE_LOGOUT);
    if (!sessionExpiredMfaLogout || sessionExpiredMfaLogout !== SESSION_STORAGE_MFA_FORCE_LOGOUT) return false;
    return true;
};

export const deleteMfaAtLoginLogoutMessage = () => {
    return sessionStorage.removeItem(SESSION_STORAGE_MFA_FORCE_LOGOUT);
};
