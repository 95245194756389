import { PROFILE_REQUESTED, PROFILE_RESOLVED, PROFILE_ERROR } from './types';
import { graphQLQuery } from '../../graphql/client';
import { profileDetailsQuery } from '../../graphql/queries/profile';

const getProfileDetails = async (dispatch, getState) => {
    const state = getState().profile;

    if (state && (state.get('status') !== 'INIT' || state.get('loading') === true)) return;

    dispatch({
        type: PROFILE_REQUESTED,
    });

    try {
        const profile = await graphQLQuery(profileDetailsQuery);
        return dispatch({
            type: PROFILE_RESOLVED,
            payload: {
                profile,
            },
        });
    } catch (error) {
        return dispatch({
            type: PROFILE_ERROR,
        });
    }
};

export default getProfileDetails;
