import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import {
    ELIGIBILITY_REQUESTED,
    ELIGIBILITY_RESET,
    ELIGIBILITY_POLICY_RESOLVED,
    ELIGIBILITY_USER_RESOLVED,
    ELIGIBILITY_ERROR,
    ELIGIBILITY_USER_SAVE_VALUES,
} from './types';

export const initialState = Map({
    status: STORE_STATUS_INIT,
    policyOutcome: null,
    userOutcome: null,
    companies: null,
    errorCount: 0,
    policyReference: null,
    publicAccessToken: null,
});

const eligibilityReducer = (state = initialState, { type, payload, policyReference }) => {
    switch (type) {
        case ELIGIBILITY_RESET:
        case STORE_RESET:
            return initialState;
        case ELIGIBILITY_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);
        case ELIGIBILITY_POLICY_RESOLVED:
            let errorCount = state.get('errorCount') || 0;
            return state
                .set('status', STORE_STATUS_OK)
                .set('policyOutcome', payload.outcome)
                .set('companies', payload.companies)
                .set('errorCount', errorCount + 1)
                .set('policyReference', policyReference)
                .set('publicAccessToken', payload.publicAccessToken);
        case ELIGIBILITY_USER_RESOLVED:
            return state
                .set('status', STORE_STATUS_OK)
                .set('userOutcome', payload.outcome)
                .set('publicAccessToken', payload.publicAccessToken);
        case ELIGIBILITY_USER_SAVE_VALUES:
            return state.set('userDetails', payload).set('userOutcome', null);
        case ELIGIBILITY_ERROR:
            return initialState.set('status', STORE_STATUS_ERROR);

        default:
            return state;
    }
};

export default eligibilityReducer;
