import { createSelector } from 'reselect';
import { STORE_STATUS_MAINTENANCE } from '../statuses';

const stateSelector = state => state.app;

export const selectApp = createSelector([stateSelector], state => {
    const status = state.get('status');
    return {
        maintenance: status === STORE_STATUS_MAINTENANCE,
        status,
        version: state.get('version'),
        maintenanceWindow: state.get('maintenanceWindow'),
        config: state.get('config'),
    };
});
