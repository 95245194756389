import {
    PORTFOLIO_POLICY_ACTION_LINK,
    PORTFOLIO_POLICY_ACTION_PRESERVE,
    PORTFOLIO_POLICY_ACTION_UNLINK,
} from './link-actions';

export const createPortfolioPolicyProfile = policies => {
    const policyProfile = {};
    if (!policies || !policies.length) return policyProfile;
    for (const policy of policies) {
        const {
            details: { policyId },
        } = policy;
        if (!policyId) continue;
        policyProfile[policyId] = {
            ...policy,
            action: PORTFOLIO_POLICY_ACTION_PRESERVE,
        };
    }

    return policyProfile;
};

export const transformPortfolioPolicy = ({ details, permissions }) => ({
    details,
    action: PORTFOLIO_POLICY_ACTION_LINK,
    linked: {
        date: 'n/a',
    },
    permissions,
});

export const getUpdateAdviserPortfolioPoliciesInput = ({ portfolioId, policies }) => ({
    portfolioId,
    policies: policies
        .filter(({ action }) => action !== PORTFOLIO_POLICY_ACTION_PRESERVE)
        .map(({ details: { policyId }, action }) => ({ policyId, action })),
});

export const getAdviserPortfolioPolicyProfileHasChanges = policies => {
    for (const policy of policies) {
        if (policy.action === PORTFOLIO_POLICY_ACTION_LINK || policy.action === PORTFOLIO_POLICY_ACTION_UNLINK)
            return true;
    }
    return false;
};
