import { useCallback, useEffect } from 'react';
import {
    FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED,
    FEATURE_ADVISER_MANAGE_POLICIES_ENABLED,
    FEATURE_ADVISER_PORTFOLIOS_ENABLED,
    FEATURE_ADVISOR_ENABLED,
    FEATURE_AGENCY_FULL_IDENTITY_ENABLED,
    FEATURE_CONFIG_UPDATE_ENABLED,
    FEATURE_DOCUMENTS_ENABLED,
    FEATURE_CHECK_ELIGIBILITY_ENABLED,
    FEATURE_CHANGE_ADDRESS_ENABLED,
    FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED,
    FEATURE_POLICY_ADMINISTRATOR_ENABLED,
    FEATURE_POLICY_BENEFITS_ENABLED,
    FEATURE_POLICY_BONUS_HISTORY_ENABLED,
    FEATURE_POLICY_FUND_SWITCH_ENABLED,
    FEATURE_POLICY_INCOME_HISTORY_ENABLED,
    FEATURE_POLICY_PAYMENT_HISTORY_ENABLED,
    FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED,
    FEATURE_POLICY_PROJECTIONS_ENABLED,
    FEATURE_POLICY_THIRD_PARTIES_ENABLED,
    FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED,
    FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED,
    FEATURE_POLICY_VALUATION_ENABLED,
    FEATURE_POLICY_VALUATION_CWP_ENABLED,
    FEATURE_POLICY_GUARANTEE_ENABLED,
    FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED,
    FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED,
    FEATURE_SELF_SERVICE_ENABLED,
    FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED,
    FEATURE_SCHEMES_ENABLED,
    FEATURE_MFA_AT_LOGIN_ENABLED,
    FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED,
} from '../../services/features';
import { useDispatch, useSelector } from 'react-redux';
import { STORE_STATUS_INIT, STORE_STATUS_MAINTENANCE, STORE_STATUS_OK } from '../statuses';
import { getAppConfigAction, getServerStatusAction } from './actions';
import { selectApp } from './selectors';
import { getAppConfigProperty, isInActiveMaintenanceWindow } from './helpers';
import { ADVISER_ROUTE, RETAIL_ROUTE } from '../../services/secureRoute';
import { useSubscription } from '@apollo/client';
import { APP_UPDATE } from './types';
import { onConfigSubscription } from '../../graphql/subscriptions/config';
import { ADVISER, RETAIL, SCHEME, SCHEME_SUPERUSER } from '../../services/role-types';
import { selectUserCategory } from '../selectors';

export const useApp = () => {
    const dispatch = useDispatch();
    const { status, maintenance, maintenanceWindow, version, config, resetApp } = useSelector(selectApp);
    const getApp = useCallback(async () => {
        await dispatch(getServerStatusAction);
        await dispatch(getAppConfigAction);
    }, [dispatch]);

    useEffect(() => {
        if (status === STORE_STATUS_INIT) {
            getApp();
        }
    }, [status, getApp]);

    return { status, maintenance, maintenanceWindow, version, config, resetApp };
};

export const useSummaryOfTermsAndConditionsUrl = () => {
    const {
        config: { SUMMARY_OF_TERMS_AND_CONDITIONS_URL },
    } = useApp();

    return SUMMARY_OF_TERMS_AND_CONDITIONS_URL;
};

export const useReadyMadeFundUrl = () => {
    const {
        config: { READY_MADE_FUND_URL },
    } = useApp();

    return READY_MADE_FUND_URL;
};

export const useTermsAndConditionsConfig = () => {
    const category = useSelector(selectUserCategory);
    const {
        config: {
            ADVISER_TERMS_VERSION_ID,
            ADVISER_TERMS_PUBLISH_DATE,
            RETAIL_TERMS_VERSION_ID,
            RETAIL_TERMS_PUBLISH_DATE,
            SCHEME_TERMS_VERSION_ID,
            SCHEME_TERMS_PUBLISH_DATE,
        },
    } = useApp();

    switch (category) {
        case ADVISER:
            return {
                type: ADVISER,
                version: ADVISER_TERMS_VERSION_ID,
                date: ADVISER_TERMS_PUBLISH_DATE,
            };
        case RETAIL:
            return {
                type: RETAIL,
                version: RETAIL_TERMS_VERSION_ID,
                date: RETAIL_TERMS_PUBLISH_DATE,
            };
        case SCHEME:
            return {
                type: SCHEME,
                version: SCHEME_TERMS_VERSION_ID,
                date: SCHEME_TERMS_PUBLISH_DATE,
            };
        case SCHEME_SUPERUSER:
            return {
                type: SCHEME_SUPERUSER,
                version: SCHEME_TERMS_VERSION_ID,
                date: SCHEME_TERMS_PUBLISH_DATE,
            };
        default:
            return {
                type: RETAIL,
                version: RETAIL_TERMS_VERSION_ID,
                date: RETAIL_TERMS_PUBLISH_DATE,
            };
    }
};

export const useFeatureFlags = () => {
    const { config } = useApp();
    return {
        adviserBackOfficeIntegrationEnabled: !!getAppConfigProperty(
            config,
            FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED
        ),
        adviserPortfoliosEnabled: !!getAppConfigProperty(config, FEATURE_ADVISER_PORTFOLIOS_ENABLED),
        adviserManagePoliciesEnabled: !!getAppConfigProperty(config, FEATURE_ADVISER_MANAGE_POLICIES_ENABLED),
        advisersEnabled: !!getAppConfigProperty(config, FEATURE_ADVISOR_ENABLED),
        agencyFullIdentityEnabled: !!getAppConfigProperty(config, FEATURE_AGENCY_FULL_IDENTITY_ENABLED),
        configUpdateEnabled: !!getAppConfigProperty(config, FEATURE_CONFIG_UPDATE_ENABLED),
        documentsEnabled: !!getAppConfigProperty(config, FEATURE_DOCUMENTS_ENABLED),
        checkEligibilityEnabled: !!getAppConfigProperty(config, FEATURE_CHECK_ELIGIBILITY_ENABLED),
        changeAddressEnabled: !!getAppConfigProperty(config, FEATURE_CHANGE_ADDRESS_ENABLED),
        singlePremiumPaymentEnabled: !!getAppConfigProperty(config, FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED),
        policyAdministratorsEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_ADMINISTRATOR_ENABLED),
        policyThirdPartiesEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_THIRD_PARTIES_ENABLED),
        policyBenefitsEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_BENEFITS_ENABLED),
        policyPremiumBreakdownEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED),
        policyTransactionHistoryEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED),
        policyValuationEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_VALUATION_ENABLED),
        policyValuationCWPEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_VALUATION_CWP_ENABLED),
        policyValuationDifferenceEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED),
        policyProjectionsEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_PROJECTIONS_ENABLED),
        policyPaymentHistoryEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_PAYMENT_HISTORY_ENABLED),
        policyIncomeHistoryEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_INCOME_HISTORY_ENABLED),
        policyBonusHistoryEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_BONUS_HISTORY_ENABLED),
        policyFundSwitchEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_FUND_SWITCH_ENABLED),
        policyGuaranteeEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_GUARANTEE_ENABLED),
        policyReturnOfPremiumEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED),
        policyRegularBonusCWPEnabled: !!getAppConfigProperty(config, FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED),
        selfServiceEnabled: !!getAppConfigProperty(config, FEATURE_SELF_SERVICE_ENABLED),
        schemeSuperUserNominationEnabled: !!getAppConfigProperty(config, FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED),
        schemesEnabled: !!getAppConfigProperty(config, FEATURE_SCHEMES_ENABLED),
        mfaAtLoginEnabled: !!getAppConfigProperty(config, FEATURE_MFA_AT_LOGIN_ENABLED),
        enableMfaAtLoginEnabled: !!getAppConfigProperty(config, FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED),
    };
};

/**
 * Hash map of routes to feature flags
 * IMPORTANT: These must be kept in order of specificity
 */
export const featureRouteMap = {
    '/policies/:policyId/benefits': FEATURE_POLICY_BENEFITS_ENABLED,
    '/policies/:policyId/premium-breakdown': FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED,
    '/policies/:policyId/transaction-history': FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED,
    '/policies/:policyId/current-value-flexi': FEATURE_POLICY_VALUATION_ENABLED,
    '/policies/:policyId/current-value-conventional': FEATURE_POLICY_VALUATION_CWP_ENABLED,
    '/policies/:policyId/current-value': FEATURE_POLICY_VALUATION_ENABLED,
    '/policies/:policyId/self-invested-value': FEATURE_POLICY_VALUATION_ENABLED,
    '/policies/:policyId/projection': FEATURE_POLICY_PROJECTIONS_ENABLED,
    '/policies/:policyId/payment-history': FEATURE_POLICY_PAYMENT_HISTORY_ENABLED,
    '/policies/:policyId/income-history': FEATURE_POLICY_INCOME_HISTORY_ENABLED,
    '/policies/:policyId/bonus-history': FEATURE_POLICY_BONUS_HISTORY_ENABLED,
    '/policies/:policyId/fund-switch': FEATURE_POLICY_FUND_SWITCH_ENABLED,
    '/policies/:policyId/return-of-premium': FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED,
    '/manage/portfolios': FEATURE_ADVISER_PORTFOLIOS_ENABLED,
    '/manage/integrations': FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED,
    '/manage': FEATURE_ADVISER_MANAGE_POLICIES_ENABLED,
    '/documents': FEATURE_DOCUMENTS_ENABLED,
    '/check-eligibility': FEATURE_CHECK_ELIGIBILITY_ENABLED,
    '/schemes/:schemeId': FEATURE_SCHEMES_ENABLED,
    '/schemes/:schemeId/members/:memberId': FEATURE_SCHEMES_ENABLED,
    '/profile/multi-factor-authentication': FEATURE_MFA_AT_LOGIN_ENABLED,
};

/**
 * Returns a redirect path if a feature is not enabled up in config
 */
export const useFeatureRedirect = ({ path }) => {
    const { config } = useApp();

    let featureFlag;

    for (const key of Object.keys(featureRouteMap)) {
        if (path.indexOf(key) === 0) {
            featureFlag = featureRouteMap[key];
            break;
        }
    }

    const featureEnabled = !!getAppConfigProperty(config, featureFlag);

    if (!featureFlag || featureEnabled) return false;

    const redirectRoutes = [RETAIL_ROUTE, ADVISER_ROUTE, '/'];

    let redirectRoute;

    for (const route of redirectRoutes) {
        if (path.includes(route)) {
            redirectRoute = route;
            break;
        }
    }

    return redirectRoute;
};

export const useAppConfigSubscription = () => {
    const dispatch = useDispatch();

    const { configUpdateEnabled } = useFeatureFlags();

    const { data, loading } = useSubscription(onConfigSubscription, { skip: !configUpdateEnabled });

    useEffect(() => {
        if (!loading && data && data.onConfig) {
            const action = {
                type: APP_UPDATE,
            };

            const payload = {
                status: STORE_STATUS_OK,
            };

            const maintenanceWindow = {
                from: data.onConfig.PLANNED_MAINTENANCE_FROM || null,
                to: data.onConfig.PLANNED_MAINTENANCE_TO || null,
            };

            payload.config = data.onConfig;
            payload.maintenanceWindow = maintenanceWindow;

            const activeWindow = isInActiveMaintenanceWindow(maintenanceWindow);

            if (activeWindow) {
                payload.status = STORE_STATUS_MAINTENANCE;
            }

            dispatch({ ...action, payload });
        }
    }, [dispatch, loading, data]);
};
