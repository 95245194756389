import { Map } from 'immutable';
import { STORE_RESET } from '../types';
import {
    POLICY_TRANSACTION_HISTORY_REQUESTED,
    POLICY_TRANSACTION_HISTORY_RESOLVED,
    POLICY_TRANSACTION_HISTORY_ERROR,
} from './types';

/**
 *
 * Set the initial state of policy transaction history reducer
 *
 */
const schema = {};

const initialState = Map(schema);

const updatePolicyTransactionHistory = (state, { payload }) => {
    const policyTransactionHistory = state.get(payload.policyId);

    return policyTransactionHistory ? Object.assign({}, policyTransactionHistory, payload) : payload;
};

/**
 *
 * The Benefits Reducer
 *
 * Responsible for managing the state and data of customer policy benefits
 *
 */
const policyTransactionHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case POLICY_TRANSACTION_HISTORY_REQUESTED:
            return state.set(action.payload.policyId, updatePolicyTransactionHistory(state, action));

        case POLICY_TRANSACTION_HISTORY_RESOLVED:
            return state.set(action.payload.policyId, updatePolicyTransactionHistory(state, action));

        case POLICY_TRANSACTION_HISTORY_ERROR:
            return state.set(action.payload.policyId, updatePolicyTransactionHistory(state, action));

        default:
            return state;
    }
};

export { initialState };

export default policyTransactionHistoryReducer;
