/**
 * Styled-components theme for Reassure-UI badge components
 */

import pallette from './pallette';
const {
    black,
    teal,
} = pallette;


const themeConfig = {
    colors: {
        badge: {
            text: black,
            background: teal,
            border: teal,
        }
    }
}

export default themeConfig;