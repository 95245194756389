import { Map } from 'immutable';
import { transformFundProfileFundsToAbiSectors } from '../helpers';
import { STORE_RESET } from '../types';
import { createFundProfile } from './helpers';
import {
    FUND_PROFILE_ADD_FUND,
    FUND_PROFILE_CONFIRM_SELECTION,
    FUND_PROFILE_CREATE,
    FUND_PROFILE_DELETE,
    FUND_PROFILE_REMOVE_FUND,
    FUND_PROFILE_UPDATE_ALLOCATION,
} from './types';

export const initialState = Map({});

const fundProfileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FUND_PROFILE_CREATE:
            return state
                .set(payload.id, createFundProfile(payload.funds))
                .set('abiSectors', transformFundProfileFundsToAbiSectors(payload.funds));
        case FUND_PROFILE_DELETE:
            return state.delete(payload.id).delete('abiSectors');
        case FUND_PROFILE_ADD_FUND:
            state = state.setIn([payload.id, payload.fund.reAssureId], payload.fund);
            return state.set('abiSectors', transformFundProfileFundsToAbiSectors(state.get(payload.id)));
        case FUND_PROFILE_REMOVE_FUND:
            state = state.deleteIn([payload.id, payload.reAssureId]);
            return state.set('abiSectors', transformFundProfileFundsToAbiSectors(state.get(payload.id)));
        case FUND_PROFILE_CONFIRM_SELECTION:
            return state
                .setIn([payload.id, payload.reAssureId, 'confirmMultiUnitFundOnSelection'], false)
                .setIn([payload.id, payload.reAssureId, 'confirmCashFundOnSelection'], false)
                .setIn([payload.id, payload.reAssureId, 'invalidPolicies'], []);
        case FUND_PROFILE_UPDATE_ALLOCATION:
            state = state.setIn([payload.id, payload.reAssureId, 'allocation'], payload.allocation);
            return state.set('abiSectors', transformFundProfileFundsToAbiSectors(state.get(payload.id)));
        case STORE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default fundProfileReducer;
