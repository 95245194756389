/**
 * A helper service for logging Google Analytics
 * @type {Object}
 */
import { titleCase } from '../utils/strings';
import { LOG_IN, ROLE_LOG_IN, GROUP_LOG_IN, LOG_OUT } from './categories';
import TagManager from 'react-gtm-module';

const analyticsService = {};

/**
 * Helper method to format the event in Google Analytics
 * @param string - The string to format
 */
analyticsService.format = string => {
    return titleCase(string.replace(/(-|_)/g, ' '));
};

/**
 * Method to log a user log in event in Google Analytics
 * @param label String - The event label for Google Analytics
 */
analyticsService.logLogInEvent = label => {
    analyticsService.logEvent({
        event: LOG_IN,
        action: 'All user log ins',
        label,
    });
};

/**
 * Method to log a user log in event with the user's role and group in Google Analytics
 */
analyticsService.logRoleLogInEvent = async ({ role, agencyGroup }) => {
    const LOG_IN_ACTION = 'Logged in';
    analyticsService.logEvent({
        event: ROLE_LOG_IN,
        action: LOG_IN_ACTION,
        label: analyticsService.format(role),
    });

    if (agencyGroup) {
        analyticsService.logEvent({
            event: GROUP_LOG_IN,
            action: LOG_IN_ACTION,
            label: `Adviser ${analyticsService.format(agencyGroup)}`,
        });
    }
};

/**
 * Method to log a user log out event in Google Analytics
 * @param label String - The event label for Google Analytics
 */
analyticsService.logLogOutEvent = label => {
    analyticsService.logEvent({
        event: LOG_OUT,
        action: 'All user log outs',
        label,
    });
};

/**
 * Method to log a general event in Google Analytics
 * @param event Object - The event for Google Analytics with category, action and label properties
 */
analyticsService.logEvent = ({ event, action, label }) => {
    TagManager.dataLayer({
        dataLayer: {
            event,
            action,
            label,
        },
    });
};

export default analyticsService;
