import { Map } from 'immutable';
import {
    ADD_AGENCY_GROUP_USER_REQUESTED,
    ADD_AGENCY_GROUP_USER_RESOLVED,
    ADD_AGENCY_GROUP_USER_ERROR,
    ADD_AGENCY_GROUP_USER_RESET,
} from '../agency-group-users/types';
import { STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';

const schema = {
    status: STORE_STATUS_OK,
    adding: false,
};

const initialState = Map(schema);

const addAgencyGroupUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_AGENCY_GROUP_USER_REQUESTED:
            return state.set('adding', true).set('status', STORE_STATUS_OK);

        case ADD_AGENCY_GROUP_USER_RESOLVED:
            if (!action.payload.errorCode) {
                return state.set('status', STORE_STATUS_OK).set('adding', false);
            } else {
                return state.set('status', action.payload.errorCode).set('adding', false);
            }

        case ADD_AGENCY_GROUP_USER_ERROR:
            return state.set('status', 'ERROR').set('adding', false);

        case ADD_AGENCY_GROUP_USER_RESET:
            return initialState;

        case STORE_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default addAgencyGroupUserReducer;
