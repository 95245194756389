import { Map } from 'immutable';
import { AGENCY_GROUP_DETAILS_REQUESTED, AGENCY_GROUP_DETAILS_RESOLVED, AGENCY_GROUP_DETAILS_ERROR } from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    details: null,
};

const initialState = Map(schema);

const agencyGroupDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case AGENCY_GROUP_DETAILS_REQUESTED:
            return state.set('loading', true);

        case AGENCY_GROUP_DETAILS_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('loading', false).set('details', payload);

        case AGENCY_GROUP_DETAILS_ERROR:
            return state.set('status', 'ERROR').set('loading', false).set('details', null);

        default:
            return state;
    }
};

export { initialState };

export default agencyGroupDetailsReducer;
