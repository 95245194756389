// Opera 8.0+
const isOpera =
    (!!window.opr && !!window.opr.addons) || !!window.opera || window.navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || (typeof safari !== 'undefined' && window.safari.pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1+
const isChrome = !!window.chrome && !!window.chrome.webstore;

// Blink engine detection
//const isBlink = (isChrome || isOpera) && !!window.CSS;

// iOS
const isIOS =
    window.navigator.vendor != null &&
    window.navigator.vendor.match(/Apple Computer, Inc./) &&
    (window.navigator.userAgent.match(/iPhone/i) ||
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPod/i));

// Android
const isAndroid = window.navigator.userAgent.match(/Android/i);

// Windows
const isWindows =
    window.navigator.platform === 'Win32' ||
    window.navigator.platform === 'Win64' ||
    window.navigator.userAgent.match(/Windows NT/i);

// MacOS
const isMacOS = window.navigator.platform === 'MacIntel' || window.navigator.userAgent.match(/Macintosh/i);

export const getBrowser = () => {
    if (isOpera) {
        return 'Opera';
    } else if (isFirefox) {
        return 'isFirefox';
    } else if (isSafari) {
        return 'Safari';
    } else if (isFirefox) {
        return 'isFirefox';
    } else if (isIE) {
        return 'IE';
    } else if (isEdge) {
        return 'Edge';
    } else if (isChrome) {
        return 'Chrome';
    } else if (isFirefox) {
        return 'isFirefox';
    } else if (isFirefox) {
        return 'isFirefox';
    }
};

export const getOS = () => {
    if (isIOS) {
        return 'iOS';
    } else if (isAndroid) {
        return 'Android';
    } else if (isWindows) {
        return 'Windows';
    } else if (isMacOS) {
        return 'MacOS';
    } else {
        return 'Unknown';
    }
};

export const getWindowWidth = () => window.innnerWidth || global.innerWidth || global.window.innerWidth;

export const getBreakpoint = () => {
    const windowWidth = getWindowWidth();
    let breakpoint = 'xxs';
    if (windowWidth >= 375) breakpoint = 'xs';
    if (windowWidth >= 576) breakpoint = 'sm';
    if (windowWidth >= 768) breakpoint = 'md';
    if (windowWidth >= 992) breakpoint = 'lg';
    if (windowWidth >= 1400) breakpoint = 'xl';
    return breakpoint;
};

export const getDimensionsCalcElement = () => {
    const id = 'dimensions-calc-element';
    let div = document.getElementById(id);
    if (!div) {
        try {
            div = document.createElement('div');
            div.id = id;
            div.style.fontFamily = '"Open sans"';
            // hide the element off the screen
            div.style.position = 'fixed';
            div.style.left = '-10000px';
            // test id for unit tests
            div.dataset.testid = id;
            // insert the div
            const root = document.getElementById('root');
            document.body.insertBefore(div, root);
        } catch (error) {
            console.error(error);
        }
    }
    return div;
};

export const calculateElementDimensions = (content, styles) => {
    const el = getDimensionsCalcElement();
    styles = {
        ...el.styles,
        ...styles,
    };
    for (const style of Object.keys(styles)) {
        el.style[style] = styles[style];
    }
    el.textContent = content;
    return {
        width: el.offsetWidth,
        height: el.offsetHeight,
    };
};
