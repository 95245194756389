import { gql } from '@apollo/client';

export const onProfileDetailsSubscription = gql`
    subscription OnProfileDetails {
        onProfileDetails {
            title
            firstName
            lastName
            dateOfBirth
            nationalInsuranceNumber
            maritalStatus
            telephoneNumber {
                value
                status
                editable
            }
            mobileNumber {
                value
                status
                editable
            }
            emailAddress {
                value
                status
                editable
            }
            address {
                value {
                    address
                    postCode
                }
                status
                editable
            }
        }
    }
`;
