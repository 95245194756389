export const SCHEME_NOMINATION_SET_SCHEME_NUMBER = 'SCHEME_NOMINATION_SET_SCHEME_NUMBER';
export const SCHEME_NOMINATION_SET_TRUSTEE_DETAILS = 'SCHEME_NOMINATION_SET_TRUSTEE_DETAILS';
export const SCHEME_NOMINATION_SET_SUPERUSER_DETAILS = 'SCHEME_NOMINATION_SET_SUPERUSER_DETAILS';
export const SCHEME_NOMINATION_SET_SUPERUSER_SUCCESS = 'SCHEME_NOMINATION_SET_SUPERUSER_SUCCESS';

export const SCHEME_NOMINATION_STATUS_SCHEME_NUMBER = 'SCHEME_NUMBER';
export const SCHEME_NOMINATION_STATUS_TRUSTEE_DETAILS = 'TRUSTEE_DETAILS';
export const SCHEME_NOMINATION_STATUS_SUPERUSER_DETAILS = 'SUPERUSER_DETAILS';
export const SCHEME_NOMINATION_STATUS_SUMMARY = 'SUMMARY';
export const SCHEME_NOMINATION_STATUS_SUCCESS = 'SUCCESS';
