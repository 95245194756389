export const CONFIG_COMMISSION_AGENT_LIMIT = 'COMMISSION_AGENT_LIMIT';
export const CONFIG_PDF_VIEWER_RENDER_MAX_PAGES = 'PDF_VIEWER_RENDER_MAX_PAGES';
export const CONFIG_PLANNED_MAINTENANCE_FROM = 'PLANNED_MAINTENANCE_FROM';
export const CONFIG_PLANNED_MAINTENANCE_TO = 'PLANNED_MAINTENANCE_TO';
export const CONFIG_GTM_ACCOUNT = 'GTM_ACCOUNT';
export const CONFIG_LOQATE_API_KEY = 'LOQATE_API_KEY';
export const COOKIE_PRO_DOMAIN_SCRIPT_ID = 'COOKIE_PRO_DOMAIN_SCRIPT_ID';

export const getConfig = state => {
    if (!state || !state.app || !state.app.get('config')) return null;
    return state.app.get('config');
};

/**
 * Return the commission agent limit
 *
 * @param {Object} state The redux state
 * @return {Mixed} Null if unavailable or number if available
 */
export const getCommissionAgentLimit = state => {
    const config = getConfig(state);
    if (!config || !config[CONFIG_COMMISSION_AGENT_LIMIT] || isNaN(config[CONFIG_COMMISSION_AGENT_LIMIT])) return 0;
    return config[CONFIG_COMMISSION_AGENT_LIMIT];
};

/**
 * Return the PDF Viewer render max pages limit
 *
 * @param {Object} state The redux state
 * @return {Mixed} Null if unavailable or number if available
 */
export const getPdfViewerRenderMaxPages = state => {
    const config = getConfig(state);
    if (!config || !config[CONFIG_PDF_VIEWER_RENDER_MAX_PAGES] || isNaN(config[CONFIG_PDF_VIEWER_RENDER_MAX_PAGES]))
        return 100;
    return config[CONFIG_PDF_VIEWER_RENDER_MAX_PAGES];
};
