import { gql } from '@apollo/client';

export const onConfigSubscription = gql`
    subscription OnConfig {
        onConfig {
            COMMISSION_AGENT_LIMIT
            FEATURE_ADVISOR_ENABLED
            FEATURE_AGENCY_FULL_IDENTITY_ENABLED
            FEATURE_DOCUMENTS_ENABLED
            FEATURE_POLICY_VALUATION_ENABLED
            FEATURE_POLICY_VALUATION_CWP_ENABLED
            FEATURE_POLICY_BENEFITS_ENABLED
            FEATURE_POLICY_BONUS_HISTORY_ENABLED
            FEATURE_POLICY_PREMIUM_BREAKDOWN_ENABLED
            FEATURE_POLICY_TRANSACTION_HISTORY_ENABLED
            FEATURE_POLICY_PAYMENT_HISTORY_ENABLED
            FEATURE_POLICY_INCOME_HISTORY_ENABLED
            FEATURE_POLICY_PROJECTIONS_ENABLED
            FEATURE_POLICY_ADMINISTRATOR_ENABLED
            FEATURE_POLICY_THIRD_PARTIES_ENABLED
            FEATURE_POLICY_VALUATION_DIFFERENCE_ENABLED
            FEATURE_POLICY_GUARANTEE_ENABLED
            FEATURE_POLICY_RETURN_OF_PREMIUM_ENABLED
            FEATURE_POLICY_REGULAR_BONUS_CWP_ENABLED
            FEATURE_CONFIG_UPDATE_ENABLED
            FEATURE_SELF_SERVICE_ENABLED
            FEATURE_ADVISER_MANAGE_POLICIES_ENABLED
            FEATURE_POLICY_FUND_SWITCH_ENABLED
            FEATURE_ADVISER_PORTFOLIOS_ENABLED
            FEATURE_ADVISER_BACK_OFFICE_INTEGRATION_ENABLED
            FEATURE_CHECK_ELIGIBILITY_ENABLED
            FEATURE_CHANGE_ADDRESS_ENABLED
            FEATURE_SINGLE_PREMIUM_PAYMENT_ENABLED
            FEATURE_SCHEME_SUPER_USER_NOMINATION_ENABLED
            FEATURE_SCHEMES_ENABLED
            FEATURE_MFA_AT_LOGIN_ENABLED
            FEATURE_ENABLE_MFA_AT_LOGIN_ENABLED
            ALLOWED_PORTFOLIO_ORIGINATING_COMPANIES
            PDF_VIEWER_RENDER_MAX_PAGES
            CUSTOMER_SERVICES_EXPERIENCING_HIGH_VOLUMES
            PLANNED_MAINTENANCE_FROM
            PLANNED_MAINTENANCE_TO
            GTM_ACCOUNT
            OKTA_ORIGIN
            OKTA_CLIENT_ID
            ADVISER_TERMS_DOCUMENT_ID
            ADVISER_TERMS_VERSION_ID
            ADVISER_TERMS_PUBLISH_DATE
            RETAIL_TERMS_DOCUMENT_ID
            RETAIL_TERMS_VERSION_ID
            RETAIL_TERMS_PUBLISH_DATE
            SCHEME_TERMS_DOCUMENT_ID
            SCHEME_TERMS_VERSION_ID
            SCHEME_TERMS_PUBLISH_DATE
            PORTFOLIO_MANAGEMENT_PERFORMANCE_WARNING
            LOQATE_API_KEY
            COOKIE_PRO_DOMAIN_SCRIPT_ID
            SUMMARY_OF_TERMS_AND_CONDITIONS_URL
            READY_MADE_FUND_URL
        }
    }
`;
