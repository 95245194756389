import { Map } from 'immutable';
import {
    REMOVE_AGENCY_GROUP_USER_REQUESTED,
    REMOVE_AGENCY_GROUP_USER_RESOLVED,
    REMOVE_AGENCY_GROUP_USER_ERROR,
    REMOVE_AGENCY_GROUP_USER_ERROR_CODE,
    REMOVE_AGENCY_GROUP_USER_RESET,
} from '../agency-group-users/types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';

const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    error: null,
};

const initialState = Map(schema);

const removeAgencyGroupUserReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case REMOVE_AGENCY_GROUP_USER_RESET:
            return initialState;

        case REMOVE_AGENCY_GROUP_USER_REQUESTED:
            return state.set('loading', true);

        case REMOVE_AGENCY_GROUP_USER_RESOLVED:
            return state.set('status', STORE_STATUS_OK).set('loading', false);

        case REMOVE_AGENCY_GROUP_USER_ERROR:
            return state.set('status', STORE_STATUS_ERROR).set('loading', false);

        case REMOVE_AGENCY_GROUP_USER_ERROR_CODE:
            return state.set('status', 'OK').set('loading', false).set('error', payload.errorCode);

        default:
            return state;
    }
};

export { initialState };

export default removeAgencyGroupUserReducer;
