import getContent from '../services/content/getContent';

export const SECTOR_COLORS = [
    '#4DA7AA',
    '#F59600',
    '#F547AE',
    '#8C66C3',
    '#75A3FF',
    '#C4AC0B',
    '#AA4DA8',
    '#4DAA50',
    '#C01B57',
    '#9B9EA0',
    '#FF3C55',
    '#8ECACC',
    '#FBCB7E',
    '#FFB8E2',
    '#CAAEF2',
    '#B3CCFF',
    '#EBD754',
    '#C480C3',
    '#86CF89',
    '#D65A87',
    '#CDD1D4',
    '#FF8A99',
    '#2E878A',
    '#CB7D01',
    '#C52483',
    '#6B44A2',
    '#4F7ACF',
    '#9E8A04',
    '#862C84',
    '#2F8532',
    '#991143',
    '#576168',
    '#CC2B40',
    '#B2DBDC',
    '#FBDDB0',
    '#F8CBE8',
    '#DCC4FF',
    '#CCDDFF',
    '#F2E27C',
    '#DEA6DD',
    '#9BE89F',
    '#FA9BBE',
    '#E5EAED',
    '#FFCFD5',
];

export const transformHashMapToArray = hashMap => {
    if (!hashMap) return [];
    const result = [];
    for (const key in hashMap) {
        result.push(hashMap[key]);
    }
    return result;
};

export const transformNullFundValues = (funds, substitute = '-') => {
    const result = [];

    if (!funds) return result;

    for (const fund of funds) {
        let item = { ...fund };

        for (const [key, value] of Object.entries(item)) {
            if (key === 'units') {
                item.units = transformNullFundValues(value);
            }
            if (value === null || value === undefined) {
                item = {
                    ...item,
                    [key]:
                        key === 'abiSectorName' ? getContent(['funds', 'abi-sector-not-available-label']) : substitute,
                };
            }
        }

        result.push(item);
    }

    return result;
};

const sortAbiSectorsByAllocation = abiSectors => {
    return abiSectors.sort((a, b) => {
        if (b.allocation === a.allocation) {
            return a.name > b.name ? 1 : -1;
        }
        return b.allocation - a.allocation;
    });
};

const aggregateFundAllocationsBySector = funds => {
    const aggregatedFunds = funds.reduce((aggregator, currentFund) => {
        let { abiSectorName: currentAbiSectorName } = currentFund;
        let matching = aggregator.find(({ abiSectorName }) => abiSectorName === currentAbiSectorName);
        const { abiSectorName, allocation } = currentFund;
        matching ? (matching.allocation += currentFund.allocation) : aggregator.push({ abiSectorName, allocation });

        return aggregator;
    }, []);

    return aggregatedFunds.map(({ abiSectorName: name, ...rest }) => ({ name, ...rest }));
};

export const setFundAllocationsToSector = (abiSectors, funds) => {
    const nullTransformedSectors = transformNullFundValues(
        abiSectors,
        getContent(['funds', 'abi-sector-not-available-label'])
    );

    const nullTransformedFunds = transformNullFundValues(funds);

    const aggregatedSectorAllocations = aggregateFundAllocationsBySector(nullTransformedFunds);
    for (const { name: aggregatedAbiSectorName, allocation } of aggregatedSectorAllocations) {
        let matching = nullTransformedSectors.find(({ name }) => {
            return aggregatedAbiSectorName === name;
        });

        if (matching) matching.allocation = allocation;
    }

    sortAbiSectorsByAllocation(nullTransformedSectors);

    return nullTransformedSectors;
};

export const transformFundsToUniqueAbiSectors = funds => {
    const uniqueSectors = {};
    for (const { abiSectorName } of funds) {
        uniqueSectors[abiSectorName] = true;
    }
    return Object.keys(uniqueSectors).map(name => {
        return name === 'null' ? { name: null } : { name };
    });
};

export const setColorsToAbiSectors = (currentAbiSectors, availableAbiSectors) => {
    const currentFundSectorsHashmap = {};

    for (const { name } of currentAbiSectors) {
        currentFundSectorsHashmap[name] = true;
    }

    availableAbiSectors = availableAbiSectors.filter(sector => !currentFundSectorsHashmap[sector.name]);
    const allSectors = [...currentAbiSectors, ...availableAbiSectors];

    const allSectorsSorted = sortAbiSectorsByAllocation(allSectors);
    const sectorMap = {};

    for (const [index, { name }] of allSectorsSorted.entries()) {
        sectorMap[name] = SECTOR_COLORS[index];
    }
    return sectorMap;
};

export const transformFundProfileFundsToAbiSectors = funds => {
    const transformedFunds = transformHashMapToArray(funds);
    const uniqueAbiSectors = transformFundsToUniqueAbiSectors(transformedFunds);

    return setFundAllocationsToSector(uniqueAbiSectors, transformedFunds);
};

export const removeDuplicatesFromArray = (array, property) => {
    if (!array) return [];
    return [...new Set(property ? array.map(item => item[property]) : array)];
};

export const transformArrayToHashmap = (array, propertyName) => {
    if (!array.length || !array) return {};

    const hashmap = {};

    const keys = removeDuplicatesFromArray(array, propertyName);

    for (const key of keys) {
        const matches = array.filter(item => item[propertyName] === key);
        hashmap[key] = matches;
    }

    return hashmap;
};
