import { createSelector } from 'reselect';
import { STORE_STATUS_EDIT, STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING } from '../statuses';
import { transformNullFundValues } from '../helpers';

const stateSelector = (state, portfolioId) => state.adviserPortfolioDetails.get(portfolioId);
const filterSelector = state => state.adviserPortfolioDetails.get('abiSectorFilter');

export const selectAdviserPortfolioDetails = createSelector([stateSelector], state => {
    if (!state) return { status: STORE_STATUS_INIT, loading: true, funds: [], policies: [], editting: false };
    const { status, funds, policies, abiSectors } = state;
    return {
        status,
        funds: transformNullFundValues(funds),
        policies,
        abiSectors,
        loading: status === STORE_STATUS_LOADING || status === STORE_STATUS_INIT,
        error: status === STORE_STATUS_ERROR,
        editting: status === STORE_STATUS_EDIT,
    };
});

export const selectAdviserPortfolioSectorFilter = createSelector([filterSelector], abiSectorFilter => {
    return {
        abiSectorFilter: abiSectorFilter === undefined ? null : abiSectorFilter,
    };
});
