import { Map } from 'immutable';

import {
    CHANGE_PASSWORD_REQUESTED,
    CHANGE_PASSWORD_RESOLVED,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_RESET,
} from '../user/types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_INIT } from '../statuses';

/**
 *
 * Set the initial state of our chage password reducer
 *
 */
const initialState = Map({
    status: STORE_STATUS_INIT,
    loading: false,
});

/**
 * The changePassword Reducer
 *
 * A reducer which controls the state of the change password journey
 *
 */
const changePasswordReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHANGE_PASSWORD_REQUESTED:
            state = state.set('loading', true);

            return state;

        case CHANGE_PASSWORD_RESOLVED:
            state = state.set('status', 'SUCCESS');
            state = state.set('loading', false);

            return state;

        case CHANGE_PASSWORD_ERROR:
            state = state.set('status', payload.error);
            state = state.set('loading', false);

            return state;

        case STORE_RESET:
            return initialState;

        case CHANGE_PASSWORD_RESET:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default changePasswordReducer;
