export const ADVISER_CLIENT_POLICIES_REQUESTED = 'ADVISER_CLIENT_POLICIES_REQUESTED';
export const ADVISER_CLIENT_POLICIES_RESOLVED = 'ADVISER_CLIENT_POLICIES_RESOLVED';
export const ADVISER_CLIENT_POLICIES_ERROR = 'ADVISER_CLIENT_POLICIES_ERROR';
export const ADVISER_CLIENT_POLICIES_NEXT_PAGE = 'ADVISER_CLIENT_POLICIES_NEXT_PAGE';
export const ADVISER_CLIENT_POLICIES_PREV_PAGE = 'ADVISER_CLIENT_POLICIES_PREV_PAGE';
export const ADVISER_CLIENT_POLICIES_SET_FILTER = 'ADVISER_CLIENT_POLICIES_SET_FILTER';
export const ADD_ADVISER_CLIENT_POLICIES_REQUESTED = 'ADD_ADVISER_CLIENT_POLICIES_REQUESTED';
export const ADD_ADVISER_CLIENT_POLICIES_RESOLVED = 'ADD_ADVISER_CLIENT_POLICIES_RESOLVED';
export const ADD_ADVISER_CLIENT_POLICIES_ERROR = 'ADD_ADVISER_CLIENT_POLICIES_ERROR';
export const RESET_ADD_ADVISER_CLIENT_POLICIES = 'RESET_ADD_ADVISER_CLIENT_POLICIES';
export const ADVISER_CLIENT_POLICIES_DOWNLOAD_REQUESTED = 'ADVISER_CLIENT_POLICIES_DOWNLOAD_REQUESTED';
export const ADVISER_CLIENT_POLICIES_DOWNLOAD_RESOLVED = 'ADVISER_CLIENT_POLICIES_DOWNLOAD_RESOLVED';
export const ADVISER_CLIENT_POLICIES_DOWNLOAD_ERROR = 'ADVISER_CLIENT_POLICIES_DOWNLOAD_ERROR';
export const ADVISER_CLIENT_POLICIES_DOWNLOADS_REQUESTED = 'ADVISER_CLIENT_POLICIES_DOWNLOADS_REQUESTED';
export const ADVISER_CLIENT_POLICIES_DOWNLOADS_RESOLVED = 'ADVISER_CLIENT_POLICIES_DOWNLOADS_RESOLVED';
export const ADVISER_CLIENT_POLICIES_DOWNLOADS_ERROR = 'ADVISER_CLIENT_POLICIES_DOWNLOADS_ERROR';