import { Map } from 'immutable';
import { TOGGLE_UI_VISIBILITY } from './types';
import { STORE_RESET } from '../types';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    addClientPanelVisible: false,
    addUserPanelVisible: false,
    createAdviserPortfolioVisible: false,
};

const initialState = Map(schema);

/**
 *
 * The Display Toggles Reducer
 *
 * Controls the visibility/display of the various UI elements
 *
 */
const uiTogglesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case TOGGLE_UI_VISIBILITY:
            return state.set(payload.propertyName, payload.visible);

        default:
            return state;
    }
};

export { initialState };

export default uiTogglesReducer;
