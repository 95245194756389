import { Map } from 'immutable';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';

import { RECOVERY_QUESTIONS_REQUESTED, RECOVERY_QUESTIONS_RESOLVED, RECOVERY_QUESTIONS_ERROR } from './types';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    questions: null,
};

const initialState = Map(schema);

/**
 *
 * The Recovery Questions Reducer
 *
 * A reducer to manage recovery questions
 *
 */
const recoveryQuestionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case RECOVERY_QUESTIONS_REQUESTED:
            return state.set('loading', true);

        case RECOVERY_QUESTIONS_RESOLVED:
            state = state.set('status', STORE_STATUS_OK);
            state = state.set('loading', false);
            state = state.set('questions', payload);

            return state;

        case RECOVERY_QUESTIONS_ERROR:
            state = state.set('status', STORE_STATUS_ERROR);
            state = state.set('loading', false);
            state = state.set('questions', null);

            return state;

        default:
            return state;
    }
};

export { initialState };

export default recoveryQuestionsReducer;
