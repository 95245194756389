import { Map } from 'immutable';
import { isEqual } from 'lodash';
import {
    USER_CREATING,
    USER_CREATED,
    USER_CREATION_ERROR,
    USER_LOGIN_REQUESTED,
    USER_LOGIN_RESOLVED,
    USER_LOGIN_ERROR,
    USER_OKTA_ERROR,
    USER_LOGIN_ERROR_RESET,
    USER_LOCKED_OUT,
    USER_UPDATE_REQUESTED,
    USER_UPDATE_RESOLVED,
    USER_UPDATE_ERROR,
    UPDATE_LAST_LOGIN_REQUESTED,
    UPDATE_LAST_LOGIN_RESOLVED,
    UPDATE_LAST_LOGIN_ERROR,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING } from '../statuses';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    customerId: null,
    contextId: null,
    username: null,
    groups: [],
    category: null,
    role: null,
    agencyGroup: null,
    currentLogin: null,
    lastLogin: null,
    emailVerified: false,
    notificationPreferencesSet: false,
    validated: false,
    welcomeScreenViewed: false,
    termsAndConditionsAccepted: false,
    factors: [],
    recoveryQuestion: null,
    mfaAtLoginEnabled: false,
};

const initialState = Map(schema);

/**
 *
 * The User Reducer
 *
 * A reducer to manage the state of the user in the store
 *
 */
const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            // when resetting the store preserve the status if it is CREATED
            if (state.get('status') === 'CREATED') {
                return initialState.set('status', 'CREATED');
            } else {
                return initialState;
            }

        case USER_UPDATE_REQUESTED:
            return state.set('loading', true);

        case USER_UPDATE_RESOLVED:
            let updatedState = state.set('loading', false);
            if (payload.hasOwnProperty('status')) {
                updatedState = updatedState.set('status', payload.status);
            } else {
                updatedState = updatedState.set('status', 'ACTIVE');
            }

            const optionalKeys = [
                'customerId',
                'contextId',
                'username',
                'lastLogin',
                'currentLogin',
                'groups',
                'category',
                'role',
                'agencyGroup',
                'emailVerified',
                'factors',
                'notificationPreferencesSet',
                'recoveryQuestion',
                'validated',
                'welcomeScreenViewed',
                'termsAndConditionsAccepted',
                'acceptedTermsAndConditions',
                'mfaAtLoginEnabled',
            ];
            for (const key of optionalKeys) {
                if (payload.hasOwnProperty(key)) {
                    updatedState = updatedState.set(key, payload[key]);
                }
            }

            return isEqual(updatedState, state) ? state : updatedState;

        case USER_UPDATE_ERROR:
            state = state.set('loading', false);
            state = state.set('status', USER_UPDATE_ERROR);
            state = state.set('errors', payload);

            return state;

        case USER_CREATING:
            state = state.set('username', payload.username);
            state = state.set('loading', true);

            return state;

        case USER_CREATED:
            state = state.set('status', 'CREATED');
            state = state.set('username', payload.username);
            state = state.set('loading', false);

            return state;

        case USER_CREATION_ERROR:
            state = state.set('loading', false);
            state = state.set('status', USER_CREATION_ERROR);
            state = state.set('errors', payload.errors);

            return state;

        case USER_LOGIN_REQUESTED:
            state = state.set('loading', true);
            if (payload?.username) {
                state = state.set('username', payload.username);
            }

            return state;

        case USER_LOGIN_RESOLVED:
            state = state.set('loading', false);

            return state;

        case USER_LOCKED_OUT:
            state = initialState;
            state = state.set('status', USER_LOCKED_OUT);

            return state;

        case USER_LOGIN_ERROR:
            state = state.set('customerId', null);
            state = state.set('contextId', null);
            state = state.set('status', USER_LOGIN_ERROR);
            state = state.set('loading', false);

            return state;

        case USER_OKTA_ERROR:
            state = state.set('customerId', null);
            state = state.set('contextId', null);
            state = state.set('status', USER_OKTA_ERROR);
            state = state.set('loading', false);

            return state;

        case USER_LOGIN_ERROR_RESET:
            state = state.set('status', STORE_STATUS_INIT);

            return state;

        case UPDATE_LAST_LOGIN_REQUESTED:
            return state.set('lastLogin', STORE_STATUS_LOADING);

        case UPDATE_LAST_LOGIN_RESOLVED:
            state = state.set('lastLogin', payload.lastLogin);
            state = state.set('currentLogin', payload.currentLogin);

            return state;

        case UPDATE_LAST_LOGIN_ERROR:
            state = state.set(
                'state',
                Object.assign({}, state.get('state'), {
                    lastLogin: STORE_STATUS_ERROR,
                })
            );

            return state;

        default:
            return state;
    }
};

export { initialState };

export default userReducer;
