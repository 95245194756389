import { gql } from '@apollo/client';

export const unreadThreadCountQuery = gql`
    query CustomerMessages {
        customerMessages {
            unreadCount
        }
    }
`;

export const getCustomerMessagesQuery = gql`
    query CustomerMessages {
        customerMessages {
            unreadCount
            threads {
                id
                subject
                excerpt
                dateTime
                policyId
                customerId
                messages {
                    id
                    from
                    dateTime
                    read
                    body
                }
            }
        }
    }
`;

export const customerMessageCategoriesQuery = gql`
    query CustomerMessageCategories {
        customerMessagesCategories {
            productType
            categories {
                typeId
                categoryId
                description
            }
        }
    }
`;
