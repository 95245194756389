export const USER_UPDATED = 'USER_UPDATED';
export const USER_CREATING = 'USER_CREATING';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATION_ERROR = 'USER_CREATION_ERROR';
export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_RESOLVED = 'USER_LOGIN_RESOLVED';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_OKTA_ERROR = 'USER_OKTA_ERROR';
export const USER_LOGIN_ERROR_RESET = 'USER_LOGIN_ERROR_RESET';
export const USER_LOCKED_OUT = 'USER_LOCKED_OUT';
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED';
export const USER_UPDATE_RESOLVED = 'USER_UPDATE_RESOLVED';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_LAST_LOGIN_REQUESTED = 'UPDATE_LAST_LOGIN_REQUESTED';
export const UPDATE_LAST_LOGIN_RESOLVED = 'UPDATE_LAST_LOGIN_RESOLVED';
export const UPDATE_LAST_LOGIN_ERROR = 'UPDATE_LAST_LOGIN_ERROR';
export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_RESOLVED = 'CHANGE_PASSWORD_RESOLVED';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
