import { Map } from 'immutable';
import { STORE_STATUS_OK } from '../statuses';
import { STORE_RESET } from '../types';
import { SET_ADVISER_PORTFOLIO_ABI_SECTOR_COLORS } from './types';

export const initialState = Map({});

const adviserPortfolioAbiSectorColorsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;
        case SET_ADVISER_PORTFOLIO_ABI_SECTOR_COLORS:
            const { id, colors } = payload;
            return state.set(id, {
                status: STORE_STATUS_OK,
                colors,
            });
        default:
            return state;
    }
};

export default adviserPortfolioAbiSectorColorsReducer;
