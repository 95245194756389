import { Map } from 'immutable';
import {
    ADVISER_PORTFOLIO_INSTRUCTION_RESET_INSTRUCTION,
    ADVISER_PORTFOLIO_INSTRUCTION_INITIATE_INSTRUCTION,
    ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_ERROR,
    ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_REQUESTED,
    ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_RESOLVED,
    ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION,
    ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION_POLICY,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of valuation types
 *
 */
const schema = {};

const initialState = Map(schema);

/**
 *
 * The Adviser Portfolio Instruction Reducer
 *
 * Responsible for managing the state of the selected portfolio fund switch intruction
 *
 */
const adviserPortfolioInstructionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
        case ADVISER_PORTFOLIO_INSTRUCTION_RESET_INSTRUCTION:
            return initialState;

        case ADVISER_PORTFOLIO_INSTRUCTION_INITIATE_INSTRUCTION:
            return state.set('portfolioId', payload.portfolioId).set('status', STORE_STATUS_OK).set('instruction', {});

        case ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION_POLICY:
            if (!payload.instruction.switch && !payload.instruction.redirect) {
                return state.deleteIn(['instruction', payload.policyId]);
            }
            return state.setIn(['instruction', payload.policyId], payload.instruction);

        case ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);

        case ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_ERROR:
            return state.set('status', STORE_STATUS_ERROR);

        case ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION:
            return state.set('instruction', payload.instruction);

        case ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_RESOLVED:
            return initialState;

        default:
            return state;
    }
};

export { initialState };

export default adviserPortfolioInstructionReducer;
