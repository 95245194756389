/**
 * Error Codes used for lower level eror reporting such as with verification code sending/verification
 */

// Generic service error
export const ERROR_SERVICE = 'ERROR_SERVICE';
export const ERROR_CODE_VERIFICATION_NEW_EMAIL_EXISTS = 'ERROR_CODE_VERIFICATION_NEW_EMAIL_EXISTS';
export const ERROR_CODE_VERIFICATION_CODE_CREATE = 'ERROR_CODE_VERIFICATION_CODE_CREATE';
export const ERROR_CODE_VERIFICATION_CODE_SEND_SMS = 'ERROR_CODE_VERIFICATION_CODE_SEND_SMS';
export const ERROR_CODE_VERIFICATION_CODE_SEND_EMAIL = 'ERROR_CODE_VERIFICATION_CODE_SEND_EMAIL';
export const ERROR_CODE_VERIFICATION_CODE_VERIFY = 'ERROR_CODE_VERIFICATION_CODE_VERIFY';
export const OKTA_ERROR_CHANGING_RECOVERY_QUESTION = 'OKTA_ERROR_CHANGING_RECOVERY_QUESTION';
export const ERROR_ADVISER_PORTFOLIOS_NO_ACCESS = 'ERROR_NO_ACCESS';
export const ERROR_PAYMENT_NOT_SUPPORTED = 'PAYMENT_NOT_SUPPORTED';
