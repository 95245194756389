import { Map } from 'immutable';
import {
    POLICY_BENEFITS_REQUESTED,
    POLICY_BENEFITS_RESOLVED,
    POLICY_BENEFITS_ERROR,
} from './types';
import { STORE_RESET } from '../types';

/**
*
* Set the initial state of benifits types
*
*/
const schema = {}

const initialState = Map(schema);



const updatePolicyBenefits = (state, action) => {

    const policyBenefits = state.get(action.payload.policyId);

    return (policyBenefits) ? Object.assign({}, policyBenefits, action.payload) : action.payload;

};

/**
*
* The Policy Benefits Reducer
*
* Responsible for managing the state and data of customer policy benefits
*
*/
const policyBenefitsReducer = (state = initialState, action) => {

    switch (action.type) {

        case STORE_RESET:

            return initialState;

        case POLICY_BENEFITS_REQUESTED:

            return state.set(action.payload.policyId, updatePolicyBenefits(state, action));

        case POLICY_BENEFITS_RESOLVED:

            return state.set(action.payload.policyId, updatePolicyBenefits(state, action));

        case POLICY_BENEFITS_ERROR:

            return state.set(action.payload.policyId, updatePolicyBenefits(state, action));

        default:

            return state;
    }
};

export { initialState };
    
export default policyBenefitsReducer;
