import { Map } from 'immutable';

import { SET_STYLE } from './types';

/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    backgroundColor: '',
    footer: {
        hide: false,
        fixed: false,
    },
};

const initialState = Map(schema);

/**
 *
 * The Styles Reducer
 *
 * A reducer responsible for managing global styles for the application
 *
 */
const stylesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_STYLE:
            if (payload.hasOwnProperty('backgroundColor')) {
                state = state.set('backgroundColor', payload.backgroundColor);
            }

            if (payload.hasOwnProperty('footer')) {
                state = state.set('footer', Object.assign({}, state.get('footer'), payload.footer));
            }

            return state;

        default:
            return state;
    }
};

export { initialState };

export default stylesReducer;
