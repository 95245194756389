/**
 * Styled-components theme for Reassure-UI icon components
 */

import pallette from './pallette';
const {
    black,
    midRed,
    midGreen,
    white,
} = pallette;


const themeConfig = {
    colors: {
        circleIcon: {
            background: black,
            border: black,
            error: {
                background: midRed,
                border: midRed,
            }
        },
        colourIcon: {
            primary: midGreen,
            secondary: white,
        }
    }
}

export default themeConfig;