import { useSelector } from 'react-redux';
import defaultTheme, { THEME_NAME_DARK, THEME_NAME_LIGHT } from '../../theme';
import lightTheme from '../../theme/theme-light';
import darkTheme from '../../theme/theme-dark';
import { selectAppearance } from './selectors';

export const useTheme = () => {
    const { mode } = useSelector(selectAppearance);
    let theme;

    switch (mode) {
        case THEME_NAME_LIGHT:
            theme = lightTheme;
            break;
        case THEME_NAME_DARK:
            theme = darkTheme;
            break;
        default:
            theme = defaultTheme;
    }

    return {
        theme,
        customCss: `
            body {
                color: ${theme.colors.primaryText};
            }

            #onetrust-pc-sdk .ot-pc-logo {
              width: 145px !important;
            }
      
            #onetrust-pc-sdk #close-pc-btn-handler {
              background-size: 11px !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
              font-size: 20px !important;
              margin-bottom: 12px !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc {
              font-size: 13px;
              line-height: 20px;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc .privacy-notice-link {
              margin-top: 10px !important;
              margin-bottom: 0 !important;
            }
      
            #onetrust-pc-sdk #ot-category-title {
              font-size: 20px !important;
              padding-bottom: 15px !important;
            }
      
            #onetrust-pc-sdk .ot-cat-item {
              margin-bottom: 8px !important;
              border: 1px solid #d8d8d8 !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk .category-host-list-handler {
              text-decoration: underline !important;
              letter-spacing: normal !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk .category-host-list-handler:hover {
              text-decoration: none !important;
            }
            
            #onetrust-pc-sdk .category-switch-handler:before,
            #onetrust-pc-sdk .category-switch-handler:checked:before,
            #onetrust-pc-sdk .ot-vs-config .ot-acc-hdr .ot-always-active {
              color: #3C4981;
              font-weight: 600;
            }
      
            #onetrust-pc-sdk .ot-switch {
              width: 52px !important;
              height: 27px !important;
            }
      
            #onetrust-pc-sdk .ot-switch-nob {
              border: 1px solid #2C3D6C !important;
              background-color: #CDD2DC !important;
            }
      
            #onetrust-pc-sdk .ot-switch-nob:before {
              height: 17px !important;
              width: 17px !important;
              bottom: 5px !important;
              left: 5px !important;
              background-color: white !important;
            }
      
            #onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
              background-color: #49A94E !important;
              border: 1px solid ${lightTheme.colors.midGreen} !important;
              outline: 1px solid ${lightTheme.colors.midGreen};
            }
      
            #onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob:before {
              transform: translateX(24px) !important;
            }
      
            #onetrust-pc-sdk .ot-pc-footer {
              padding-bottom: 10px;
              padding-top: 22px;
            }
      
            #onetrust-pc-sdk .ot-pc-footer button {
              margin-top: 0 !important;
            }
      
            #onetrust-pc-sdk .ot-pc-footer-logo {
              display: none !important;
            }
      
            #onetrust-pc-sdk .category-switch-handler {
              position: relative;
            }
      
            #onetrust-pc-sdk .ot-tgl input {
              opacity: 1 !important;
            }
      
            #onetrust-pc-sdk .category-switch-handler:before,
            #onetrust-pc-sdk .category-switch-handler:checked:before {
              padding-right: 8px;
              font-size: .88em;
              transform: translate(-100%, 0);
              position: absolute;
              left: 0;
              top: 3px;
            }
      
            #onetrust-pc-sdk .category-switch-handler:before {
              content: 'Declined';
            }
      
            #onetrust-pc-sdk .category-switch-handler:checked:before {
              content: 'Accepted';
            }
      
            #onetrust-pc-sdk .ot-btn-container {
              text-align: right !important;
            }

            #onetrust-consent-sdk #onetrust-pc-sdk .category-switch-handler:focus {
              outline: none !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn) {
              background-color: #4DAA50 !important;
              border-radius: 4px !important;
              font-weight: 600;
              border-width: 2px !important;
              font-size: 14px !important;
              padding: 16px 60px !important;
            }
      
            #onetrust-consent-sdk #onetrust-pc-sdk #accept-recommended-btn-handler,
            #onetrust-consent-sdk #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn):first-child {
              display: none !important;
            }
      
            #onetrust-pc-sdk.ot-ftr-stacked .ot-pc-footer button {
              width: auto !important;
            }
      
            #onetrust-pc-sdk :not(.ot-leg-btn-container)>button:not(.ot-link-btn):hover {
              opacity: 1 !important;
            }
      
            @media (max-width: 768px) {
              #onetrust-pc-sdk .ot-pc-footer button:first-child {
                margin-bottom: 12px !important;
                margin-top: 0 !important;
              }
            }
        `,
    };
};
