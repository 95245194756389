import getContent from '../content/getContent';
import moment from 'moment-timezone';

/**
* Method to transform an ISO date timestamp to a presentable date and time format
* @param {String} date
*/
export const formatTimeStamp = (date) => {
    return `${moment(date).format('h.mma')} on ${moment(date).format('D MMMM')}`
}

/**
* Method to get a future maintenance window message based on the current state
* @param {Object} state
*/
export const getFutureMaintenanceWindowMessage = (maintenanceWindow) => {
    if(!maintenanceWindow) return null;

    const fromDate = maintenanceWindow.from;
    const toDate = maintenanceWindow.to;

    if(!fromDate) return null;

    if(fromDate === toDate) return null;

    if(toDate && moment(toDate).tz("Europe/London").isBefore(fromDate)) return null;

    const now = moment().tz("Europe/London");
    if(fromDate && moment(fromDate).tz("Europe/London").isBefore(now)) return null;

    const messageContent = getContent(['maintenance', 'future-maintenance-window-message']);

    if(toDate) {
        return messageContent.replace(/{{MAINTENANCE_FROM_DATE}}/g, formatTimeStamp(fromDate)).replace(/{{MAINTENANCE_TO_DATE}}/g, formatTimeStamp(toDate));
    }
    return messageContent.split(' to {{MAINTENANCE_TO_DATE}}').join('').replace(/{{MAINTENANCE_FROM_DATE}}/g, formatTimeStamp(fromDate));
}

/**
* Method to get a current maintenance window message based on the current state
* @param {Object} state
*/
export const getCurrentMaintenanceWindowMessage = (maintenanceWindow) => {
    if(!maintenanceWindow) return null;

    const fromDate = maintenanceWindow.from;
    const toDate = maintenanceWindow.to;

    if(!fromDate) return null;

    if(fromDate === toDate) return null;

    if(toDate && moment(toDate).tz("Europe/London").isBefore(fromDate)) return null;

    const now = moment().tz("Europe/London");
    if(fromDate && moment(fromDate).tz("Europe/London").isAfter(now)) return null;

    if(fromDate && toDate && moment(toDate).tz("Europe/London").isBefore(now)) return null;

    const messageContent = getContent(['maintenance', 'current-maintenance-window-message']);

    if(toDate) {
        return messageContent.replace(/{{MAINTENANCE_FROM_DATE}}/g, formatTimeStamp(fromDate)).replace(/{{MAINTENANCE_TO_DATE}}/g, formatTimeStamp(toDate));
    }
    return messageContent.split(' to {{MAINTENANCE_TO_DATE}}').join('').replace(/{{MAINTENANCE_FROM_DATE}}/g, formatTimeStamp(fromDate));
}