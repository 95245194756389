import { Map } from 'immutable';
import { PROFILE_REQUESTED, PROFILE_RESOLVED, PROFILE_ERROR, PROFILE_FIELD_UPDATE } from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_OK } from '../statuses';
/**
 *
 * Set the initial state of our app
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    profile: {},
};

// method to append the postcode to the address
const updateProfile = profile => {
    if (profile?.address?.value?.address && profile?.address?.value?.postCode) {
        return {
            ...profile,
            address: {
                value: {
                    address: profile.address.value.address.concat(profile.address.value.postCode),
                    postCode: profile.address.value.postCode,
                },
                status: profile.address.status,
                editable: profile.address.editable,
            },
        };
    }
    return profile;
};

const initialState = Map(schema);

/**
 *
 * The Profile Reducer
 *
 * Responsible for managing the state and data of customer profile
 *
 */
const profileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case PROFILE_REQUESTED:
            return state.set('loading', true);

        case PROFILE_RESOLVED:
            return state
                .set('status', STORE_STATUS_OK)
                .set('profile', updateProfile(payload.profile))
                .set('loading', false);

        case PROFILE_ERROR:
            return state.set('loading', false).set('status', STORE_STATUS_ERROR);

        case PROFILE_FIELD_UPDATE:
            const { fieldName, ...values } = payload;
            const profile = state.get('profile');
            const field = Object.assign({}, profile[fieldName], { ...values });
            return state.set('profile', Object.assign({}, profile, { [fieldName]: field }));

        default:
            return state;
    }
};

export { initialState };

export default profileReducer;
