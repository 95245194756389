import { Map } from 'immutable';
import {
    POLICY_FUND_SWITCH_FUNDS_INVESTED_REQUESTED,
    POLICY_FUND_SWITCH_FUNDS_INVESTED_RESOLVED,
    POLICY_FUND_SWITCH_FUNDS_INVESTED_ERROR,
    POLICY_FUND_SWITCH_FUNDS_AVALABLE_REQUESTED,
    POLICY_FUND_SWITCH_FUNDS_AVALABLE_RESOLVED,
    POLICY_FUND_SWITCH_FUNDS_AVALABLE_ERROR,
    POLICY_FUND_SWITCH_FUND_SELECT_TOGGLE,
    POLICY_FUND_SWITCH_UPDATE_ALLOCATION,
    POLICY_FUND_SWITCH_CONFIRM_SELECTION,
    POLICY_FUND_SWITCH_STATE_REQUESTED,
    POLICY_FUND_SWITCH_STATE_RESOLVED,
    POLICY_FUND_SWITCH_STATE_ERROR,
    CREATE_POLICY_FUND_SWITCH_REQUESTED,
    CREATE_POLICY_FUND_SWITCH_RESOLVED,
    CREATE_POLICY_FUND_SWITCH_ERROR,
    POLICY_FUND_SWITCH_REDIRECT_PREMIUMS_TOGGLE,
} from './types';
import { STORE_RESET } from '../types';
import { FUND_SWITCH_STATUS_PENDING } from '../../services/fundSwitch';
import { STORE_STATUS_ERROR, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of valuation types
 *
 */
const schema = {};

const initialState = Map(schema);

const createSelectedFund = (funds, reAssureId) => {
    const units = funds.filter(fund => fund.reAssureId === reAssureId);
    return {
        allocation: 0,
        confirmMultiUnitFundOnSelection: units.length > 1,
        confirmCashFundOnSelection: units.filter(unit => unit.cashLike === true).length > 0,
        name: units.length > 1 ? units[0].reAssureFundName : units[0].name,
        reAssureId,
        units,
    };
};

const getPolicyFundSwitchSelectedOutState = funds => {
    if (!funds || !funds.length) return {};
    const availableFunds = funds.filter(fund => fund.canSwitchOut);
    if (availableFunds.length > 1) return {};
    return {
        [availableFunds[0].reAssureId]: createSelectedFund(availableFunds, availableFunds[0].reAssureId),
    };
};

const updateRedirectPremiums = (state, { policyId, redirectPremiums }) =>
    state.setIn([policyId, 'redirectPremiums'], redirectPremiums);

/**
 * Builds the model for each policy state
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePolicyFundSwitch = (state, { type, payload }) => {
    let update;

    switch (type) {
        case POLICY_FUND_SWITCH_FUNDS_INVESTED_REQUESTED:
            update = { out: { funds: [], status: STORE_STATUS_LOADING } };
            break;
        case POLICY_FUND_SWITCH_FUNDS_INVESTED_RESOLVED:
            update = {
                out: {
                    funds: payload.funds,
                    selected: getPolicyFundSwitchSelectedOutState(payload.funds),
                    status: STORE_STATUS_OK,
                },
            };
            break;
        case POLICY_FUND_SWITCH_FUNDS_INVESTED_ERROR:
            update = { out: { status: STORE_STATUS_ERROR } };
            break;
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_REQUESTED:
            update = { in: { funds: [], status: STORE_STATUS_LOADING } };
            break;
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_RESOLVED:
            update = {
                in: {
                    funds: payload.funds,
                    status: STORE_STATUS_OK,
                },
            };
            break;
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_ERROR:
            update = { in: { status: STORE_STATUS_ERROR } };
            break;
        case POLICY_FUND_SWITCH_STATE_REQUESTED:
            update = { state: { status: STORE_STATUS_LOADING } };
            break;
        case POLICY_FUND_SWITCH_STATE_RESOLVED:
            update = { state: { ...payload.state } };
            break;
        case POLICY_FUND_SWITCH_STATE_ERROR:
            update = { state: { status: STORE_STATUS_ERROR } };
            break;
        case CREATE_POLICY_FUND_SWITCH_REQUESTED:
            update = { submitting: true };
            break;
        case CREATE_POLICY_FUND_SWITCH_RESOLVED:
            const policyFundSwitchState = state.get(payload.policyId).state;
            update = {
                submitting: false,
                state: Object.assign({}, policyFundSwitchState, { status: FUND_SWITCH_STATUS_PENDING }),
            };
            break;
        case CREATE_POLICY_FUND_SWITCH_ERROR:
            update = { submitting: false };
            break;
        default:
            update = {};
    }

    const policyFundSwitch = state.get(payload.policyId);

    return policyFundSwitch ? Object.assign({}, policyFundSwitch, update) : update;
};

/**
 *
 * The Fund Switch Reducer
 *
 * Responsible for managing the state and data of policy fund switching
 *
 */
const fundSwitchReducer = (state = initialState, action) => {
    const selectedKey = 'selected';

    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case POLICY_FUND_SWITCH_FUNDS_INVESTED_REQUESTED:
        case POLICY_FUND_SWITCH_FUNDS_INVESTED_RESOLVED:
        case POLICY_FUND_SWITCH_FUNDS_INVESTED_ERROR:
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_REQUESTED:
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_RESOLVED:
        case POLICY_FUND_SWITCH_FUNDS_AVALABLE_ERROR:
        case POLICY_FUND_SWITCH_STATE_REQUESTED:
        case POLICY_FUND_SWITCH_STATE_RESOLVED:
        case POLICY_FUND_SWITCH_STATE_ERROR:
        case CREATE_POLICY_FUND_SWITCH_REQUESTED:
        case CREATE_POLICY_FUND_SWITCH_RESOLVED:
        case CREATE_POLICY_FUND_SWITCH_ERROR:
            return state.set(action.payload.policyId, updatePolicyFundSwitch(state, action));

        case POLICY_FUND_SWITCH_FUND_SELECT_TOGGLE:
            const isSelected = state.getIn([action.payload.policyId, 'out', selectedKey, action.payload.reAssureId]);
            const funds = state.getIn([action.payload.policyId, 'out', 'funds']);
            state = isSelected
                ? state.deleteIn([action.payload.policyId, 'out', selectedKey, action.payload.reAssureId])
                : state.setIn(
                      [action.payload.policyId, 'out', selectedKey, action.payload.reAssureId],
                      createSelectedFund(funds, action.payload.reAssureId)
                  );
            return state;

        case POLICY_FUND_SWITCH_UPDATE_ALLOCATION:
            return state.setIn(
                [action.payload.policyId, 'out', selectedKey, action.payload.reAssureId, 'allocation'],
                action.payload.allocation
            );

        case POLICY_FUND_SWITCH_CONFIRM_SELECTION:
            return state
                .setIn(
                    [
                        action.payload.policyId,
                        'out',
                        selectedKey,
                        action.payload.reAssureId,
                        'confirmCashFundOnSelection',
                    ],
                    false
                )
                .setIn(
                    [
                        action.payload.policyId,
                        'out',
                        selectedKey,
                        action.payload.reAssureId,
                        'confirmMultiUnitFundOnSelection',
                    ],
                    false
                );

        case POLICY_FUND_SWITCH_REDIRECT_PREMIUMS_TOGGLE:
            return updateRedirectPremiums(state, action.payload);

        default:
            return state;
    }
};

export { initialState };

export default fundSwitchReducer;
