export const POLICY_FUND_SWITCH_FUNDS_INVESTED_REQUESTED = 'POLICY_FUND_SWITCH_FUNDS_INVESTED_REQUESTED';
export const POLICY_FUND_SWITCH_FUNDS_INVESTED_RESOLVED = 'POLICY_FUND_SWITCH_FUNDS_INVESTED_RESOLVED';
export const POLICY_FUND_SWITCH_FUNDS_INVESTED_ERROR = 'POLICY_FUND_SWITCH_FUNDS_INVESTED_ERROR';
export const POLICY_FUND_SWITCH_FUNDS_AVALABLE_REQUESTED = 'POLICY_FUND_SWITCH_FUNDS_AVALABLE_REQUESTED';
export const POLICY_FUND_SWITCH_FUNDS_AVALABLE_RESOLVED = 'POLICY_FUND_SWITCH_FUNDS_AVALABLE_RESOLVED';
export const POLICY_FUND_SWITCH_FUNDS_AVALABLE_ERROR = 'POLICY_FUND_SWITCH_FUNDS_AVALABLE_ERROR';
export const POLICY_FUND_SWITCH_FUND_SELECT_TOGGLE = 'POLICY_FUND_SWITCH_FUND_SELECT_TOGGLE';
export const POLICY_FUND_SWITCH_UPDATE_ALLOCATION = 'POLICY_FUND_SWITCH_UPDATE_ALLOCATION';
export const POLICY_FUND_SWITCH_CONFIRM_SELECTION = 'POLICY_FUND_SWITCH_CONFIRM_SELECTION';
export const POLICY_FUND_SWITCH_STATE_REQUESTED = 'POLICY_FUND_SWITCH_STATE_REQUESTED';
export const POLICY_FUND_SWITCH_STATE_RESOLVED = 'POLICY_FUND_SWITCH_STATE_RESOLVED';
export const POLICY_FUND_SWITCH_STATE_ERROR = 'POLICY_FUND_SWITCH_STATE_ERROR';
export const CREATE_POLICY_FUND_SWITCH_REQUESTED = 'CREATE_POLICY_FUND_SWITCH_REQUESTED';
export const CREATE_POLICY_FUND_SWITCH_RESOLVED = 'CREATE_POLICY_FUND_SWITCH_RESOLVED';
export const CREATE_POLICY_FUND_SWITCH_ERROR = 'CREATE_POLICY_FUND_SWITCH_ERROR';
export const POLICY_FUND_SWITCH_REDIRECT_PREMIUMS_TOGGLE = 'POLICY_FUND_SWITCH_REDIRECT_PREMIUMS_TOGGLE';