import { Map } from 'immutable';
import {
    NOTIFICATION_PREFERENCES_REQUESTED,
    NOTIFICATION_PREFERENCES_RESOLVED,
    NOTIFICATION_PREFERENCES_ERROR,
} from './types';
import {
    UPDATE_NOTIFICATION_PREFERENCES_REQUESTED,
    UPDATE_NOTIFICATION_PREFERENCES_RESOLVED,
    UPDATE_NOTIFICATION_PREFERENCES_ERROR,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_INIT } from '../statuses';

/**
 *
 * Set the initial state
 *
 */
const schema = {
    status: STORE_STATUS_INIT,
    loading: false,
    saving: false,
};

const initialState = Map(schema);

/**
 *
 * The Notification Preferences Reducer
 *
 * A reducer to manage the state of the user's notification preferences in the store
 *
 */
const notificationPreferencesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STORE_RESET:
            return initialState;

        case NOTIFICATION_PREFERENCES_REQUESTED:
            return state.set('loading', true);

        case NOTIFICATION_PREFERENCES_RESOLVED:
            state = state.set('email', payload.email);
            state = state.set('sms', payload.sms);
            state = state.set('telephone', payload.telephone);
            state = state.set('paperless', payload.paperless);
            state = state.set('status', 'OK');
            state = state.set('loading', false);

            return state;

        case NOTIFICATION_PREFERENCES_ERROR:
            state = state.set('status', 'ERROR');
            state = state.set('loading', false);

            return state;

        case UPDATE_NOTIFICATION_PREFERENCES_REQUESTED:
            state = state.set('email', payload.email);
            state = state.set('sms', payload.sms);
            state = state.set('telephone', payload.telephone);
            state = state.set('paperless', payload.paperless);
            state = state.set('status', 'OK');
            state = state.set('saving', true);

            return state;

        case UPDATE_NOTIFICATION_PREFERENCES_RESOLVED:
            state = state.set('email', payload.email);
            state = state.set('sms', payload.sms);
            state = state.set('telephone', payload.telephone);
            state = state.set('paperless', payload.paperless);
            state = state.set('status', 'OK');
            state = state.set('saving', false);

            return state;

        case UPDATE_NOTIFICATION_PREFERENCES_ERROR:
            state = state.set('status', 'ERROR');
            state = state.set('saving', false);

            return state;

        default:
            return state;
    }
};

export { initialState };

export default notificationPreferencesReducer;
