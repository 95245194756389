export const ADVISER_PORTFOLIO_INSTRUCTION_RESET_INSTRUCTION = 'ADVISER_PORTFOLIO_INSTRUCTION_RESET_INSTRUCTION';
export const ADVISER_PORTFOLIO_INSTRUCTION_INITIATE_INSTRUCTION = 'ADVISER_PORTFOLIO_INSTRUCTION_INITIATE_INSTRUCTION';
export const ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION = 'ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION';
export const ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION_POLICY =
    'ADVISER_PORTFOLIO_INSTRUCTION_UPDATE_INSTRUCTION_POLICY';
export const ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_REQUESTED =
    'ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_REQUESTED';
export const ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_RESOLVED =
    'ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_RESOLVED';
export const ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_ERROR =
    'ADVISER_PORTFOLIO_INSTRUCTION_SUBMIT_INSTRUCTION_ERROR';
export const ADVISER_PORTFOLIO_INSTRUCTION_TOGGLE_SWITCH_ALL_POLICIES =
    'ADVISER_PORTFOLIO_INSTRUCTION_TOGGLE_SWITCH_ALL_POLICIES';
export const ADVISER_PORTFOLIO_INSTRUCTION_TOGGLE_REDIRECT_ALL_POLICIES =
    'ADVISER_PORTFOLIO_INSTRUCTION_TOGGLE_REDIRECT_ALL_POLICIES';
