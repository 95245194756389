import { createSelector } from 'reselect';
import { ADVISER, RETAIL, SCHEME } from '../services/role-types';

const inviteRoleSelector = state => {
    const role = state.invitation.get('role');

    if (!role) return null;

    if (role.indexOf(ADVISER) === 0) return ADVISER;
    if (role.indexOf(RETAIL) === 0) return RETAIL;
    if (role.indexOf(SCHEME) === 0) return SCHEME;

    return RETAIL;
};

const userCategorySelector = state => {
    const category = state.user.get('category');
    return category || null;
};

export const selectUserCategory = createSelector(
    [inviteRoleSelector, userCategorySelector],
    (inviteCategory, userCategory) => userCategory || inviteCategory
);
