//es6 polyfills required for IE11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './store';

// Apollo
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';

// ReassureUI
import DynamicAppProvider from './components/dynamic-app-provider';

// App
import App from './components/app';

/**
 * Build the app
 */

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <DynamicAppProvider>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </DynamicAppProvider>
        </Provider>
    </ApolloProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.REACT_APP_LOG_WEB_VITALS === 'true' ? console.log : null);
