import { gql } from '@apollo/client';

export const markCustomerMessageReadMutation = gql`
    mutation MarkCustomerMessageRead($input: IdInput!) {
        markCustomerMessageRead(input: $input)
    }
`;

export const sendCustomerMessageMutation = gql`
    mutation SendCustomerMessage($input: SendCustomerMessageInput!) {
        sendCustomerMessage(input: $input)
    }
`;
