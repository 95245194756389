import { createSelector } from 'reselect';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING } from '../statuses';

const stateSelector = state => state.adviserBackOfficeIntegrations;

export const selectAdviserBackOfficeIntegrations = createSelector([stateSelector], state => {
    const { status, integrations } = state.toJS();
    return {
        status,
        loading: status === STORE_STATUS_LOADING || status === STORE_STATUS_INIT,
        error: status === STORE_STATUS_ERROR,
        integrations,
    };
});
