import { Map } from 'immutable';
import { POLICY_THIRD_PARTIES_REQUESTED, POLICY_THIRD_PARTIES_RESOLVED, POLICY_THIRD_PARTIES_ERROR } from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_OK } from '../statuses';

/**
 *
 * Set the initial state of policy third parties reducer
 *
 */
const schema = {};

const initialState = Map(schema);
/**
 * Builds the model for each policy state
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePolicyThirdParties = (state, { type, payload }) => {
    let update;

    switch (type) {
        case POLICY_THIRD_PARTIES_REQUESTED:
            update = Object.assign(payload, { loading: true });
            break;
        case POLICY_THIRD_PARTIES_RESOLVED:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_OK });
            break;
        case POLICY_THIRD_PARTIES_ERROR:
            update = Object.assign(payload, { loading: false, status: STORE_STATUS_ERROR });
            break;
        default:
            update = payload;
    }

    const policyThirdParties = state.get(payload.policyId);

    return policyThirdParties ? Object.assign({}, policyThirdParties, update) : update;
};

/**
 *
 * The Policy Third Parties Reducer
 *
 * Responsible for managing the state and data of customer policy third parties
 *
 */
const policyThirdPartiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_RESET:
            return initialState;

        case POLICY_THIRD_PARTIES_REQUESTED:
            return state.set(action.payload.policyId, updatePolicyThirdParties(state, action));

        case POLICY_THIRD_PARTIES_RESOLVED:
            return state.set(action.payload.policyId, updatePolicyThirdParties(state, action));

        case POLICY_THIRD_PARTIES_ERROR:
            return state.set(action.payload.policyId, updatePolicyThirdParties(state, action));

        default:
            return state;
    }
};

export { initialState };

export default policyThirdPartiesReducer;
