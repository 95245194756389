import { Map } from 'immutable';
import {
    CREATE_ADVISER_PORTFOLIO_ERROR,
    CREATE_ADVISER_PORTFOLIO_REQUESTED,
    CREATE_ADVISER_PORTFOLIO_RESET,
    CREATE_ADVISER_PORTFOLIO_RESOLVED,
} from './types';
import { STORE_RESET } from '../types';
import { STORE_STATUS_ERROR, STORE_STATUS_INIT, STORE_STATUS_LOADING, STORE_STATUS_OK } from '../statuses';

const schema = {
    status: STORE_STATUS_INIT,
};

const initialState = Map(schema);

const createAdviserPortfolioReducer = (state = initialState, { type }) => {
    switch (type) {
        case CREATE_ADVISER_PORTFOLIO_REQUESTED:
            return state.set('status', STORE_STATUS_LOADING);
        case CREATE_ADVISER_PORTFOLIO_RESOLVED:
            return state.set('status', STORE_STATUS_OK);
        case CREATE_ADVISER_PORTFOLIO_ERROR:
            return state.set('status', STORE_STATUS_ERROR);
        case STORE_RESET:
        case CREATE_ADVISER_PORTFOLIO_RESET:
            return initialState;
        default:
            return state;
    }
};

export { initialState };

export default createAdviserPortfolioReducer;
